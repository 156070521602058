import { useEffect } from 'react';

export const useDocTitle = ( title?: string, fallback = true ) => {
  useEffect( () => {
    if ( title ) {
      document.title = title;
      if ( fallback )
        return () => {
          document.title = 'Crewww';
        };
    }
  }, [ title ] );

  return null;
};
