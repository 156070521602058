import { ReactNode } from 'react';
import '../../assets/css/components/controls/Group.css';

export type GroupProps = { children: ReactNode; title?: string | JSX.Element };

export const Group = ( { children, title }: GroupProps ) => (
  <div className='controls-group'>
    {title && <label>{title}</label>}
    {children}
  </div>
);
