import * as yup from 'yup';
import { testPhone, testPhoneOptionalPlus } from '.';
import {
  ImageSelectBlockFieldValue,
  TagListFieldValues,
  ToggleLabeledFieldValue
} from '../components/forms';
import {
  ChangePasswordFormFields,
  FacebookRegisterData,
  GoogleRegisterData,
  LoginFormFields,
  ProfileEditFormFields,
  RegisterFormFields,
  RestorePasswordFormFields,
  ValidationSchema
} from '../services';

export const eventCreationValidationSchema = ( maxMembers: number, edit?: boolean ) =>
  yup.object( {
    title: yup.string().required(),
    emoji: yup.string(),
    bannerColor: yup.mixed().when( 'byoc', {
      is: ( val: unknown ) => !!val,
      then: yup.mixed(),
      otherwise: yup.string().required()
    } ),
    maxMembers: yup.number().min( 2, '' ).max( 8, '' ).required( '' ),
    start: yup.date().required(),
    description: yup.string().required(),
    location: yup.object().shape( {
      lat: yup.number().required(),
      lng: yup.number().required(),
      title: yup.string().required()
    } ),
    filters: yup
      .object( {
        gender: yup.boolean(),
        age: yup
          .object( {
            min: yup.number().min( 18 ).max( yup.ref( 'max' ) ).required(),
            max: yup.number().max( 120 ).min( yup.ref( 'min' ) ).required()
          } )
          .notRequired()
          .default( undefined )
      } )
      .notRequired()
      .default( undefined ),
    byoc: yup
      .object( {
        ...( edit
          ? {}
          : {
              authorCrewGender: yup.string().oneOf( [ 'male', 'female' ] ).required(),
              invites: yup
                .object( {
                  registered: yup.array( yup.string().required() ).required(),
                  notRegistered: yup
                    .array(
                      yup
                        .string()
                        .test( { test: testPhoneOptionalPlus, message: 'Invalid phone' } )
                        .required()
                    )
                    .required()
                } )
                .test( ( { registered, notRegistered } ) => {
                  if ( !registered || !notRegistered ) return false;
                  return registered.length + notRegistered.length === maxMembers - 1;
                } )
            } ),
        targetCrewGender: yup.string().oneOf( [ 'male', 'female' ] ).required()
      } )
      .notRequired()
      .default( undefined )
  } );

export const registrationValidationSchema = yup
  .object()
  .shape<ValidationSchema<RegisterFormFields>>( {
    name: yup.string().required( 'Please provide your name' ),
    gender: yup.string().required( 'Please provide your gender' ),
    phone: yup
      .string()
      .test( {
        test: testPhone
      } )
      .required( 'Please provide your phone' ),
    otp: yup.string().length( 6 ).required(),
    termsAgreed: yup.boolean().isTrue().required(),
    password: yup
      .string()
      .min( 6, 'Password should be at least 6 characters long' )
      .required(),
    confirmPassword: yup
      .string()
      .equals( [ yup.ref( 'password' ) ], 'Password mismatch' )
      .required( 'Please confirm your password' ),
    birthDate: yup.date().required()
  } );

export const loginValidationSchema = yup
  .object()
  .shape<ValidationSchema<LoginFormFields>>( {
    phone: yup
      .string()
      .test( {
        test: testPhone,
        message: 'Invalid phone'
      } )
      .required( 'Please enter your phone' ),
    password: yup.string().required( 'Please enter your password' )
  } );

export const profileEditRequiredFieldsSchema = yup
  .object()
  .shape<ValidationSchema<ProfileEditFormFields>>( {
    about: yup.string().required(),
    age: yup.number().required(),
    alcohol: yup.object().shape<ValidationSchema<ToggleLabeledFieldValue>>( {
      enabled: yup.boolean().required(),
      visible: yup.boolean().required()
    } ),
    smoking: yup.object().shape<ValidationSchema<ToggleLabeledFieldValue>>( {
      enabled: yup.boolean().required(),
      visible: yup.boolean().required()
    } ),
    education: yup.string().required(),
    job: yup.string().required(),
    location: yup.string().required(),
    images: yup.object().shape<ValidationSchema<ImageSelectBlockFieldValue>>( {
      added: yup.array().min( 1 ).required()
    } ),
    features: yup.object().shape<ValidationSchema<TagListFieldValues>>( {
      selected: yup.array().min( 1 ).required()
    } ),
    interests: yup.object().shape<ValidationSchema<TagListFieldValues>>( {
      selected: yup.array().min( 1 ).required()
    } )
  } );

export const changePasswordValidationSchema = yup
  .object()
  .shape<ValidationSchema<ChangePasswordFormFields>>( {
    oldPassword: yup.string().required( 'Please enter your old password' ),
    newPassword: yup
      .string()
      .required( 'Please enter your new password' )
      .min( 6, 'Password should be at least 6 characters long' ),
    confirmNewPassword: yup
      .string()
      .equals( [ yup.ref( 'newPassword' ) ], 'Password mismatch ' )
      .required( 'Please confirm your password' )
  } );

export const restorePasswordValidationSchema = yup
  .object()
  .shape<ValidationSchema<RestorePasswordFormFields>>( {
    phone: yup
      .string()
      .test( {
        test: testPhone,
        message: 'Invalid phone'
      } )
      .required( 'Please enter your phone' ),
    otp: yup.string().length( 6 ).required(),
    newPassword: yup
      .string()
      .min( 6, 'Password should be at least 6 characters long' )
      .required(),
    confirmPassword: yup
      .string()
      .equals( [ yup.ref( 'newPassword' ) ], 'Password mismatch' )
      .required( 'Please confirm your password' )
  } );

export const facebookRegistrationValidationSchema = yup
  .object()
  .shape<
    ValidationSchema<Omit<FacebookRegisterData, 'facebookUserId' | 'facebookAccessToken'>>
  >( {
    phone: yup
      .string()
      .test( { test: testPhone, message: 'Invalid phone' } )
      .required( 'Please enter your phone number' ),
    otp: yup.string().length( 6 ).required(),
    age: yup.number().min( 18 ).max( 120 ).required(),
    gender: yup.string().required(),
    name: yup.string().required(),
    termsAgreed: yup.boolean().isTrue()
  } );

export const googleRegistrationValidationSchema = yup
  .object()
  .shape<ValidationSchema<GoogleRegisterData>>( {
    phone: yup
      .string()
      .test( { test: testPhone, message: 'Invalid phone' } )
      .required( 'Please enter your phone number' ),
    otp: yup.string().length( 6 ).required(),
    age: yup.number().min( 18 ).max( 120 ).required( 'Please enter your age' ),
    gender: yup.string().required( 'Please select your gender' ),
    termsAgreed: yup.boolean().isTrue(),
    emailMarketing: yup.boolean(),
    code: yup.string().required(),
    overrideName: yup.string().required( 'Please enter your name' )
  } );
