import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiSliceState, AuthSliceState, localStoragePath } from '.';

const initialState: AuthSliceState = {
  accessToken: localStorage.getItem( localStoragePath.token ) || '',
  isAuthorized: !!localStorage.getItem( localStoragePath.token ),
  refreshToken: localStorage.getItem( localStoragePath.refreshToken ) || '',
  isLoading: false,
  firstLogin: !!localStorage.getItem( localStoragePath.firstLogin )
};

export const authSlice = createSlice( {
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: ( state, action: PayloadAction<string> ) => {
      state.accessToken = action.payload;
      localStorage.setItem( localStoragePath.token, action.payload );
      if ( action.payload ) state.error = undefined;
      state.isAuthorized = !!action.payload;
    },
    setIsAuthorized: ( state, action: PayloadAction<boolean> ) => {
      state.isAuthorized = action.payload;
      if ( !action.payload ) {
        state.refreshToken = '';
        state.accessToken = '';
      }
    },
    setRefreshToken: ( state, action: PayloadAction<string> ) => {
      state.refreshToken = action.payload;
      localStorage.setItem( localStoragePath.refreshToken, action.payload );
    },
    setLoading: state => {
      state.isLoading = true;
    },
    setError: ( state, action: PayloadAction<ApiSliceState['error']> ) => {
      state.error = action.payload;
      state.isLoading = false;
      state.success = false;
    },
    setSuccess: ( state, action: PayloadAction<ApiSliceState['success']> ) => {
      state.success = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    setFirstLogin: ( state, action: PayloadAction<boolean> ) => {
      if ( action.payload ) {
        localStorage.setItem( localStoragePath.firstLogin, 'true' );
        state.firstLogin = true;
      } else {
        localStorage.removeItem( localStoragePath.firstLogin );
        state.firstLogin = false;
      }
    },
    setRestorePasswordError: ( state, action: PayloadAction<string | undefined> ) => {
      state.restorePasswordError = action.payload;
    },
    setRestorePasswordSuccess: ( state, action: PayloadAction<boolean | undefined> ) => {
      state.restorePasswordSuccess = action.payload;
    }
  }
} );

export const authActions = authSlice.actions;
