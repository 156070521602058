import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../../assets/css/components/screens/LoginScreen.css';
import { ReactComponent as Logo } from '../../../assets/img/logo.svg';
import { routesPaths } from '../../../navigation';
import { LoginFormFields, authService, isServerResponse } from '../../../services';
import { useTypedSelector } from '../../../state';
import { authActions } from '../../../state/slices/authSlice';
import { getLoginInitialValues, loginValidationSchema } from '../../../utils';
import { PhoneField, Submit } from '../../forms';
import { PasswordField } from '../../forms/PasswordField';
import { WebViewWarningModal } from '../../modals';
import { ScreenTitle } from '../../ui';
import { LoginWithSocials } from './LoginWithSocials';

const initialValues: LoginFormFields = getLoginInitialValues();

export const LoginScreen = () => {
  const authState = useTypedSelector( state => state.auth );
  const dispatch = useDispatch();

  useEffect( () => {
    if ( !authState.success ) {
      let message: string | undefined;
      if ( typeof authState.error === 'string' ) message = authState.error;
      else if ( isServerResponse( authState.error ) ) {
        message = authState.error.message;
      }
      if ( message ) {
        toast.error( message );
        dispatch( authActions.setSuccess( undefined ) );
      }
    }
  }, [ authState.success, authState.error ] );

  const onSubmit = ( credentials: LoginFormFields ) => authService.login( credentials );

  return (
    <>
      <ScreenTitle
        title='Login'
        close={true}
        backLink={routesPaths.onboarding}
      />
      <div className='login-container'>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={loginValidationSchema}
        >
          <Form>
            <Logo className='logo' />
            <PhoneField name='phone' />
            <PasswordField
              name='password'
              placeholder='Password'
            />
            <LoginWithSocials />
            <Submit text='Login' />
            <p className='forgot'>
              <Link to={routesPaths.restorePassword}>forgot password</Link>
            </p>
          </Form>
        </Formik>
      </div>
      <WebViewWarningModal />
    </>
  );
};
