import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import '../../assets/css/components/containers/Container.css';
import { isPathWithoutBottomNavigation } from '../../navigation';
import { useTypedSelector } from '../../state';
import { appActions } from '../../state/slices/appSlice';
import { BottomNavigation, DesktopHeader } from '../ui';

export interface ContainerProps {
  children?: ReactNode;
  className?: string;
}

export const Container = ( { children, className }: ContainerProps ) => {
  const dispatch = useDispatch();

  const [ preload, setPreload ] = useState( true );
  const { isAuthorized } = useTypedSelector( state => state.auth );

  const containerRef = useCallback( ( element: HTMLDivElement | null ) => {
    if ( element ) {
      dispatch( appActions.setContainerWidth( element.offsetWidth ) );
      dispatch(
        appActions.setContainerPadding(
          parseInt(
            window.getComputedStyle( element, null ).getPropertyValue( 'padding-left' )
          )
        )
      );
      dispatch( appActions.setContainerRef( element ) );
    }
  }, [] );

  useEffect( () => {
    setTimeout( () => setPreload( false ), 350 );
  } );

  const location = useLocation();

  const showBottomNavigation =
    isAuthorized && !isPathWithoutBottomNavigation( location.pathname );

  const containerClassName = [ 'container', className, preload ? 'preload' : '' ].join( ' ' );

  return (
    <div className={containerClassName}>
      <DesktopHeader />
      <div
        className='content-container'
        ref={containerRef}
      >
        {children}
        {showBottomNavigation && <BottomNavigation />}
      </div>
    </div>
  );
};
