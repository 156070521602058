import { useFormikContext } from 'formik';
import { MouseEventHandler } from 'react';
import { Button, ButtonProps } from '../controls';

export type SubmitProps = Pick<ButtonProps, 'text' | 'styles' | 'className' | 'onClick'>;

export const Submit = ( { text, styles, className, onClick }: SubmitProps ) => {
  const { isValid } = useFormikContext();

  const onButtonClick: MouseEventHandler = e => {
    if ( onClick ) onClick( e );
  };

  return (
    <Button
      text={text}
      onClick={onButtonClick}
      type='submit'
      disabled={!isValid}
      styles={styles}
      className={className}
    />
  );
};
