import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppSliceState } from './types';

const initialState: AppSliceState = {
  containerPadding: 0,
  containerWidth: 0,
  containerElement: undefined,
  gaInitialized: false
};

export const appSlice = createSlice( {
  initialState: initialState,
  name: 'app',
  reducers: {
    setContainerWidth: ( state, action: PayloadAction<number> ) => {
      state.containerWidth = action.payload;
    },
    setContainerPadding: ( state, action: PayloadAction<number> ) => {
      state.containerPadding = action.payload;
    },
    setContainerRef: (
      state,
      action: PayloadAction<AppSliceState['containerElement']>
    ) => {
      state.containerElement = action.payload as never;
    },
    setGAInitialized: ( state, action: PayloadAction<AppSliceState['gaInitialized']> ) => {
      state.gaInitialized = action.payload;
    }
  }
} );

export const appActions = appSlice.actions;
