import { ChangeEventHandler, TouchEventHandler, useCallback, useState } from 'react';
import styles from '../EventCreationScreen.module.scss';

export interface AgeScrollerProps {
  value: number;
  onChange: ( value: number ) => void;
  min?: number;
  max?: number;
  placeholder?: string;
  isError?: boolean;
}

export const AgeScroller = ( {
  onChange,
  value,
  min = 18,
  max = 120,
  placeholder,
  isError
}: AgeScrollerProps ) => {
  const [ touchStart, setTouchStart ] = useState<number>();
  const [ initialValue, setInitialValue ] = useState<number | undefined>();
  const translate = ( value - min + 2 ) * 20;

  const onInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    onChange( +event.target.value );
  };

  const preventDefault = useCallback( ( e: Event ) => e.preventDefault(), [] );

  const onTouchMove: TouchEventHandler<HTMLDivElement> = event => {
    const touch = event.changedTouches[0];
    const diff = -( touch.clientY - ( touchStart || 0 ) );
    const newVal = ( initialValue || min ) + Math.round( diff / 20 );
    onChange( newVal >= min ? ( newVal <= max ? newVal : max ) : min );
  };
  const onTouchStart: TouchEventHandler<HTMLDivElement> = event => {
    const touch = event.changedTouches[0];
    setTouchStart( touch.clientY );
    setInitialValue( value );
    window.addEventListener( 'touchmove', preventDefault, { passive: false } );
    window.addEventListener( 'scroll', preventDefault, { passive: false } );
    window.addEventListener( 'wheel', preventDefault, { passive: false } );
  };
  const onTouchEnd: TouchEventHandler<HTMLDivElement> = () => {
    setTouchStart( undefined );
    setInitialValue( undefined );
    window.removeEventListener( 'touchmove', preventDefault );
    window.removeEventListener( 'scroll', preventDefault );
    window.removeEventListener( 'wheel', preventDefault );
  };

  return (
    <div
      className={styles['age-scroller']}
      onTouchMove={onTouchMove}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      data-error={isError}
    >
      <input
        type='number'
        value={value}
        onChange={onInputChange}
        min={min}
        max={max}
        placeholder={placeholder}
      />
      <div
        className={styles['options']}
        data-visible={!!initialValue}
      >
        <div
          className={styles['wrapper']}
          style={{ transform: `translateY(-${translate}px)` }}
        >
          {Array.from( { length: max - min + 5 }, ( _, index ) => {
            const optionValue = min + index - 2;

            return (
              <div
                className={styles['option']}
                key={index}
                data-current={optionValue === value}
                data-disabled={optionValue > max || optionValue < min}
              >
                {optionValue}
              </div>
            );
          } )}
        </div>
      </div>
    </div>
  );
};
