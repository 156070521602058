import { Link } from 'react-router-dom';
import { ReactComponent as ClockIcon } from '../../../../assets/img/icons/clock.svg';
import { ReactComponent as InvitePreviewIcon } from '../../../../assets/img/icons/invitePreview.svg';
import { routesPaths } from '../../../../navigation';
import { Invite } from '../../../../services';
import { getEventStartString, getInviteTimeString } from '../../../../utils';
import styles from './InviteCard.module.sass';

export interface InvitePreviewCardProps {
  invites: Invite[];
  type?: 'plain' | 'crew';
}

export const InvitePreviewCard = ( { invites, type }: InvitePreviewCardProps ) => {
  if ( !invites.length ) return null;

  const invite = invites[0];
  const user = invite.author;
  const event = invite.event;

  return (
    <article
      className={[
        styles['invite-card'],
        styles['preview'],
        invites.length > 1 ? styles['multiple'] : undefined
      ].join( ' ' )}
    >
      <Link to={routesPaths.invites}>
        <div className={styles['header']}>
          <InvitePreviewIcon />
          <h1>
            {invites.length > 1 ? `${invites.length} crewww members` : 'A crewww member'}{' '}
            {type === 'crew' ? 'added you to their crew!' : 'invited you to their event!'}
          </h1>
        </div>
        <div className={styles['content']}>
          <div className={styles['user-info']}>
            <div className={styles['avatar']}>
              {user.media.length ? (
                <img
                  src={user.media[0].url}
                  alt={user.name}
                />
              ) : (
                <div className={styles['no-image']}>{user.name[0]}</div>
              )}
            </div>
            <span className={styles['name']}>{user.name}</span>
          </div>
          <div className={styles['event-info']}>
            <div className={styles['content-top']}>
              <span>{event.emoji}</span>
              <span className={styles['invite-time']}>
                {getInviteTimeString( invite.createdAt )}
              </span>
            </div>
            <h2>{event.title}</h2>
            <span className={styles['event-start']}>
              <ClockIcon /> {getEventStartString( event )}
            </span>
          </div>
        </div>
      </Link>
    </article>
  );
};
