import { getDownloadURL, ref } from 'firebase/storage';
import { authorizedAxiosInstance, isServerResponse, Media, ServerResponse } from '.';
import { firebaseStorage } from './firebase/configuration';
import { firebaseService } from './firebase/firebaseService';
import { mainApi } from './mainApi';

export const mediaService = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getUserMedia: build.query<Media[], string>( {
      query: uid => ( { url: `/media/?author=${uid}`, method: 'GET' } ),
      providesTags: ( result, error, arg ) =>
        result ? [ { type: 'Media', id: arg } ] : [ 'Media' ]
    } ),
    uploadUserMedia: build.mutation<ServerResponse, { image: File; uid: string }>( {
      invalidatesTags: ( result, error, arg ) =>
        result?.success ? [ { type: 'Media', id: arg.uid } ] : [],
      queryFn: async ( { image, uid } ) => {
        const firebaseResult = await firebaseService.uploadUserImage( image, uid );
        if ( isServerResponse( firebaseResult ) ) {
          return {
            error: firebaseResult.message || 'Could not upload image to firebase'
          };
        }
        const apiResult = await authorizedAxiosInstance.post<ServerResponse>( '/media', {
          url: await getDownloadURL( firebaseResult ),
          firebasePath: firebaseResult.fullPath
        } );
        return apiResult.status === 200
          ? { data: apiResult.data }
          : { error: apiResult.data.message || 'Could not upload image to server' };
      }
    } ),
    deleteUserMedia: build.mutation<ServerResponse, Media>( {
      invalidatesTags: ( result, error, arg ) =>
        result?.success ? [ { type: 'Media', id: arg.author } ] : [],
      queryFn: async media => {
        await firebaseService.deleteUserImage( ref( firebaseStorage, media.firebasePath ) );
        const apiResult = await authorizedAxiosInstance.delete<ServerResponse>(
          `/media/${media._id}`
        );
        return apiResult.status === 200
          ? { data: apiResult.data }
          : { error: apiResult.data.message || 'Could not delete image from server' };
      }
    } )
  } )
} );

export const {
  useGetUserMediaQuery,
  useUploadUserMediaMutation,
  useDeleteUserMediaMutation
} = mediaService;
