import { GetTagsParams, Tag } from '.';
import { mainApi } from './mainApi';

export const tagsService = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getTags: build.query<Tag[], GetTagsParams>( {
      query: params => ( {
        url: '/tags',
        params,
        method: 'GET'
      } )
    } )
  } )
} );

export const { useGetTagsQuery } = tagsService;
