import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import '../../assets/css/components/modal/EventLocationModal.css';
import { Event } from '../../services';
import { useTypedSelector } from '../../state';
import { Button } from '../controls';
import { LoadingIndicator } from '../ui';

export interface EventLocationModalProps {
  close: () => void;
  name: string;
}
const libraries: 'places'[] = [ 'places' ];

export const EventLocationModal = ( { name, close }: EventLocationModalProps ) => {
  const { values, setFieldValue } = useFormikContext();
  const { lat: userLat, lng: userLng } = useTypedSelector( state => state.location );

  const fieldValue = ( values as Record<string, Event['location']> )[name];
  const defaultLocation = { lat: fieldValue.lat, lng: fieldValue.lng };
  const title = fieldValue.title;

  const [ markerLocation, setMarkerLocation ] = useState<
    { lat: number; lng: number } | undefined
  >(
    defaultLocation.lat && defaultLocation.lng
      ? {
          lat: defaultLocation.lat,
          lng: defaultLocation.lng,
        }
      : undefined
  );
  const [ locationTitle, setLocationTitle ] = useState( title );
  const [ map, setMap ] = useState<google.maps.Map | null>( null );
  const [ geocoder, setGeocoder ] = useState<google.maps.Geocoder | null>( null );
  const inputRef = useRef<HTMLInputElement>( null );

  const { isLoaded } = useJsApiLoader( {
    googleMapsApiKey: 'AIzaSyAtSWhsW6immaDcyMDT7JSLZ_3zfAdHF0g',
    libraries: libraries,
  } );

  useEffect( () => {
    if ( isLoaded ) setGeocoder( new google.maps.Geocoder() );
  }, [ isLoaded ] );

  useEffect( () => {
    if ( map && markerLocation ) {
      map.panTo( markerLocation );
      map.setZoom( 16 );
    }
  }, [ map, markerLocation ] );

  const onClick = ( event: google.maps.MapMouseEvent ) => {
    const latLng = event.latLng;
    if ( latLng ) {
      const coords = { lat: latLng.lat(), lng: latLng.lng() };
      if ( coords.lat !== markerLocation?.lat && coords.lng !== markerLocation?.lng )
        setMarkerLocation( coords );
      if ( geocoder )
        geocoder
          .geocode( { location: coords } )
          .then( response => {
            const address = response.results[0].formatted_address;
            if ( inputRef.current ) inputRef.current.value = address;
            setLocationTitle( address );
          } )
          .catch( e => window.alert( 'Geocoder failed due to: ' + e ) );
    }
  };

  const onPlaceSelected = ( place: google.maps.places.PlaceResult ) => {
    if ( !place.geometry || !place.geometry.location || !place.name ) return;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setMarkerLocation( { lat, lng } );
    setLocationTitle( place.name );
  };

  const onConfirmLocation = () => {
    setFieldValue( name, {
      lat: markerLocation?.lat || NaN,
      lng: markerLocation?.lng || NaN,
      title: inputRef.current?.value || locationTitle,
    } as Event['location'] );
    close();
  };

  const onMapLoad = ( map: google.maps.Map ) => {
    setMap( map );
  };

  if ( !isLoaded ) return <LoadingIndicator />;

  return (
    <div className='event-location-modal'>
      <GoogleMap
        mapContainerClassName='modal-map'
        center={
          markerLocation ? undefined : { lat: userLat || 1.33, lng: userLng || 103.8 }
        }
        zoom={12}
        options={{
          disableDefaultUI: true,
          minZoom: 2,
        }}
        onClick={onClick}
        onLoad={onMapLoad}
      >
        {markerLocation && <Marker position={markerLocation} />}
      </GoogleMap>
      <Autocomplete
        apiKey='AIzaSyCpQeiD37I4Tfwybcwa5MVJ-tM_Upxvlo8'
        onPlaceSelected={onPlaceSelected}
        options={{
          types: [ 'establishment', 'geocode' ],
          fields: [ 'geometry', 'name' ],
        }}
        ref={inputRef}
      />
      <Button
        text='Confirm location'
        onClick={onConfirmLocation}
      />
    </div>
  );
};
