import { mainApi } from './mainApi';
import { Invite, InviteWithInvitee } from './models';

export const invitesApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getInvites: build.query<Invite[], { status: Invite['status'] } | void>( {
      query: params => ( {
        url: '/invites',
        params
      } ),
      providesTags: ( res, err ) => ( err ? [] : [ { type: 'invites', id: 'my' } ] )
    } ),
    getEventInvites: build.query<InviteWithInvitee[], string>( {
      query: id => ( {
        url: `/invites/event/${id}`
      } ),
      providesTags: ( res, err, id ) => ( err ? [] : [ { type: 'invites', id } ] )
    } ),
    inviteToEvent: build.mutation<void, { invitee: string; event: string }>( {
      query: fields => ( {
        url: '/invites',
        method: 'post',
        data: fields
      } ),
      invalidatesTags: ( res, err, { event } ) =>
        err ? [] : [ { type: 'invites', id: event } ]
    } ),
    readInvite: build.mutation<void, string>( {
      query: id => ( {
        url: `/invites/read/${id}`,
        method: 'put'
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ { type: 'invites', id: 'my' } ] )
    } )
  } )
} );

export const {
  useGetEventInvitesQuery,
  useGetInvitesQuery,
  useInviteToEventMutation,
  useReadInviteMutation
} = invitesApi;
