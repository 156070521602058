import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import { CurrentUser } from '../../services';
import { useGetCurrentUserInfoQuery } from '../../services/currentUserApi';
import { useGetInstagramMediaQuery } from '../../services/instagram';

export const useGetInstagramMedia = ( id: string ) => {
  const currentUser = useGetCurrentUserInfoQuery();

  const isAuthorizedInstagram = useMemo( () => {
    if ( !currentUser.data ) return false;
    return !!currentUser.data.instagram?.accessToken;
  }, [ currentUser.data ] );

  const instagramMedia = useGetInstagramMediaQuery(
    isAuthorizedInstagram
      ? {
          accessToken: ( currentUser.data as Required<CurrentUser> ).instagram.accessToken,
          fields: [ 'media_type', 'media_url' ],
          id,
        }
      : skipToken
  );

  return instagramMedia.data;
};
