import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Routes, useLocation } from 'react-router-dom';
import { useTypedSelector } from '../state';
import { renderRoutes, routes } from './routes';

export const AppRouter = () => {
  const location = useLocation();

  const { gaInitialized } = useTypedSelector( state => state.app );

  useEffect( () => {
    if ( gaInitialized ) {
      ReactGA.pageview( location.pathname, undefined, document.title );
    }
  }, [ location, gaInitialized ] );

  return <Routes>{renderRoutes( routes.app )}</Routes>;
};
