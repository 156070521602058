import { RiFacebookFill, RiGoogleFill } from 'react-icons/ri';
import { authService } from '../../../services';
import { facebookService } from '../../../services/facebookService';
import { googleService } from '../../../services/google';
import { IconButton } from '../../controls/IconButton';

export const LoginWithSocials = () => {

  const onFacebookClick = () => {
    facebookService.login().then( authData => {
      if ( authData ) authService.loginWithFacebook( authData );
    } );
  };

  const onGoogleClick = () => {
    googleService.login().then( code => {
      authService.loginWithGoogle( { code, googleRedirectUri: window.location.origin } );
    } );
  };
  
  return (
    <div className="login-socials">
      <p>or login with</p>
      <IconButton icon={RiFacebookFill} onClick={onFacebookClick} />
      <IconButton icon={RiGoogleFill} onClick={onGoogleClick} />
    </div>
  );
};