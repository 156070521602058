import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { ReactComponent as CakeIcon } from '../../../../assets/img/icons/cake.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/img/icons/clock.svg';
import { ReactComponent as FemaleIcon } from '../../../../assets/img/icons/female.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/img/icons/location.svg';
import { ReactComponent as MaleIcon } from '../../../../assets/img/icons/male.svg';
import { ReactComponent as SandGlassIcon } from '../../../../assets/img/icons/sandglass.svg';
import { ReactComponent as UserIcon } from '../../../../assets/img/icons/userStroke.svg';
import { EventSingle } from '../../../../services';
import { generateMapsLocationURL, getEventStartString } from '../../../../utils';
import { CountDown, EventInfo } from '../../../ui';
import '../EventScreen.sass';
import styles from './EventBody.module.scss';
export interface EventMainInfoProps {
  event: EventSingle;
}

export const EventMainInfo = ( { event }: EventMainInfoProps ) => {
  const mapsUrl = useMemo( () => generateMapsLocationURL( event ), [ event ] );

  const genderIcon = ( gender: 'male' | 'female', muted = false ) => {
    return gender === 'male' ? (
      <MaleIcon className={muted ? styles['muted'] : undefined} />
    ) : (
      <FemaleIcon className={muted ? styles['muted'] : undefined} />
    );
  };

  return (
    <>
      <EventInfo
        items={[
          {
            icon: LocationIcon,
            element: (
              <a
                href={mapsUrl}
                target='_blank'
                rel='noreferrer'
              >
                <u>{event.location.title}</u>
              </a>
            )
          },
          {
            icon: UserIcon,
            element: (
              <p>
                {event.byoc
                  ? event.maxMembers
                  : `${event.members.length}/${event.maxMembers}`}
              </p>
            )
          },
          ...( event.byoc
            ? [
                {
                  element: (
                    <div className={styles['byoc-genders']}>
                      <div className={styles['icon']}>
                        {genderIcon( event.byoc.authorCrewGender )}
                      </div>
                      <div className={styles['icon']}>
                        {genderIcon( event.byoc.targetCrewGender, !event.byoc.joinedCrew )}
                      </div>
                    </div>
                  )
                }
              ]
            : [] ),
          { icon: ClockIcon, element: <p>{getEventStartString( event )}</p> },
          {
            icon: SandGlassIcon,
            element: <CountDown target={DateTime.fromJSDate( event.start )} />,
            className: 'countdown'
          }
        ]}
      />
      <EventInfo
        className='start'
        items={[
          ...( event.filters?.age
            ? [
                {
                  icon: CakeIcon,
                  element: (
                    <>
                      {event.filters.age.min}-{event.filters.age.max}
                    </>
                  )
                }
              ]
            : [] ),
          ...( event.filters?.gender
            ? [
                {
                  icon: event.author.gender === 'female' ? FemaleIcon : MaleIcon,
                  element: <>Only</>
                }
              ]
            : [] )
        ]}
      />
    </>
  );
};
