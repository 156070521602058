import '../../../assets/css/components/controls/ListControls/ListControls.css';
import { ListControlsSort, ListControlsSortProps } from './ListSort';



export interface ListControlsProps {
  controls: ListControlsSortProps[]
}

export const ListControls = ( { controls } : ListControlsProps ) => {

  return (
    <div className="list-controls">
      {controls.map( ( control, index ) => (
        <ListControlsSort key={index} {...control} />
      ) )}
    </div>
  );
};