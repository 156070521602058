import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import '../../assets/css/components/controls/ImageSelect.css';
import { ReactComponent as Close } from '../../assets/img/icons/close.svg';
import { Button } from './Button';

export interface ImageSelectProps {
  name: string,
  defaultUrl?: string,
  onChange?: ( img: File ) => void,
  onDelete?: ( img: File ) => void
}

export const ImageSelect = (
  { name, defaultUrl, onChange, onDelete } : ImageSelectProps
) => {

  const [ previewUrl, setPreviewUrl ] = useState( defaultUrl );
  const [ currentFile, setCurrentFile ] = useState<File | null>( null );
  const [ cropper, setCropper ] = useState<Cropper | null>( null );
  const [ isCropModalOpen, setCropModalOpen ] = useState( false );
  const [ lastFileName, setLastFileName ] = useState<string>();

  const cropImageRef = useCallback( ( imageElement: HTMLImageElement | null ) => {
    if ( imageElement ) {
      const cropper = new Cropper( imageElement, {
        aspectRatio: 1 / 1,
        viewMode: 1,
        background: false,
      } );
      setCropper( cropper );
    }
  }, [] );

  const onInputChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    if ( e.target.files?.length ) {
      setPreviewUrl( window.URL.createObjectURL( e.target.files[0] ) );
      setCropModalOpen( true );
      setLastFileName( e.target.files[0].name );
      // onChange && onChange( e.target.files[0] );
      e.target.value = '';
    }
  };

  const onCancel = () => {
    setPreviewUrl( undefined );
    onDelete && currentFile && onDelete( currentFile );
  };

  useEffect( () => {
    if ( currentFile && onChange ) {
      onChange( currentFile );
      setPreviewUrl( window.URL.createObjectURL( currentFile ) );
    }
  }, [ currentFile ] );

  return (
    <div className="image-select">
      <input
        type="file"
        accept=".jpg, .jpeg, .png"
        name={name}
        id={name}
        hidden
        onChange={onInputChange}
      />
      <label
        htmlFor={name}
        style={ previewUrl ? { backgroundImage: `url(${previewUrl})` } : undefined }
        className={previewUrl ? 'preview' : undefined}
      />
      <Close className='close' onClick={onCancel}/>
      <ReactModal
        isOpen={isCropModalOpen}
        className='crop-modal'
        overlayClassName='crop-overlay'
        appElement={document.getElementsByName( 'body' )}
      >
        <img src={previewUrl} ref={cropImageRef}/>
        <Button text='Done' onClick={() => {
          if ( cropper ) {
            cropper.getCroppedCanvas().toBlob( blob => {
              if ( blob ) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const blobTmp : any = blob;
                blobTmp.lastModifiedDate = new Date;
                blobTmp.name = lastFileName || randomString( 8 ) + 'jpg';
                setCurrentFile( blobTmp );
                setCropModalOpen( false );
              }
            } );
          }
        }} />
      </ReactModal>
    </div>
  );
};

function randomString( length: number ) {
  let result           = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt( Math.floor( Math.random() * charactersLength ) );
  }
  return result;
}