import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as FemaleIcon } from '../../../../../../assets/img/icons/female.svg';
import { ReactComponent as InvitedIcon } from '../../../../../../assets/img/icons/invited.svg';
import { ReactComponent as MaleIcon } from '../../../../../../assets/img/icons/male.svg';
import { ReactComponent as UserIcon } from '../../../../../../assets/img/icons/userStrokeAlt.svg';
import { GetUsersResponse } from '../../../../../../services';
import { Button } from '../../../../../controls';
import { Avatar } from '../../../../../ui';
import styles from './SearchUser.module.scss';

export interface SearchUserProps {
  user: GetUsersResponse[0];
  isAdded?: boolean;
  onAdd?: () => void;
}

export const SearchUser = ( { user, isAdded, onAdd }: SearchUserProps ) => {
  const age = useMemo( () => {
    if ( user.birthDate )
      return Math.floor( -DateTime.fromJSDate( user.birthDate.date ).diffNow( 'years' ).years );
    return user.age;
  }, [ user ] );

  const genderIcon = useMemo( () => {
    if ( user.gender === 'male' ) return <MaleIcon />;
    if ( user.gender === 'female' ) return <FemaleIcon />;
  }, [ user ] );

  return (
    <div className={styles['search-user']}>
      <div className={styles['left']}>
        <Avatar user={user} />
        <div className={styles['info']}>
          <Link
            to={`/profile/${user._id}`}
            target='_blank'
          >
            <p className={styles['name']}>
              {user.name}
              {genderIcon}
            </p>
          </Link>
          <p className={styles['age']}>
            <UserIcon />
            {age}
          </p>
        </div>
      </div>
      <Button
        text={
          isAdded ? (
            <>
              <InvitedIcon />
              Added
            </>
          ) : (
            'Add'
          )
        }
        onClick={isAdded ? undefined : onAdd}
        style={isAdded ? 'secondary' : undefined}
        className={[ styles['invite-button'], isAdded && styles['invited'] ].join( ' ' )}
      />
    </div>
  );
};
