import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDocTitle } from '../../hooks';
import { routesPaths } from '../../navigation';
import { useGetCurrentUserInfoQuery } from '../../services/currentUserApi';
import {
  useGetCurrentUserJoinedEventsQuery,
  useGetUserCreatedEventsQuery
} from '../../services/eventsService';
import { ListControls } from '../controls';
import { EventItem, LoadingIndicator, ScreenTitle } from '../ui';

export interface MyEventsScreenState {
  show?: 'joined' | 'created';
}

export const MyEventsScreen = () => {
  useDocTitle( 'Crewww - My Events' );

  const navigate = useNavigate();
  const { state } = useLocation();
  const [ show, setShow ] = useState<string>(
    ( state as MyEventsScreenState )?.show || 'joined'
  );

  const currentUser = useGetCurrentUserInfoQuery();
  const createdEvents = useGetUserCreatedEventsQuery( currentUser.data?._id || skipToken );
  const joinedEvents = useGetCurrentUserJoinedEventsQuery(
    show === 'joined' ? undefined : skipToken
  );

  const events = show === 'joined' ? joinedEvents.data : createdEvents.data;

  if (
    currentUser.isLoading ||
    createdEvents.isLoading ||
    joinedEvents.isLoading ||
    !events
  )
    return <LoadingIndicator />;

  return (
    <>
      <ScreenTitle
        title='My Events'
        action={{ label: 'View all', onClick: () => navigate( routesPaths.events.index ) }}
      />
      <ListControls
        controls={[
          {
            options: [
              { value: 'created', label: 'Events created' },
              { value: 'joined', label: 'Events joined' }
            ],
            onChange: setShow,
            placeholder: '',
            value: show
          }
        ]}
      />
      {events.map( ( event, index ) => (
        <EventItem
          event={event}
          key={index}
        />
      ) )}
      <div style={{ marginBottom: 80 }} />
    </>
  );
};
