import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Avatar } from '..';
import { ReactComponent as CloseCircleIcon } from '../../../../assets/img/icons/close-circle.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/img/icons/message.svg';
import { routesPaths } from '../../../../navigation';
import { UserMediaExpanded } from '../../../../services';
import { useGetCurrentUserInfoQuery } from '../../../../services/currentUserApi';
import {
  useGetEventQuery,
  useRemoveEventMemberMutation
} from '../../../../services/eventsService';
import { getEventIdFromSignature } from '../../../../utils';
import { Button, RadioSelect } from '../../../controls';
import { Modal } from '../../../modals';
import styles from './EventMember.module.scss';

export interface EventMemberProps {
  user: UserMediaExpanded;
  creator?: boolean;
  isBYOC?: boolean;
  pending?: boolean;
}

export const EventMember = ( { user, creator, isBYOC, pending }: EventMemberProps ) => {
  const { eventSignature } = useParams();

  const eventId = useMemo( () => {
    if ( eventSignature ) return getEventIdFromSignature( eventSignature );
  }, [ eventSignature ] );

  const { data: currentUser } = useGetCurrentUserInfoQuery();
  const { data: event } = useGetEventQuery( eventId || skipToken, {
    pollingInterval: 3000
  } );
  const [ removeMember ] = useRemoveEventMemberMutation();

  const [ isRemoveModalOpen, setRemoveModalOpen ] = useState( false );
  const [ removeReason, setRemoveReason ] = useState<string>();

  const toggleRemoveModalOpen = ( value: boolean ) => () => setRemoveModalOpen( value );

  const isCurrentUserAuthor = useMemo( () => {
    if ( currentUser && event ) {
      return event.author._id === currentUser._id;
    }
    return false;
  }, [ currentUser, event ] );

  const onRemoveConfirm = () => {
    if ( isCurrentUserAuthor && eventId && removeReason ) {
      removeMember( { eventId, memberId: user._id, reason: removeReason } );
    }
  };

  const isPassed = useMemo( () => {
    if ( !event ) return true;
    return new Date( event.start ) < new Date();
  }, [ event ] );

  return (
    <div className={styles['event-member']}>
      <Link
        className={styles['info']}
        to={`${routesPaths.profile.index}/${user._id}`}
      >
        <Avatar user={user} />
        {user && <div className={styles['name']}>{user.name}</div>}
        {creator && <span>creator</span>}
        {pending && <span>Pending</span>}
      </Link>
      {isCurrentUserAuthor && !creator && !isPassed && (
        <div className={styles['actions']}>
          {!isBYOC && (
            <a
              href={`https://wa.me/${( user as unknown as { phone: string } ).phone}`}
              target='_blank'
              rel='noreferrer'
            >
              <MessageIcon />
            </a>
          )}
          <button onClick={toggleRemoveModalOpen( true )}>
            <CloseCircleIcon />
          </button>
        </div>
      )}
      {isCurrentUserAuthor && (
        <Modal
          title='Remove the user'
          isOpen={isRemoveModalOpen}
          onClose={toggleRemoveModalOpen( false )}
        >
          <p>
            Let us know why you removed the user so we can better design Crewww for you!
          </p>
          <RadioSelect
            options={[
              { label: 'Did not reach out to me', value: 'didNotReach' },
              { label: 'Is not responsive', value: 'notResponsive' },
              { label: 'Other', value: 'other' }
            ]}
            value={removeReason}
            onChange={setRemoveReason}
          />
          {removeReason && (
            <Button
              text={'Confirm'}
              styles={{ marginTop: 16, marginBottom: 0 }}
              onClick={onRemoveConfirm}
            />
          )}
        </Modal>
      )}
    </div>
  );
};
