import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDocTitle } from '../../../hooks';
import { useGetCurrentUserInfoQuery } from '../../../services/currentUserApi';
import { useGetEventQuery } from '../../../services/eventsService';
import { inviteScreenActions } from '../../../state/slices/inviteScreenSlice';
import { getEventIdFromSignature, isEventAuthor, isEventMember } from '../../../utils';
import { EventBannerSingle, LoadingIndicator } from '../../ui';
import './EventScreen.sass';
import { JoinByocModal } from './components';
import { EventBody } from './components/EventBody';

export const EventScreen = () => {
  const { eventSignature } = useParams();

  const eventId = useMemo( () => {
    if ( !eventSignature ) throw new Error( 'no event signature' );
    return getEventIdFromSignature( eventSignature );
  }, [ eventSignature ] );

  const dispatch = useDispatch();

  const event = useGetEventQuery( eventId as string, { pollingInterval: 3000 } );
  const currentUser = useGetCurrentUserInfoQuery();

  useDocTitle( event.data?.title );

  const [ bannerHeight, setBannerHeight ] = useState( 180 );
  const [ isByocJoinModalOpen, setByocJoinModalOpen ] = useState( false );

  const bannerRef = useCallback(
    ( element: HTMLDivElement | null ) => {
      if ( element ) setBannerHeight( element.offsetHeight );
    },
    [ setBannerHeight ]
  );
  const isMember = useMemo(
    () => isEventMember( event.data, currentUser.data ),
    [ event.data, currentUser.data ]
  );
  const isAuthor = useMemo(
    () => isEventAuthor( event.data, currentUser.data ),
    [ event.data, currentUser.data ]
  );

  useEffect( () => {
    return () => {
      dispatch( inviteScreenActions.clearInviteScreenState() );
    };
  }, [] );

  if ( event.isLoading || currentUser.isLoading ) return <LoadingIndicator />;
  if ( event.isError || !event.data || currentUser.error || !currentUser.data )
    return <>error</>;

  const toggleByocJoinModal = ( value: boolean ) => () => setByocJoinModalOpen( value );

  if ( isByocJoinModalOpen && event.data ) {
    return (
      <JoinByocModal
        event={event.data}
        close={toggleByocJoinModal( false )}
      />
    );
  }

  return (
    <>
      <EventBannerSingle
        event={event.data}
        bannerRef={bannerRef}
        isAuthor={isAuthor}
        isMember={isMember}
      />
      <EventBody
        event={event.data}
        bannerHeight={bannerHeight}
        isAuthor={isAuthor}
        isMember={isMember}
        openByocJoin={toggleByocJoinModal( true )}
      />
    </>
  );
};
