import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useGetInstagramImages } from '../../../../hooks/instagram';
import { useGetCurrentUserInfoQuery } from '../../../../services/currentUserApi';
import { useGetUserQuery } from '../../../../services/usersApi';
import { LoadingIndicator } from '../../global';
import styles from './InstagramBlock.module.sass';
import { InstagramBlockSlide } from './InstagramBlockSlide';

SwiperCore.use( [ Pagination ] );

export const InstagramBlock = () => {
  const params = useParams() as { userId?: string };
  const user = useGetUserQuery( params.userId || skipToken );
  const currentUser = useGetCurrentUserInfoQuery();

  const { images, isLoading } = useGetInstagramImages( params.userId );
  const [ imagesPerSlide ] = useState( 9 );

  if (
    !currentUser.data?.instagram?.accessToken ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ( params.userId && !( user.data as any )?.instagram?.userId ) ||
    !images ||
    !images.length
  )
    return null;

  return (
    <div className={styles['instagram-block']}>
      <h4 className='label'>Instagram</h4>
      {isLoading ? (
        <div style={{ position: 'relative', height: 100 }}>
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <Swiper
            slidesPerView={1}
            spaceBetween={50}
            pagination={{
              el: `.${styles['pagination']}`,
              type: 'bullets',
              bulletClass: styles['bullet'],
              bulletActiveClass: styles['active'],
              clickable: true,
            }}
          >
            {Array( 3 )
              .fill( null )
              .map( ( _, index ) => {
                if ( images.length < imagesPerSlide * index ) return null;
                return (
                  <SwiperSlide key={index}>
                    <InstagramBlockSlide
                      imagesPerSlide={imagesPerSlide}
                      slideNumber={index}
                    />
                  </SwiperSlide>
                );
              } )}
          </Swiper>
          <div className={styles['pagination']}></div>
        </>
      )}
    </div>
  );
};
