import { Media, UserMediaExpanded } from '../../services';
import { InstagramMedia } from '../../services/instagram';

export const localStoragePath = {
  token: 'jwt',
  refreshToken: 'refreshToken',
  firstLogin: 'firstLogin',
  facebookState: 'facebookState'
};

export interface ApiSliceState {
  isLoading: boolean;
  error?: string | object;
  success?: boolean;
}

export interface AuthSliceState extends ApiSliceState {
  accessToken: string;
  isAuthorized: boolean;
  refreshToken: string;
  firstLogin: boolean;
  restorePasswordError?: string;
  restorePasswordSuccess?: boolean;
}

export interface LocationSliceState {
  lat?: number;
  lng?: number;
  locationReceived: boolean;
  locationDenied?: boolean;
}

export interface ImageViewSliceState {
  image?:
    | {
        type: 'app';
        media: Media;
      }
    | {
        type: 'instagram';
        media: InstagramMedia;
      };
}

export interface AppSliceState {
  containerPadding: number;
  containerWidth: number;
  containerElement: HTMLDivElement | undefined;
  gaInitialized: boolean;
}

export interface InviteScreenSliceState {
  eventId?: string;
  selectedFeatures?: string[];
  selectedInterests?: string[];
  suitableUsers?: UserMediaExpanded[];
  cancelInitRequest?: boolean;
  hasNext?: boolean;
  currentPage?: number;
}
