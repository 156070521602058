import { useFormikContext } from 'formik';
import { DropDown, DropDownProps } from '../controls';

export type DropDownFieldProps = Omit<DropDownProps, 'value' | 'onChange'>

export const DropDownField = (
  props : DropDownFieldProps
) => {

  const { values, handleChange, errors, touched, handleBlur } = useFormikContext();

  const error = ( errors as Record<string, string | undefined> )[props.name];
  const isTouched = ( touched as Record<string, boolean | undefined> )[props.name];

  return (
    <DropDown 
      {...props}
      onChange={handleChange( props.name )}
      value={( values as Record<string, string> )[props.name]}
      error={isTouched ? error : undefined}
      onBlur={handleBlur( props.name )}
    />
  );
  
};