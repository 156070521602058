import { Mutex } from 'async-mutex';
import axios from 'axios';
import { isServerResponse, LoginFormFields, TokensPair } from '.';
import { authActions } from '../state/slices/authSlice';
import { store } from '../state/store';
import { baseUrl } from './baseInstances';
import { mainApi } from './mainApi';
import { FacebookAuthData, GoogleAuthData } from './types';

const client = axios.create( {
  baseURL: baseUrl
} );

async function login( credentials: LoginFormFields ) {
  try {
    const { data, status } = await client.post( '/auth/login', credentials );
    if ( status === 200 ) await processTokensPair( data );
    else store.dispatch( authActions.setError( data ) );
  } catch ( error ) {
    processAuthError( error );
  }
}

const refresh = ( function () {
  let runningFunction: Promise<void> | null = null;
  return () => {
    if ( runningFunction !== null ) {
      return runningFunction;
    } else {
      runningFunction = ( async () => {
        const { refreshToken, isAuthorized } = store.getState().auth;
        try {
          if ( refreshToken ) {
            const { data, status } = await client.post( '/auth/refresh', { refreshToken } );
            if ( status === 200 ) processTokensPair( data );
            else logout();
          } else if ( isAuthorized ) {
            logout();
          }
        } catch {
          logout();
        } finally {
          runningFunction = null;
        }
      } )();
    }
    return runningFunction;
  };
} )();

const refreshMutex = ( function () {
  const mutex = new Mutex();
  const refresh = async () => {
    const { refreshToken, isAuthorized } = store.getState().auth;
    try {
      if ( refreshToken ) {
        const { data, status } = await client.post( '/auth/refresh', { refreshToken } );
        if ( status === 200 ) processTokensPair( data );
        else logout();
      } else if ( isAuthorized ) {
        logout();
      }
    } catch {
      logout();
    }
  };
  return async () =>
    mutex.isLocked()
      ? mutex.acquire().then( release => release() )
      : mutex.runExclusive( refresh );
} )();

async function restorePassword( phone: string, newPassword: string, otp: string ) {
  try {
    const { status } = await client.post( '/auth/restorePassword', {
      phone,
      newPassword,
      otp
    } );
    if ( status === 200 ) store.dispatch( authActions.setRestorePasswordSuccess( true ) );
    else {
      throw new Error( 'failed to restore password' );
    }
  } catch ( error: unknown ) {
    processRestorePasswordError( error );
  }
}

function processAuthError( error: unknown ) {
  let errorMessage = error;
  console.log( { error } );
  if ( axios.isAxiosError( error ) ) errorMessage = error.response?.data;
  else errorMessage = 'Unknown auth error';
  store.dispatch( authActions.setError( errorMessage as string | object ) );
}

function processRestorePasswordError( error: unknown ) {
  console.log( error );
  let errorMessage = error;
  if ( axios.isAxiosError( error ) && isServerResponse( error.response?.data ) ) {
    errorMessage = error.response?.data.message;
  }

  store.dispatch( authActions.setRestorePasswordSuccess( false ) );
  store.dispatch(
    authActions.setRestorePasswordError(
      typeof errorMessage === 'string' ? errorMessage : JSON.stringify( errorMessage )
    )
  );
}

async function processTokensPair( tokensPair: TokensPair ) {
  store.dispatch( authActions.setAccessToken( tokensPair.accessToken ) );
  store.dispatch( authActions.setRefreshToken( tokensPair.refreshToken ) );
}

function logout() {
  store.dispatch( authActions.setIsAuthorized( false ) );
  localStorage.clear();
  store.dispatch( mainApi.util.resetApiState() );
}

async function loginWithFacebook( facebookAuthData: FacebookAuthData ) {
  try {
    const { data, status } = await client.post(
      '/socials/facebook/login',
      facebookAuthData
    );
    if ( status === 200 ) {
      processTokensPair( data );
    } else {
      store.dispatch( authActions.setError( data ) );
    }
  } catch ( err ) {
    processAuthError( err );
  }
}

async function loginWithGoogle( googleAuthData: GoogleAuthData ) {
  try {
    const { data, status } = await client.post( '/socials/google/login', googleAuthData );
    if ( status === 200 ) {
      processTokensPair( data );
    } else {
      store.dispatch( authActions.setError( data ) );
    }
  } catch ( err ) {
    processAuthError( err );
  }
}

export const authService = {
  login,
  refresh,
  logout,
  restorePassword,
  processTokensPair,
  loginWithFacebook,
  loginWithGoogle,
  refreshMutex
};
