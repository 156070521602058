import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isServerResponse } from '../../services';
import { getEventPreview } from '../../services/eventsService';
import { getEventUrl } from '../../utils';

export const EventPreviewRedirect = () => {
  const navigate = useNavigate();

  useEffect( () => {
    const regexMatch = window.location.href.match( /preview\/(.*)$/ );
    if ( regexMatch ) {
      const token = regexMatch[1];
      getEventPreview( token ).then( response => {
        if ( isServerResponse( response ) || !response ) {
          console.log( { response } );
        } else {
          navigate( getEventUrl( response ) );
        }
      } );
    }
  }, [] );

  return null;
};
