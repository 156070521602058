import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import '../../assets/css/components/controls/OTPInput.css';
import { CountDown } from '../ui/views/CountDown';

export interface OTPInputProps {
  name: string,
  value: string,
  onChange: ( otp: string ) => void,
  title?: string,
  action?: { label: string, onClick: () => void, buttonText: string, lastSend: DateTime },
  disabled?: boolean
}

const otpIndexes = [ 0,1,2,3,4,5 ];

export const OTPInput = (
  { name, value, onChange, title, action, disabled } : OTPInputProps
) => {

  const splittedValue = value.split( '' );
  const inputsRefs = useRef<HTMLInputElement[]>( [] );

  const [ showButton, setShowButton ] = useState( false );
  const [ canPerformAction, setCanPerformAction ] = useState( false );

  const onActionLabelClick = () => setShowButton( !showButton );
  const onActionClick = canPerformAction ? () => {
    action?.onClick();
    setShowButton( false );
  } : undefined;
  
  const onCountDownReached = () => {
    setCanPerformAction( true );
  };
  
  const onOTPChange = ( index: number, value: string ) => {
    inputsRefs.current[index].value = value.slice( -1 );
    const inputs = otpIndexes.reduce<HTMLInputElement[]>( ( inputs, i ) => {
      inputs.push( inputsRefs.current[i] );
      return inputs;
    }, [] );
    const otp = inputs.map( input => input.value ).join( '' );
    onChange( otp );
    if ( value && index > otp.length - 1 ) inputsRefs.current[otp.length].focus();
    else if ( !value && index > 0 ) inputsRefs.current[index-1].focus();
    else if ( value && index < 5 ) inputsRefs.current[index+1].focus();
  };
  

  return (
    <div className={[ 'otp-input', disabled ? 'disabled' : undefined ].join( ' ' )}>
      {title && <label>{title}</label>}
      <div className="inputs">
        {otpIndexes.map( index => (
          <input 
            key={index}
            type='number' 
            ref={el => inputsRefs.current[index] = ( el as HTMLInputElement )}
            value={splittedValue[index] || ''}
            onChange={( event ) => onOTPChange( index, event.target.value )}
          />
        ) )}
      </div>

      {action && (
        <div className="action">
          <span onClick={onActionLabelClick}>{action.label}</span>
          {showButton && (
            <button className="button" onClick={onActionClick} disabled={!canPerformAction}>
              <CountDown 
                target={action.lastSend} 
                reachedLabel={action.buttonText} 
                onReached={onCountDownReached}
              />
            </button>
          )}
        </div>
      )}

      <input type="text" name={name} hidden defaultValue={value}/>
    </div>
  );
};