import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Ref } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../../assets/img/icons/back-arrow.svg';
import { ReactComponent as ShareIcon } from '../../../../assets/img/icons/share.svg';
import { EventSingle } from '../../../../services';
import { useGetEventPreviewTokenQuery } from '../../../../services/eventsService';
import { getCssGradientObject } from '../../../../utils';

export interface EventBannerSingleProps {
  event: EventSingle;
  bannerRef: Ref<HTMLDivElement>;
  isAuthor: boolean;
  isMember: boolean;
}

export const EventBannerSingle = ( {
  event,
  bannerRef,
  isAuthor,
  isMember
}: EventBannerSingleProps ) => {
  const navigate = useNavigate();

  const previewToken = useGetEventPreviewTokenQuery(
    isMember && event._id ? event._id : skipToken
  );

  const onShare = () => {
    if ( previewToken.data ) {
      navigator.clipboard
        .writeText( `${window.location.origin}/preview/${previewToken.data.previewToken}` )
        .then( () => {
          toast.success( 'Link copied to clipboard' );
        } );
    }
  };

  const goBack = () => navigate( -1 );

  const isByoc = !!event.byoc;

  return (
    <div
      className='event-banner single'
      style={getCssGradientObject( event.bannerColor )}
      ref={bannerRef}
      data-byoc={isByoc}
    >
      <div className='emoji'>
        <div
          className='back'
          onClick={goBack}
        >
          <BackIcon className='triangle' />
        </div>
        {( isAuthor || isMember ) && (
          <div
            className='share'
            onClick={onShare}
          >
            <ShareIcon />
          </div>
        )}
        {event.emoji}
      </div>
      <div className='title'>{event.title}</div>
    </div>
  );
};
