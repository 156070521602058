import { DateTime } from 'luxon';
import { Event } from '../services';

export const getEventStartString = ( event: Pick<Event, 'start'> ) => {
  const date =
    typeof event.start === 'string'
      ? DateTime.fromISO( event.start )
      : DateTime.fromJSDate( event.start );
  const time =
    date.minute === 0
      ? date.toFormat( 'h a' ).toLowerCase()
      : date.toFormat( 'h:mma' ).toLowerCase();
  const isToday = date.hasSame( DateTime.now(), 'day' );
  const isTomorrow = date.hasSame( DateTime.now().plus( { days: 1 } ), 'day' );

  if ( isToday ) return `${time} today`;
  if ( isTomorrow ) return `${time} tomorrow`;

  return date.toFormat( 'LLL d, h a', { locale: 'en' } );
};

export const getInviteTimeString = ( date: Date ) => {
  const dateTime = DateTime.fromJSDate( date );
  const daysDiff = DateTime.now().diff( dateTime, 'days' ).days;
  if ( daysDiff < 1 ) {
    return dateTime.toFormat( 'h:mm a' );
  } else {
    return dateTime.toFormat( 'd LLL h:mm a' );
  }
};

const isoDateFormat =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString( value: unknown ): boolean {
  return !!value && typeof value === 'string' && isoDateFormat.test( value );
}

export function handleDates( body: unknown ) {
  if ( body === null || body === undefined || typeof body !== 'object' ) return body;

  for ( const key of Object.keys( body ) ) {
    const value = ( body as Record<string, unknown> )[key];
    if ( isIsoDateString( value ) )
      ( body as Record<string, unknown> )[key] = new Date( value as string );
    else if ( typeof value === 'object' ) handleDates( value );
  }
}
