import '../../assets/css/components/controls/PhoneInput.css';
import { testPhone, testPhoneStart } from '../../utils';
import { TextInput, TextInputProps } from './TextInput';

export type PhoneInputProps = Omit<TextInputProps, 'type' | 'placeholder'> & { 
  action?: { label: string, onClick: ( phone: string ) => void } 
}

export const PhoneInput = (
  { name, onChange, value, className, defaultValue, title, action, error, onBlur } : PhoneInputProps
) => {

  const formattedValue = value;

  const middlewareOnChange = ( value: string ) => {
    if ( !value || testPhoneStart( value ) ) onChange( value );
  };

  const onActionClick = action && ( () => {
    if ( testPhone( value ) ) action.onClick( value );
  } );

  return (
    <TextInput
      name={name}
      onChange={middlewareOnChange}
      value={formattedValue}
      className={[ className, 'phone' ].join( ' ' )}
      defaultValue={defaultValue}
      placeholder={'+1234567890'}
      title={title}
      type='tel'
      error={error}
      onBlur={onBlur}
    >
      {action && (
        <div className="action" onClick={onActionClick}>
          {action.label}
        </div>
      )}
    </TextInput>
  );
};