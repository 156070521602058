import React from 'react';
import { IconType } from 'react-icons';
import '../../../assets/css/components/ui/views/EventInfo.css';

export interface EventInfoProps {
  items: {
    icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | IconType;
    element: JSX.Element;
    className?: string;
    onClick?: () => void;
  }[];
  className?: string;
}

export const EventInfo = ( { items, className }: EventInfoProps ) => {
  return (
    <div className={[ 'event-info', className ].join( ' ' )}>
      {items.map( ( item, index ) => (
        <div
          key={index}
          className={[ 'info', item.className ].join( ' ' )}
          onClick={item.onClick}
        >
          {item.icon && <item.icon />}
          {item.element}
        </div>
      ) )}
    </div>
  );
};
