import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Container } from './components/containers';
import { ImageFullScreenView } from './components/ui';
import { RootRouter } from './navigation';
import { servicesInjectStore } from './services';
import { useGetCurrentUserInfoQuery } from './services/currentUserApi';
import { store } from './state';
import { appActions } from './state/slices/appSlice';

servicesInjectStore( store );

export const App = () => {
  const dispatch = useDispatch();
  const { data: currentUser } = useGetCurrentUserInfoQuery();

  useEffect( () => {
    if ( currentUser ) {
      ReactGA.initialize( 'G-G5R8Z2W5M0' );
      gtag( 'set', 'user_properties', {
        user_id: currentUser._id,
        name: currentUser.name
      } );
      gtag( 'config', 'G-G5R8Z2W5M0', {
        user_id: currentUser._id
      } );

      heap.identify( currentUser._id );
      heap.addUserProperties( {
        name: currentUser.name,
        phone: currentUser.phone,
        age: currentUser.age
      } );
      dispatch( appActions.setGAInitialized( true ) );
    }
  }, [ currentUser ] );

  return (
    <Container>
      <RootRouter />
      <Toaster />
      <ImageFullScreenView />
    </Container>
  );
};
