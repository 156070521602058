import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDocTitle } from '../../hooks';
import { useInstagramRedirectHandler } from '../../hooks/instagram';
import { routesPaths } from '../../navigation';
import { ProfileEditFormFields, ServerResponse } from '../../services';
import {
  useGetCurrentUserInfoQuery,
  useUpdateCurrentUserInfoMutation,
  useUpdateCurrentUserTagsMutation
} from '../../services/currentUserApi';
import {
  useDeleteUserMediaMutation,
  useGetUserMediaQuery,
  useUploadUserMediaMutation
} from '../../services/mediaService';
import { useGetTagsQuery } from '../../services/tagsService';
import { useTypedSelector } from '../../state';
import { authActions } from '../../state/slices/authSlice';
import {
  getProfileEditValues,
  profileEditRequiredFieldsSchema,
  reduceResponsePromises
} from '../../utils';
import { Group } from '../controls';
import {
  BirthDateField,
  ImageSelectBlockField,
  Submit,
  TagListField,
  TextAreaField,
  TextField,
  ToggleLabeledField
} from '../forms';
import { FormChangesListener, FormValidationChecker } from '../serviceComponents';
import { ButtonWithConfirmation, ScreenTitle, TopLoadingIndicator } from '../ui';
import { LoadingIndicator } from '../ui/global';

export const ProfileEditScreen = () => {
  useDocTitle( 'Crewww - Edit Profile' );

  const currentUser = useGetCurrentUserInfoQuery();
  const userMedia = useGetUserMediaQuery( currentUser.data?._id || skipToken );
  const { data: defaultFeatures } = useGetTagsQuery( {
    type: 'userFeature',
    defaultTags: true
  } );
  const { data: defaultInterests } = useGetTagsQuery( {
    type: 'userInterest',
    defaultTags: true
  } );

  useInstagramRedirectHandler();

  const [ updateUserInfo, updateUserStatus ] = useUpdateCurrentUserInfoMutation();
  const [ updateUserTags, updateTagsStatus ] = useUpdateCurrentUserTagsMutation();
  const [ uploadMedia, uploadMediaStatus ] = useUploadUserMediaMutation();
  const [ deleteMedia, deleteMediaStatus ] = useDeleteUserMediaMutation();

  const { firstLogin } = useTypedSelector( state => state.auth );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ updateSuccess, setUpdateSuccess ] = useState<boolean | undefined>( undefined );
  const [ isChangesMade, setChangesMade ] = useState( false );
  const [ fullFilled, setFullFilled ] = useState( false );

  const isLoading = currentUser.isLoading || userMedia.isLoading;
  const updateIsLoading =
    updateUserStatus.isLoading ||
    updateTagsStatus.isLoading ||
    uploadMediaStatus.isLoading ||
    deleteMediaStatus.isLoading;

  useEffect( () => {
    if ( updateSuccess !== undefined ) {
      if ( updateSuccess ) toast.success( 'Saved successfully' );
      else {
        const error = updateUserStatus.error as AxiosResponse;
        const responseMessage = ( error.data as ServerResponse ).message;
        toast.error( responseMessage || 'Failed to save' );
      }
      setUpdateSuccess( undefined );
    }
  }, [ updateSuccess ] );

  const initialValues: ProfileEditFormFields = getProfileEditValues(
    currentUser.data,
    userMedia.data
  );

  const visibleFeatures = useMemo( () => {
    if ( defaultFeatures && currentUser.data ) {
      const defaultIds = defaultFeatures.map( tag => tag._id );
      const userTags = currentUser.data.features.filter(
        tag => !defaultIds.includes( tag._id )
      );
      return defaultFeatures.concat( userTags );
    }
    return [];
  }, [ defaultFeatures, currentUser.data ] );
  const visibleInterests = useMemo( () => {
    if ( defaultInterests && currentUser.data ) {
      const defaultIds = defaultInterests.map( tag => tag._id );
      const userTags = currentUser.data.interests.filter(
        tag => !defaultIds.includes( tag._id )
      );
      return defaultInterests.concat( userTags );
    }
    return [];
  }, [ defaultInterests, currentUser.data ] );

  const onSubmit = async ( userInfo: ProfileEditFormFields ) => {
    const { interests, features, images, ...restUserInfo } = userInfo;

    restUserInfo.age = +restUserInfo.age;

    const updatePromises: Promise<ServerResponse>[] = [];
    updatePromises.push( updateUserInfo( restUserInfo ).unwrap() );
    if ( features !== initialValues.features ) {
      if ( features.upload.length )
        features.selected = features.selected.filter( value => !/^__added/.test( value ) );
      updatePromises.push(
        updateUserTags( { tagType: 'features', tags: features } ).unwrap()
      );
    }
    if ( interests !== initialValues.interests ) {
      if ( interests.upload.length )
        interests.selected = interests.selected.filter( value => !/^__added/.test( value ) );
      updatePromises.push(
        updateUserTags( { tagType: 'interests', tags: interests } ).unwrap()
      );
    }
    if ( images.added.length && currentUser.data?._id ) {
      updatePromises.push(
        reduceResponsePromises(
          images.added.map( image =>
            uploadMedia( {
              image,
              uid: currentUser.data?._id as string
            } ).unwrap()
          )
        )
      );
    }
    if ( images.deleted.length ) {
      updatePromises.push(
        reduceResponsePromises( images.deleted.map( media => deleteMedia( media ).unwrap() ) )
      );
    }

    try {
      const response = await reduceResponsePromises( updatePromises );
      setUpdateSuccess( response.success );

      if ( firstLogin ) dispatch( authActions.setFirstLogin( false ) );

      navigate( routesPaths.profile.index );
    } catch {
      setUpdateSuccess( false );
    }
  };

  const goViewProfile = () => navigate( routesPaths.profile.index );

  if ( isLoading ) return <LoadingIndicator />;
  if ( currentUser.isError ) return <>{currentUser.error}</>;

  return (
    <>
      <ScreenTitle
        title='Edit profile'
        confirmationProps={
          isChangesMade
            ? {
                text: 'Are you sure you want to discard your changes?',
                title: 'Discard Changes'
              }
            : undefined
        }
        back={!firstLogin}
        action={{
          label: 'View',
          onClick: goViewProfile
        }}
        backLink={routesPaths.profile.index}
      />
      {updateIsLoading && <TopLoadingIndicator />}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {( { handleSubmit, submitForm } ) => (
          <form onSubmit={handleSubmit}>
            <FormChangesListener onChange={setChangesMade} />
            <FormValidationChecker
              onChange={setFullFilled}
              schema={profileEditRequiredFieldsSchema}
            />
            <Group title='Display name'>
              <TextField
                name='name'
                placeholder='Display name'
              />
            </Group>
            <ImageSelectBlockField
              name='images'
              title='My photos'
            />
            <TextAreaField
              name='about'
              maxCharacters={300}
              title='About me'
              placeholder='e.g. I am a laid back californian looking to make friends in la, I recently moved here from San Diego. I think im a pretty relaxed person, work in tech. I enjoy soaking in the sun, hiking, surfing, drinks and beach vibes.        '
            />
            <Group title='My basic info'>
              {!currentUser.data?.birthDate && (
                <TextField
                  name='age'
                  placeholder='age'
                  type='number'
                  min={18}
                  max={120}
                  disabled
                />
              )}
              <BirthDateField />
              <TextField
                name='location'
                placeholder='location'
              />
              <TextField
                name='education'
                placeholder='education'
              />
              <TextField
                name='job'
                placeholder='job'
              />
            </Group>
            <TagListField
              name='features'
              items={visibleFeatures}
              title='I am ...'
              canAdd
              resetAdded={updateSuccess}
              type='userFeature'
            />
            <TagListField
              name='interests'
              items={visibleInterests}
              title='My interests include ...'
              canAdd
              resetAdded={updateSuccess}
              type='userInterest'
            />
            <Group title='My social preferences'>
              <ToggleLabeledField
                name='alcohol'
                title='Alcohol'
              />
              <ToggleLabeledField
                name='smoking'
                title='Smoking'
              />
            </Group>
            {/* <Group title={instagramProfile ? 'Instagram Connected' :(
              <a href={instagramAuthUrl} style={{ color: 'inherit' }}>
                <u>Connect Instagram</u>
              </a>
            )}>
              {instagramProfile ? (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <RiInstagramLine className='main-color' size={18} style={{ marginRight: 8 }} />
                  {instagramProfile.username}
                </span>
              ) : (
                <span className='muted'>Not connected</span>
              )}
            </Group> */}
            {firstLogin && !fullFilled ? (
              <ButtonWithConfirmation
                buttonText='Save'
                confirm={{ label: 'Back to edit' }}
                cancel={{ label: 'Skip for now', onClick: submitForm }}
                modalTitle='Proceed with incomplete profile?'
                modalText='You will have a higher chance of matching with a more complete profile.'
              />
            ) : (
              <Submit text='Save' />
            )}
          </form>
        )}
      </Formik>
    </>
  );
};
