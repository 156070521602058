import { DateTime, Duration } from 'luxon';
import { useEffect, useState } from 'react';

export interface CountDownProps {
  target: DateTime;
  reachedLabel?: string;
  onReached?: () => void;
}

export const CountDown = ( {
  target,
  reachedLabel = 'now',
  onReached
}: CountDownProps ) => {
  const [ targetDateTime ] = useState( target );
  const [ reached, setReached ] = useState( false );

  const [ diff, setDiff ] = useState<Duration>(
    targetDateTime.diff( DateTime.now(), [ 'months', 'days', 'hours', 'minutes', 'seconds' ] )
  );

  useEffect( () => {
    if ( diff.toMillis() <= 0 ) {
      setReached( true );
      if ( onReached ) onReached();
    }
    if ( !reached ) {
      const timeout = setTimeout( () => {
        setDiff( diff.minus( { seconds: 1 } ) );
      }, 1000 );
      return () => {
        clearTimeout( timeout );
      };
    }
  }, [ diff ] );

  return (
    <>
      {reached
        ? diff.hours || diff.days
          ? 'Past event'
          : reachedLabel
        : diff.toFormat( 'hh:mm:ss' )}
    </>
  );
};
