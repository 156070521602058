import { GetUsersResponse, User, UserMediaExpanded } from '.';
import { mainApi } from './mainApi';

export const usersApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getUser: build.query<UserMediaExpanded, string>( {
      query: uid => ( { url: `/users/${uid}`, method: 'GET' } )
    } ),
    getUsersByTags: build.query<
      {
        users: UserMediaExpanded[];
        total: number;
      },
      {
        features: string[];
        interests: string[];
        page?: number;
        gender?: 'male' | 'female';
      }
    >( {
      query: params => ( {
        url: '/users/byTags',
        method: 'post',
        data: params
      } )
    } ),
    getUsers: build.query<
      GetUsersResponse,
      { name?: string; phone?: string; gender?: User['gender'] }
    >( {
      query: params => ( {
        url: '/users',
        params
      } )
    } )
  } )
} );

export const {
  useGetUserQuery,
  useGetUsersByTagsQuery,
  useLazyGetUsersByTagsQuery,
  useLazyGetUsersQuery
} = usersApi;
