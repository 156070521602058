import styles from './EventMember.module.scss';

export interface EventMemberPhoneInviteProps {
  phone: string;
}

export const EventMemberPhoneInvite = ( { phone }: EventMemberPhoneInviteProps ) => {
  return (
    <>
      <div className={styles['event-member']}>
        <div className={styles['info']}>
          <div className={styles['avatar-phone']}>{phone.slice( 0, 4 )}</div>
          <div className={styles['name']}>{phone}</div>
          <span>Pending</span>
        </div>
      </div>
    </>
  );
};
