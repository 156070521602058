import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { Tag } from '../../services';
import { TagList, TagListProps, TagWithoutType } from '../controls';

export type TagListFieldValues = {
  selected: string[];
  upload: string[];
  deleted: string[];
};

export type TagListFieldProps = Omit<
  TagListProps,
  'onSelect' | 'selected' | 'onAdd' | 'editable' | 'onDelete'
> & { name: string; resetAdded?: boolean; type: Tag['type'] };

export const TagListField = ( props: TagListFieldProps ) => {
  const { values, setFieldValue } = useFormikContext();
  const [ added, setAdded ] = useState<Tag[]>( [] );
  const [ deleted, setDeleted ] = useState<Tag[]>( [] );

  const fieldValue = ( values as Record<string, TagListFieldValues> )[props.name];

  const onSelect = ( tag: TagWithoutType ) => {
    if ( fieldValue.selected.includes( tag._id ) )
      setFieldValue( props.name, {
        selected: fieldValue.selected.filter( id => id !== tag._id ),
        upload: fieldValue.upload,
        deleted: fieldValue.deleted,
      } as TagListFieldValues );
    else
      setFieldValue( props.name, {
        selected: fieldValue.selected.concat( tag._id ),
        upload: fieldValue.upload,
        deleted: fieldValue.deleted,
      } as TagListFieldValues );
  };

  const onAdd = ( value: string ) => {
    const newItemValue = `__added${added.length}`;
    setFieldValue( props.name, {
      selected: fieldValue.selected.concat( newItemValue ),
      upload: fieldValue.upload.concat( value ),
      deleted: fieldValue.deleted,
    } as TagListFieldValues );
    setAdded( added.concat( [ { _id: newItemValue, name: value, type: props.type } ] ) );
  };

  const onDelete = ( tag: Tag ) => {
    const isDeletingAdded = !!added.find( addedTag => addedTag._id === tag._id );
    setFieldValue( props.name, {
      selected: fieldValue.selected.filter( tagId => tagId !== tag._id ),
      upload: isDeletingAdded
        ? fieldValue.upload.filter( tagName => tagName !== tag.name )
        : fieldValue.upload,
      deleted: isDeletingAdded ? fieldValue.deleted : fieldValue.deleted.concat( tag._id ),
    } as TagListFieldValues );
    if ( isDeletingAdded ) setAdded( added.filter( addedTag => addedTag._id !== tag._id ) );
    else setDeleted( deleted.concat( tag ) );
  };

  useEffect( () => {
    if ( props.resetAdded ) {
      setAdded( [] );
    }
  }, [ props.resetAdded ] );

  const visibleItems = useMemo( () => {
    return props.items
      .filter( tag => !deleted.find( deletedTag => deletedTag._id === tag._id ) )
      .concat( added );
  }, [ props.items, added, deleted ] );

  return (
    <TagList
      {...props}
      items={visibleItems}
      editable
      onSelect={onSelect}
      selected={fieldValue.selected}
      onAdd={onAdd}
      onDelete={onDelete}
    />
  );
};
