import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import { CurrentUser } from '../../services';
import { useGetCurrentUserInfoQuery } from '../../services/currentUserApi';
import { useGetInstagramProfileQuery } from '../../services/instagram';

export const useGetInstagramProfile = (
  instagramUserId?: string | number | typeof skipToken,
  accessToken?: string
) => {
  const currentUser = useGetCurrentUserInfoQuery();

  const isAuthorizedInstagram = useMemo( () => {
    if ( accessToken ) return true;
    if ( !currentUser.data ) return false;
    return !!currentUser.data.instagram?.accessToken;
  }, [ currentUser.data ] );

  const instagramProfile = useGetInstagramProfileQuery(
    instagramUserId !== skipToken && isAuthorizedInstagram
      ? {
          accessToken:
            accessToken ||
            ( currentUser.data as Required<CurrentUser> ).instagram.accessToken,
          fields: [ 'media', 'username' ],
          id: instagramUserId,
        }
      : skipToken
  );

  return instagramProfile.data;
};
