import { useState } from 'react';
import '../../assets/css/components/controls/DropDown.css';

export interface DropDownProps {
  name: string;
  value?: string;
  onChange: ( value: string ) => void;
  placeholder: string;
  options: { value: string; label: string }[];
  title?: string;
  error?: string;
  onBlur?: ( e: unknown ) => void;
}

export const DropDown = ( {
  name,
  value = '',
  onChange,
  placeholder,
  options,
  title,
  error,
  onBlur
}: DropDownProps ) => {
  const [ isFocused, setFocused ] = useState( false );

  const focus = () => setFocused( true );
  const unFocus = ( e?: unknown ) => {
    setFocused( false );
    onBlur && e && onBlur( e );
  };

  const onSelectClick = () => {
    if ( isFocused ) unFocus();
    else focus();
  };

  const inputOnChange = ( event: React.ChangeEvent<HTMLSelectElement> ) =>
    onChange( event.target.value );

  return (
    <div
      className={[
        'dropdown',
        error ? 'error' : undefined,
        isFocused ? 'focused' : undefined
      ].join( ' ' )}
    >
      {title && <label htmlFor={name}>{title}</label>}
      <select
        name={name}
        id={name}
        value={value || ''}
        onChange={inputOnChange}
        className={!value ? 'not-selected' : undefined}
        onClick={onSelectClick}
        onBlur={unFocus}
      >
        <option
          value=''
          label={placeholder}
          disabled
          hidden
          selected
        />
        {options.map( ( { value, label } ) => (
          <option
            key={value}
            value={value}
          >
            {label}
          </option>
        ) )}
      </select>
      {error && <div className='error'>{error}</div>}
    </div>
  );
};
