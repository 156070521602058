import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useDocTitle } from '../../../hooks';
import {
  useGetCurrentUserInfoQuery,
  useUpdateNotificationsSettingsMutation
} from '../../../services/currentUserApi';
import { Group } from '../../controls';
import { Submit, ToggleLabeledField } from '../../forms';
import { LoadingIndicator, ScreenTitle } from '../../ui';
import styles from './NotificationsSettingsScreen.module.sass';
import { NotificationsFields, notificationsSchema } from './schemas';

export const NotificationsSettingsScreen = () => {
  useDocTitle( 'Crewww - Notifications Settings' );

  const navigate = useNavigate();

  const { data: currentUser } = useGetCurrentUserInfoQuery();
  const [ updateNotificationsSettings ] = useUpdateNotificationsSettingsMutation();

  if ( !currentUser ) return <LoadingIndicator />;

  const onSubmit = ( values: NotificationsFields ) => {
    updateNotificationsSettings( {
      invites: values.invites.enabled
    } )
      .unwrap()
      .then( () => {
        toast.success( 'Saved' );
        navigate( -1 );
      } )
      .catch( () => toast.error( 'Could not save preferences' ) );
  };

  return (
    <>
      <div className={styles['container']}>
        <ScreenTitle title='Notifications' />
        <Formik
          initialValues={
            {
              invites: {
                enabled: currentUser.notificationsPreferences?.invites === true
              }
            } as NotificationsFields
          }
          onSubmit={onSubmit}
          validationSchema={notificationsSchema}
        >
          <Form className={styles['form']}>
            <div>
              <Group title='My notification preferences'>
                <ToggleLabeledField
                  name='invites'
                  title='Event Invites'
                />
              </Group>
            </div>
            <Submit text='Save' />
          </Form>
        </Formik>
      </div>
    </>
  );
};
