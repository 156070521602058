import { useCallback, useEffect, useState } from 'react';

export const useBottomReachHandler = ( callback: () => void ) => {
  const [ isReached, setReached ] = useState<boolean>();

  const onScroll = useCallback( () => {
    const isBottom =
      document.body.scrollHeight - window.innerHeight <= window.scrollY + 32;
    if ( isBottom ) {
      if ( !isReached ) {
        callback();
        setReached( true );
      }
    } else {
      if ( isReached ) {
        setReached( false );
      }
    }
  }, [ isReached, callback ] );

  useEffect( () => {
    window.addEventListener( 'scroll', onScroll );
    return () => {
      window.removeEventListener( 'scroll', onScroll );
    };
  }, [ onScroll ] );

  return null;
};
