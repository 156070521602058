import { ReactComponent as Close } from '../../assets/img/icons/close.svg';
import { Media } from '../../services';

export interface ImageSelectPreviewProps {
  media: Media,
  onDelete: ( media: Media ) => void,
}

export const ImageSelectPreview = (
  { media, onDelete } : ImageSelectPreviewProps
) => (
  <div className="image-select">
    <label 
      style={{ backgroundImage: `url(${media.url})` }}
      className='preview'
    />
    <Close className='close' onClick={onDelete.bind( null, media )}/>

  </div>
);