import { DropDown, DropDownProps } from '..';
import '../../../assets/css/components/controls/ListControls/ListSort.css';

export type ListControlsSortProps = Pick<
  DropDownProps,
  'placeholder' | 'title' | 'onChange' | 'options' | 'value'
>;

export const ListControlsSort = ( {
  options,
  onChange,
  placeholder,
  title,
  value,
}: ListControlsSortProps ) => {
  return (
    <div className='list-sort'>
      <DropDown
        options={options}
        placeholder={placeholder}
        title={title}
        name=''
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
