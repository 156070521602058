import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FacebookAuthData, FacebookUserData } from '../../../services';
import { localStoragePath } from '../types';
import { FacebookSliceState } from './types';

const localStorageFacebookState = localStorage.getItem( localStoragePath.facebookState );

const initialState : FacebookSliceState = (
  localStorageFacebookState ? JSON.parse( localStorageFacebookState ) : {
    loggedIn: false,
    authData: null,
    userData: null,
    registrationSuccess: undefined,
    registrationError: undefined
  }
);

export const facebookSlice = createSlice( {
  name: 'facebookState',
  initialState,
  reducers: {
    setLoggedIn: ( state, action: PayloadAction<boolean> ) => {
      state.loggedIn = action.payload;
      localStorage.setItem( localStoragePath.facebookState, JSON.stringify( state ) );
    },
    setAuthData: ( state, action: PayloadAction<FacebookAuthData | null> ) => {
      state.loggedIn = !!action.payload;
      state.authData = action.payload;
      localStorage.setItem( localStoragePath.facebookState, JSON.stringify( state ) );
    },
    setUserData: ( state, action: PayloadAction<FacebookUserData | null> ) => {
      state.userData = action.payload;
      localStorage.setItem( localStoragePath.facebookState, JSON.stringify( state ) );
    },
    setRegistrationSuccess: ( state, action: PayloadAction<boolean | undefined> ) => {
      state.registrationSuccess = action.payload;
      localStorage.setItem( localStoragePath.facebookState, JSON.stringify( state ) );
    },
    setRegistrationError: ( state, action: PayloadAction<string | undefined> ) => {
      state.registrationError = action.payload;
      localStorage.setItem( localStoragePath.facebookState, JSON.stringify( state ) );
    },
  }
} );

export const facebookActions = facebookSlice.actions;