import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useDocTitle } from '../../hooks';
import { ChangePasswordFormFields } from '../../services';
import {
  useChangePasswordMutation,
  useGetCurrentUserInfoQuery
} from '../../services/currentUserApi';
import {
  changePasswordValidationSchema,
  getChangePasswordInitialValues
} from '../../utils';
import { Group } from '../controls';
import { PasswordField, Submit } from '../forms';
import { ScreenTitle } from '../ui';

export const AccountSettingsScreen = () => {
  useDocTitle( 'Crewww - Account Settings' );

  const currentUser = useGetCurrentUserInfoQuery();

  const navigate = useNavigate();

  const [ changePassword ] = useChangePasswordMutation();

  const onSubmit = ( values: ChangePasswordFormFields ) => {
    changePassword( {
      newPassword: values.newPassword,
      oldPassword: values.oldPassword
    } )
      .unwrap()
      .then( () => {
        toast.success( 'Password updated successfully' );
        navigate( -1 );
      } )
      .catch( () => {
        toast.error( 'Failed to change password' );
      } );
  };

  const settings = useMemo( () => {
    const availableSettings: JSX.Element[] = [];
    if ( !currentUser.data?.registeredWithSocials ) {
      availableSettings.push(
        <Group
          title='Change Password'
          key='pwd'
        >
          <PasswordField
            name='oldPassword'
            placeholder='Old Password'
          />
          <PasswordField
            name='newPassword'
            placeholder='New Password'
          />
          <PasswordField
            name='confirmNewPassword'
            placeholder='Confirm New Password'
          />
        </Group>
      );
    }
    return availableSettings;
  }, [ currentUser.data ] );

  return (
    <>
      <ScreenTitle title='Account Settings' />
      <Formik
        initialValues={getChangePasswordInitialValues()}
        onSubmit={onSubmit}
        validationSchema={changePasswordValidationSchema}
        validateOnMount={true}
      >
        <Form>
          {settings}
          {settings.length > 0 && (
            <Submit
              text='Save'
              className='bottom'
            />
          )}
        </Form>
      </Formik>
    </>
  );
};
