import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AppRouter, AuthRouter, FirstLoginRouter } from '.';
import { LoadingIndicator } from '../components/ui/global';
import { useGetCurrentUserInfoQuery } from '../services/currentUserApi';
import { useTypedSelector } from '../state';

export const RootRouter = () => {
  const { isAuthorized, firstLogin } = useTypedSelector( state => state.auth );

  const { isLoading } = useGetCurrentUserInfoQuery( isAuthorized ? undefined : skipToken );

  if ( isAuthorized ) {
    if ( firstLogin ) return <FirstLoginRouter />;
    return <AppRouter />;
  } else if ( !isLoading ) {
    return <AuthRouter />;
  } else {
    return <LoadingIndicator />;
  }
};
