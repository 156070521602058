
export interface InstagramProfileResponse {
  media?: {
    data: {
      id: string
    }[],
    paging: {
      cursor: {
        before: string,
        after: string
      },
      next?: string
    }
  },
  username: string,
  id: string
}

export interface InstagramMedia {
  media_url: string,
  media_type: 'IMAGE' | 'VIDEO' | 'CAROUSEL_ALBUM',
  id: string
}

export type InstagramProfileRequestFields = 'media' | 'username';
export type InstagramMediaRequestFields = 'media_type' | 'media_url';

export interface InstagramRequestParams {
  access_token: string,
  fields: string
}

export interface InstagramRequestParamsInternal<Fields extends string> {
  accessToken: string,
  fields: Fields[],
  id?: string | number
}

export const instagramRedirectUri = window.location.origin + '/profile/edit';

export const instagramAuthUrl = ( () => {
  const url = new URL( 'https://api.instagram.com/oauth/authorize' );
  url.searchParams.append( 'client_id', '517031746846225' );
  url.searchParams.append( 'redirect_uri', instagramRedirectUri );
  url.searchParams.append( 'scope', [ 'user_profile', 'user_media' ].join( ',' ) );
  url.searchParams.append( 'response_type', 'code' );
  return url.toString();
} )();


export function isInstagramMedia ( object: unknown ) : object is InstagramMedia {
  return ( object as InstagramMedia ).media_type !== undefined;
}