import { Navigate, Route } from 'react-router-dom';
import {
  AccountSettingsScreen,
  EventPreviewScreen,
  EventScreen,
  EventsListScreen,
  FacebookRegisterScreen,
  GoogleRegisterScreen,
  InviteScreen,
  InvitesScreen,
  NotificationsSettingsScreen,
  RestorePasswordScreen,
  SettingsScreen,
  TermsAndAgreements
} from '../components/screens';
import { EventCreationScreen } from '../components/screens/EventCreationScreen/EventCreationScreen';
import { LoginScreen } from '../components/screens/LoginScreen/LoginScreen';
import { MyEventsScreen } from '../components/screens/MyEventsScreen';
import { OnboardScreen } from '../components/screens/OnboardScreen';
import { ProfileEditScreen } from '../components/screens/ProfileEditScreen';
import { ProfileScreen } from '../components/screens/ProfileScreen';
import { RegisterScreen } from '../components/screens/RegistrationScreen';
import { EventPreviewRedirect, LogoutRoute } from '../components/serviceComponents';
import { RootRouterConfiguration, RouteInfo, RoutesConfiguration } from './types';

export const routesPaths = {
  landing: '/',
  onboarding: '/onboarding',
  register: '/register',
  login: '/login',
  profile: {
    index: '/profile',
    edit: 'edit',
    user: ':userId'
  },
  events: {
    index: '/events',
    my: 'my'
  },
  event: {
    index: '/event',
    creation: 'create',
    single: {
      index: ':eventSignature',
      main: '',
      invite: 'invite',
      edit: 'edit'
    }
  },
  settings: {
    index: '/settings',
    account: 'account',
    notifications: 'notifications'
  },
  logout: '/logout',
  terms: 'https://app.websitepolicies.com/policies/view/ttet82on',
  restorePassword: '/restore-password',
  socials: {
    index: '/socials',
    facebook: {
      index: 'facebook',
      register: 'register'
    },
    google: {
      index: 'google',
      register: 'register'
    }
  },
  eventPreview: '/preview/:token/*',
  invites: '/invites'
};

export const authRoutes: RoutesConfiguration = {
  onboarding: { path: routesPaths.onboarding, element: <OnboardScreen /> },
  register: { path: routesPaths.register, element: <RegisterScreen /> },
  login: { path: routesPaths.login, element: <LoginScreen /> },
  onboardingRedirect: { path: '/*', element: <Navigate to={'/'} /> },
  terms: { path: routesPaths.terms, element: <TermsAndAgreements /> },
  restorePassword: {
    path: routesPaths.restorePassword,
    element: <RestorePasswordScreen />
  },
  landing: { path: routesPaths.landing, element: <EventsListScreen /> },
  socials: {
    path: routesPaths.socials.index,
    element: [
      {
        path: routesPaths.socials.facebook.index,
        element: [
          {
            path: routesPaths.socials.facebook.register,
            element: <FacebookRegisterScreen />
          }
        ]
      },
      {
        path: routesPaths.socials.google.index,
        element: [
          {
            path: routesPaths.socials.google.register,
            element: <GoogleRegisterScreen />
          }
        ]
      }
    ]
  },
  eventPreview: { path: routesPaths.eventPreview, element: <EventPreviewScreen /> }
};

export const appRoutes: RoutesConfiguration = {
  profileRedirect: { path: '/*', element: <Navigate to={routesPaths.events.index} /> },
  profile: {
    path: routesPaths.profile.index,
    element: [
      { path: '', element: <ProfileScreen /> },
      { path: routesPaths.profile.edit, element: <ProfileEditScreen /> },
      { path: routesPaths.profile.user, element: <ProfileScreen /> }
    ]
  },
  events: {
    path: routesPaths.events.index,
    element: [
      { path: '', element: <EventsListScreen /> },
      { path: routesPaths.events.my, element: <MyEventsScreen /> }
    ]
  },
  event: {
    path: routesPaths.event.index,
    element: [
      { path: '', element: <Navigate to={'/'} /> },
      {
        path: routesPaths.event.single.index,
        element: [
          { path: routesPaths.event.single.main, element: <EventScreen /> },
          { path: routesPaths.event.single.invite, element: <InviteScreen /> },
          { path: routesPaths.event.single.edit, element: <EventCreationScreen edit /> }
        ]
      },
      { path: routesPaths.event.creation, element: <EventCreationScreen /> }
    ]
  },
  settings: {
    path: routesPaths.settings.index,
    element: [
      { path: '', element: <SettingsScreen /> },
      { path: routesPaths.settings.account, element: <AccountSettingsScreen /> },
      {
        path: routesPaths.settings.notifications,
        element: <NotificationsSettingsScreen />
      }
    ]
  },
  logout: {
    path: routesPaths.logout,
    element: <LogoutRoute />
  },
  terms: { path: routesPaths.terms, element: <TermsAndAgreements /> },
  previewRedirect: { path: routesPaths.eventPreview, element: <EventPreviewRedirect /> },
  invites: { path: routesPaths.invites, element: <InvitesScreen /> }
};

export const routes: RootRouterConfiguration = {
  auth: authRoutes,
  app: appRoutes
};

export const renderRoutes = ( routesConfiguration: RoutesConfiguration ) =>
  Object.values( routesConfiguration ).map( routeInfo => renderRouteInfo( routeInfo ) );

export const renderRouteInfo = (
  routeInfo: RouteInfo | RouteInfo[]
): JSX.Element | JSX.Element[] => {
  if ( Array.isArray( routeInfo ) ) {
    return routeInfo.map( info => renderRouteInfo( info ) ) as JSX.Element[];
  } else {
    if ( Array.isArray( routeInfo.element ) ) {
      return (
        <Route
          key={routeInfo.path}
          path={routeInfo.path}
        >
          {renderRouteInfo( routeInfo.element )}
        </Route>
      );
    } else {
      return (
        <Route
          key={routeInfo.path}
          path={routeInfo.path}
          element={routeInfo.element}
        />
      );
    }
  }
};

export const isPathWithoutBottomNavigation = ( path: string ) => {
  for ( const regex of pathsWithoutBottomNavigation ) {
    if ( regex.test( path ) ) return true;
  }
  return false;
};

export const pathsWithoutBottomNavigation = [
  new RegExp( `^${routesPaths.profile.index}/${routesPaths.profile.edit}` ),
  new RegExp( `^${routesPaths.event.index}/.+` ),
  new RegExp( `^${routesPaths.settings.index}/${routesPaths.settings.account}` ),
  new RegExp( `^${routesPaths.settings.index}/${routesPaths.settings.notifications}` )
];
