import { useFormikContext } from 'formik';
import { Media } from '../../services';
import { ImageSelectBlock, ImageSelectBlockChangeValues, ImageSelectBlockProps } from '../controls';

export type ImageSelectBlockFieldValue = ImageSelectBlockChangeValues & {default: Media[]};

export type ImageSelectBlockFieldProps = 
  Omit<ImageSelectBlockProps, 'defaultValues' | 'onChange'> & { name: string };

export const ImageSelectBlockField = (
  props : ImageSelectBlockFieldProps
) => {

  const { initialValues, setFieldValue } = useFormikContext();

  const onChange = ( values: ImageSelectBlockChangeValues ) => {
    setFieldValue( props.name, values );
  };

  return (
    <ImageSelectBlock 
      {...props}
      defaultValues={( initialValues as Record<string, ImageSelectBlockFieldValue> )[props.name].default}
      onChange={onChange}
    />
  );
};