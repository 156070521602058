import '../../assets/css/components/controls/CheckBox.css';


export interface CheckBoxProps {
  checked: boolean,
  onChange: ( checked: boolean ) => void,
  name: string
}

export const CheckBox = ( { checked, onChange, name } : CheckBoxProps ) => {

  const onInputChange = ( event: React.ChangeEvent<HTMLInputElement> ) => onChange( event.target.checked );
  
  return (
    <div className="checkbox">
      <input type="checkbox" name={name} id={name} checked={checked} onChange={onInputChange} />
      <label htmlFor={name} />
    </div>
  );
};