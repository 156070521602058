import { useFormikContext } from 'formik';
import { CheckBoxLabeled, CheckBoxLabeledProps } from '../controls';

export type CheckBoxFieldProps = Omit<CheckBoxLabeledProps, 'checked' | 'onChange'>;

export const CheckBoxField = ( props : CheckBoxFieldProps ) => {

  const { values, setFieldValue } = useFormikContext();

  const onChange = ( checked: boolean ) => setFieldValue( props.name, checked );

  return (
    <CheckBoxLabeled 
      {...props}
      checked={( values as Record<string, boolean> )[props.name]}
      onChange={onChange}
    />
  );
};