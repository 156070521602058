import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { ReactComponent as CakeIcon } from '../../../../assets/img/icons/cake.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/img/icons/clock.svg';
import { ReactComponent as FemaleIcon } from '../../../../assets/img/icons/female.svg';
import { ReactComponent as MaleIcon } from '../../../../assets/img/icons/male.svg';
import { ReactComponent as SandGlassIcon } from '../../../../assets/img/icons/sandglass.svg';
import { ReactComponent as UserIcon } from '../../../../assets/img/icons/userStroke.svg';
import { Event } from '../../../../services';
import { getEventStartString } from '../../../../utils';
import { CountDown } from '../CountDown';
import styles from './EventCardInfo.module.scss';

export interface EventCardInfoProps {
  event: Event;
  className?: string;
}

export const EventCardInfo = ( { event, className }: EventCardInfoProps ) => {
  const isPast = useMemo( () => event.start < new Date(), [ event ] );
  const isByoc = !!event.byoc;

  const getEventStartDate = useCallback( () => {
    return typeof event.start === 'string'
      ? DateTime.fromISO( event.start )
      : DateTime.fromJSDate( event.start );
  }, [ event.start ] );

  const genderIcon = useMemo( () => {
    if ( event.author.gender === 'other' ) return;
    return event.author.gender === 'female' ? <FemaleIcon /> : <MaleIcon />;
  }, [ event ] );

  const cardClassName = useMemo( () => {
    return [ styles['info'], className, isPast ? styles['past'] : undefined ].join( ' ' );
  }, [ className, isPast ] );

  const byocGenderIcon = ( gender: 'male' | 'female', filled: boolean ) => {
    if ( gender === 'male' )
      return <MaleIcon className={filled ? undefined : styles['muted']} />;
    if ( gender === 'female' )
      return <FemaleIcon className={filled ? undefined : styles['muted']} />;
  };

  return (
    <>
      <div className={cardClassName}>
        {!isPast && (
          <div className={styles['info-item']}>
            <ClockIcon /> {getEventStartString( event )}
          </div>
        )}
        <div className={[ styles['info-item'], styles['count-down'] ].join( ' ' )}>
          {isPast ? <ClockIcon /> : <SandGlassIcon />}
          {isPast ? 'Past Event' : <CountDown target={getEventStartDate()} />}
        </div>
        <div className={styles['info-item']}>
          <UserIcon />{' '}
          {isByoc ? event.maxMembers : `${event.members.length}/${event.maxMembers}`}
        </div>
        {!!event.byoc && (
          <div className={[ styles['info-item'], styles['byoc-gender'] ].join( ' ' )}>
            {byocGenderIcon( event.byoc.authorCrewGender, true )}
            {byocGenderIcon( event.byoc.targetCrewGender, !!event.byoc.joinedCrew )}
          </div>
        )}
      </div>

      {event.filters && (
        <div className={cardClassName}>
          {event.filters?.gender && (
            <div className={styles['info-item']}>{genderIcon} Only</div>
          )}
          {event.filters?.age && (
            <div className={styles['info-item']}>
              <CakeIcon /> {event.filters.age.min}-{event.filters.age.max}
            </div>
          )}
        </div>
      )}
    </>
  );
};
