import styles from './RadioSelect.module.sass';

export interface RadioSelectProps {
  options: { label: string; value: string }[];
  value: string | undefined;
  onChange: ( value: string ) => void;
}

export const RadioSelect = ( { value, onChange, options }: RadioSelectProps ) => {
  const onOptionClick = ( optionValue: string ) => () => {
    onChange( optionValue );
  };

  return (
    <div className={styles['radio-select']}>
      {options.map( ( option, index ) => (
        <div
          className={styles['option']}
          key={index}
          onClick={onOptionClick( option.value )}
          data-active={option.value === value}
        >
          <span className={styles['radio-view']}></span>
          {option.label}
        </div>
      ) )}
    </div>
  );
};
