import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as FemaleIcon } from '../../../../assets/img/icons/female.svg';
import { ReactComponent as InvitedIcon } from '../../../../assets/img/icons/invited.svg';
import { ReactComponent as MaleIcon } from '../../../../assets/img/icons/male.svg';
import { ReactComponent as UserIcon } from '../../../../assets/img/icons/userStroke.svg';
import { routesPaths } from '../../../../navigation';
import { UserMediaExpanded } from '../../../../services';
import { useInviteToEventMutation } from '../../../../services/invitesApi';
import { getUserAboutExcerpt } from '../../../../utils';
import { Button } from '../../../controls';
import styles from './InviteeCard.module.sass';

export interface InviteeCardProps {
  user: UserMediaExpanded;
  invited?: boolean;
  eventId: string;
  onInvite?: ( user: UserMediaExpanded ) => void;
}

export const InviteeCard = ( { user, invited, eventId, onInvite }: InviteeCardProps ) => {
  const navigate = useNavigate();

  const [ inviteUser ] = useInviteToEventMutation();

  const [ showAllFeatures, setShowAllFeatures ] = useState( false );
  const [ featuresWidth, setFeaturesWidth ] = useState<number>();
  const [ isImgError, setImgError ] = useState( false );

  const text = useMemo( () => {
    const about = user?.about;
    if ( !about ) return undefined;
    if ( about.length > 70 ) return getUserAboutExcerpt( about );
    return about;
  }, [ user ] );

  const visibleFeatures = useMemo( () => {
    if ( showAllFeatures ) return user?.features.map( feature => feature.name ) || [];
    if ( !featuresWidth || !user ) return [];
    const fakeFeatures = document.createElement( 'div' );
    fakeFeatures.className = styles['features'];
    fakeFeatures.style.position = 'fixed';
    fakeFeatures.style.top = '-200px';
    const expand = document.createElement( 'div' );
    expand.className = styles['feature'];
    expand.textContent = '...';
    fakeFeatures.append( expand );
    document.body.append( fakeFeatures );
    const result: string[] = [];
    for ( const feature of user.features ) {
      const featureElement = document.createElement( 'div' );
      featureElement.className = styles['feature'];
      featureElement.textContent = feature.name;
      fakeFeatures.prepend( featureElement );
      if ( fakeFeatures.offsetWidth < featuresWidth ) result.push( feature.name );
      else break;
    }
    fakeFeatures.remove();
    return result;
  }, [ featuresWidth, user, showAllFeatures ] );

  const featuresRef = useCallback( ( element: HTMLDivElement | null ) => {
    if ( element ) setFeaturesWidth( element.offsetWidth );
  }, [] );

  const toggleShowAllFeatures = ( value: boolean ) => () => setShowAllFeatures( value );
  const toggleImgError = ( value: boolean ) => () => setImgError( value );

  const onInviteClick: MouseEventHandler = event => {
    inviteUser( { invitee: user._id, event: eventId } );
    onInvite && onInvite( user );
    event.preventDefault();
    event.stopPropagation();
  };

  const goProfile = () => {
    navigate( [ routesPaths.profile.index, user._id ].join( '/' ) );
  };

  return (
    <div className={styles['invitee-card']}>
      <div
        className={styles['header']}
        onClick={goProfile}
      >
        <div className={styles['left-side']}>
          <div className={styles['avatar']}>
            {user.media.length && !isImgError ? (
              <img
                src={user.media[0].url}
                alt={user.name}
                onError={toggleImgError( true )}
              />
            ) : (
              <div className={styles['no-image']}>{user.name[0]}</div>
            )}
          </div>
          <div className={styles['info']}>
            <div className={styles['name']}>
              {user.name}
              <span className={styles['gender']}>
                {user.gender === 'female' ? (
                  <FemaleIcon />
                ) : user.gender === 'male' ? (
                  <MaleIcon />
                ) : null}
              </span>
            </div>
            <div className={styles['age']}>
              <UserIcon />
              {user.age}
            </div>
          </div>
        </div>
        <Button
          text={
            invited ? (
              <>
                <InvitedIcon />
                Invited
              </>
            ) : (
              'Invite'
            )
          }
          className={[ styles['invite-button'], invited && styles['invited'] ].join( ' ' )}
          onClick={invited ? undefined : onInviteClick}
          style={invited ? 'secondary' : undefined}
        />
      </div>
      <div className={styles['content']}>
        <div
          className={styles['features']}
          ref={featuresRef}
        >
          {visibleFeatures.map( ( featureName, index ) => (
            <div
              className={styles['feature']}
              key={index}
            >
              {featureName}
            </div>
          ) )}
          {!showAllFeatures && visibleFeatures.length !== user.features.length && (
            <div
              className={[ styles['feature'], styles['expand'] ].join( ' ' )}
              onClick={toggleShowAllFeatures( true )}
            >
              ...
            </div>
          )}
        </div>
        <p className={styles['about']}>{text}</p>
      </div>
    </div>
  );
};
