import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { AnySchema } from 'yup';

export interface FormValidationCheckerProps {
  onChange: ( valid: boolean ) => void,
  schema: AnySchema,
  debounceDuration?: number
}

export const FormValidationChecker = (
  { schema, onChange, debounceDuration } : FormValidationCheckerProps
) => {

  const { values } = useFormikContext();

  useEffect( () => {

    const timeout = setTimeout( async () => {
      onChange( await schema.isValid( values ) );
    }, debounceDuration || 300 );

    return () => clearTimeout( timeout );
  }, [ values, onChange ] );

  return null;
};