import { Tag } from '../services';

export function getRandomIntFromInterval( min: number, max: number ) {
  return Math.floor( Math.random() * ( max - min + 1 ) + min );
}

export function getRandomEmoji() {
  return String.fromCodePoint(
    parseInt( '0x' + getRandomIntFromInterval( 0x1f331, 0x1f3f0 ).toString( 16 ) )
  );
}

type TagsSplitted = [Tag[], Tag[], Tag[], Tag[]];

export function splitTags( tags: Tag[] | undefined ): TagsSplitted {
  if ( !tags ) return [ [], [], [], [] ];
  if ( tags.length < 8 ) return [ tags, [], [], [] ];
  const defaultTags = tags.filter( tag => !!tag.default );
  const customTags = tags.filter( tag => !tag.default );
  const sortedDefault = defaultTags.sort( ( a, b ) =>
    a.name.length > b.name.length ? -1 : 1
  );
  const sortedCustom = customTags.sort( ( a, b ) =>
    a.name.length > b.name.length ? -1 : 1
  );
  const defaultSplit: TagsSplitted = [ [], [], [], [] ];
  const customSplit: TagsSplitted = [ [], [], [], [] ];
  for ( let i = 0; i < sortedDefault.length; i += 4 ) {
    defaultSplit[0].push( sortedDefault[i] );
    if ( sortedDefault[i + 1] ) defaultSplit[1].push( sortedDefault[i + 1] );
    if ( sortedDefault[i + 2] ) defaultSplit[2].push( sortedDefault[i + 2] );
    if ( sortedDefault[i + 3] ) defaultSplit[3].push( sortedDefault[i + 3] );
  }
  for ( let i = 0; i < sortedCustom.length; i += 4 ) {
    customSplit[0].push( sortedCustom[i] );
    if ( sortedCustom[i + 1] ) customSplit[1].push( sortedCustom[i + 1] );
    if ( sortedCustom[i + 2] ) customSplit[2].push( sortedCustom[i + 2] );
    if ( sortedCustom[i + 3] ) customSplit[3].push( sortedCustom[i + 3] );
  }

  return [
    [
      ...defaultSplit[0].sort( () => Math.random() - 0.5 ),
      ...customSplit[0].sort( () => Math.random() - 0.5 ),
    ],
    [
      ...defaultSplit[1].sort( () => Math.random() - 0.5 ),
      ...customSplit[1].sort( () => Math.random() - 0.5 ),
    ],
    [
      ...defaultSplit[2].sort( () => Math.random() - 0.5 ),
      ...customSplit[2].sort( () => Math.random() - 0.5 ),
    ],
    [
      ...defaultSplit[3].sort( () => Math.random() - 0.5 ),
      ...customSplit[3].sort( () => Math.random() - 0.5 ),
    ],
  ];
}
