import { useGetCurrentUserInfoQuery } from '../../../../services/currentUserApi';
import { Submit } from '../../../forms';
import { ChatLink, EventMember } from '../../../ui';
import { AgeFilterField } from './AgeFilterField';
import { DefaultFields, DefaultFieldsProps } from './DefaultFields';
import { EventTypeHeader } from './EventTypeHeader';
import { GenderFilterField } from './GenderFilterField';

export interface PlainEventProps extends DefaultFieldsProps {
  edit?: boolean;
}

export const PlainEvent = ( { edit, ...defaultFieldsProps }: PlainEventProps ) => {
  const currentUser = useGetCurrentUserInfoQuery();

  return (
    <>
      <EventTypeHeader />
      <DefaultFields
        {...defaultFieldsProps}
        edit={edit}
      />
      <GenderFilterField gender={currentUser.data?.gender || 'other'} />
      <AgeFilterField />
      {currentUser.data && (
        <>
          <EventMember
            user={currentUser.data}
            creator
          />
          <ChatLink
            creation
            creatorPhone={currentUser.data.phone}
          />
          <Submit
            text={edit ? 'Update' : 'Publish'}
            styles={{ marginBottom: 12, marginTop: 16 }}
          />
        </>
      )}
    </>
  );
};
