import { ClickAwayListener } from '@mui/material';
import { BaseEmoji, Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import emojiRegex from 'emoji-regex';
import { useFormikContext } from 'formik';
import { ChangeEvent, useState } from 'react';
import '../../assets/css/components/controls/EmojiField.css';

export interface EmojiFieldProps {
  name: string;
  max?: number;
  placeholder?: string;
}

export const EmojiField = ( { name, max = 3, placeholder }: EmojiFieldProps ) => {
  const { values, setFieldValue } = useFormikContext();

  const [ showEmojis, setShowEmojis ] = useState( false );

  const fieldValue = ( values as Record<string, string> )[name];

  const onInputChange = ( event: ChangeEvent<HTMLInputElement> ) => {
    const emojiMatch = event.target.value.match( emojiRegex() ) as string[];
    const emojisLength = emojiMatch
      ? emojiMatch.reduce( ( sum, emoji ) => sum + emoji.length, 0 )
      : 0;
    if (
      event.target.value.length === 0 ||
      ( emojisLength === event.target.value.length && emojiMatch.length <= max )
    ) {
      setFieldValue( name, event.target.value );
    }
  };

  const openKeyboard = () => setShowEmojis( true );
  const closeKeyboard = () => setShowEmojis( false );
  const isTouchDevice = 'ontouchstart' in window;

  const onEmojiSelect = ( emoji: BaseEmoji ) => {
    const emojiMatch = fieldValue.match( emojiRegex() ) as string[];
    if ( fieldValue.length === 0 || emojiMatch.length < max ) {
      setFieldValue( name, fieldValue.concat( emoji.native ) );
    }
  };

  return (
    <ClickAwayListener onClickAway={closeKeyboard}>
      <div className='emoji-field'>
        <input
          type='text'
          value={showEmojis ? fieldValue : fieldValue || placeholder}
          name={name}
          onFocus={openKeyboard}
          onClick={openKeyboard}
          onChange={onInputChange}
          autoComplete='off'
        />
        {showEmojis && !isTouchDevice && (
          <Picker
            autoFocus={true}
            onSelect={onEmojiSelect}
            showPreview={false}
            showSkinTones={false}
            native={true}
            color='#f36f3b'
          />
        )}
      </div>
    </ClickAwayListener>
  );
};
