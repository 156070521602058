import { useMemo } from 'react';
import { EventSingle } from '../../../../services';
import { EventMember, EventMemberPhoneInvite } from '../../../ui';
import { EventMembersByoc } from './EventMembersByoc';

export interface EventMembersProps {
  event: EventSingle;
}

export const EventMembers = ( { event }: EventMembersProps ) => {
  const isPendingByoc = useMemo( () => {
    if ( event.byoc?.authorCrew ) {
      const { authorCrew } = event.byoc;
      return authorCrew.members.length !== event.maxMembers;
    }
    return false;
  }, [ event ] );

  if ( isPendingByoc && event.byoc?.authorCrew ) {
    const users = [ ...event.byoc.authorCrew.members ];
    const phones = event.byoc.authorCrew.invitedPhones.filter(
      phone =>
        !event.byoc?.authorCrew.members.find(
          member => member.phone.replace( '+', '' ) === phone.replace( '+', '' )
        )
    );
    return (
      <>
        {users.map( ( user, index ) => (
          <EventMember
            key={index}
            user={user}
            creator={event.author._id === user._id}
            isBYOC
          />
        ) )}
        {phones.map( ( phone, index ) => (
          <EventMemberPhoneInvite
            phone={phone}
            key={index}
          />
        ) )}
      </>
    );
  }

  if ( event.byoc ) {
    return <EventMembersByoc event={event} />;
  }

  return (
    <>
      {event.members.map( user => (
        <EventMember
          key={user._id}
          user={user}
          creator={event.author._id === user._id}
        />
      ) )}
    </>
  );
};
