import { useFormikContext } from 'formik';
import { useEffect } from 'react';


export interface FormChangesListenerProps {
  onChange: ( changed: boolean ) => void,
  debounceDuration?: number
}

export const FormChangesListener = (
  { onChange, debounceDuration } : FormChangesListenerProps
) => {

  const { values, initialValues } = useFormikContext();

  useEffect( () => {

    const timeout = setTimeout( () => {
      onChange( compareValues( values, initialValues ) );
    }, debounceDuration || 300 );

    return () => clearTimeout( timeout );
  }, [ values, initialValues, onChange, debounceDuration ] );


  return null;
};

const compareValues = ( value: unknown, otherValue: unknown ) => {
  return JSON.stringify( value ).length !== JSON.stringify( otherValue ).length;
};