import { CSSProperties, useMemo } from 'react';
import { ReactComponent as AddIcon } from '../../../../assets/img/icons/add.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/img/icons/close-circle.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/img/icons/cross.svg';
import { ReactComponent as FemaleIcon } from '../../../../assets/img/icons/female.svg';
import { ReactComponent as MaleIcon } from '../../../../assets/img/icons/male.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/img/icons/message.svg';
import { ReactComponent as SwapIcon } from '../../../../assets/img/icons/swap.svg';
import { EventSingle, GetUsersResponse } from '../../../../services';
import { useGetCurrentUserInfoQuery } from '../../../../services/currentUserApi';
import { EventMemberByoc, EventMemberByocProps } from '../../../ui';
import styles from './EventBody.module.scss';

export interface EventMembersByocProps {
  event: EventSingle;
  style?: CSSProperties;
  onRemoveJoinedCrew?: () => void;
  setUsers?: ( users: GetUsersResponse[0][] ) => void;
  editedUsers?: GetUsersResponse[0][];
  openCrewSearch?: ( isAdding?: boolean ) => () => void;
  hideJoined?: boolean;
}

export const EventMembersByoc = ( {
  event,
  style,
  onRemoveJoinedCrew,
  setUsers,
  editedUsers,
  openCrewSearch,
  hideJoined
}: EventMembersByocProps ) => {
  const { data: currentUser } = useGetCurrentUserInfoQuery();

  const genderIcon = ( gender: 'male' | 'female' ) => {
    return gender === 'male' ? <MaleIcon /> : <FemaleIcon />;
  };

  const canOnlyChangeUsers = useMemo( () => !!event.byoc?.joinedCrew, [ event ] );

  if ( !event.byoc ) return null;

  const authorCrewMembers = editedUsers || event.byoc.authorCrew.members;

  const authorCrewMembersAction: (
    user: GetUsersResponse[0]
  ) => EventMemberByocProps['action'] = user => {
    if ( !setUsers ) return;

    if ( canOnlyChangeUsers )
      return {
        icon: <SwapIcon />,
        onClick: () => {
          setUsers( authorCrewMembers.filter( member => member._id !== user._id ) );
          openCrewSearch && openCrewSearch( false )();
        }
      };

    return {
      icon: <CloseIcon />,
      onClick: () => setUsers( authorCrewMembers.filter( member => member._id !== user._id ) )
    };
  };

  const joinedCrewAuthorAction: EventMemberByocProps['action'] = onRemoveJoinedCrew
    ? {
        icon: <CloseIcon />,
        onClick: onRemoveJoinedCrew
      }
    : currentUser?._id === event.author._id
    ? {
        icon: <MessageIcon />,
        href: `https://wa.me/${event.byoc.joinedCrew?.author.phone}`
      }
    : undefined;

  return (
    <div
      className={styles['byoc-members']}
      style={style}
    >
      <div className={styles['header']}>
        <div className={styles['icons']}>
          {genderIcon( event.byoc.authorCrewGender )}
          <CrossIcon />
          {genderIcon( event.byoc.targetCrewGender )}
        </div>
      </div>
      <div className={styles['members']}>
        <div className={styles['crew']}>
          {authorCrewMembers.map( ( member, index ) => (
            <EventMemberByoc
              user={member}
              creator={member._id === event.author._id}
              key={index}
              action={
                member._id === event.author._id
                  ? undefined
                  : authorCrewMembersAction( member )
              }
            />
          ) )}
          {setUsers && !canOnlyChangeUsers && openCrewSearch && (
            <div
              className={styles['add']}
              onClick={openCrewSearch( true )}
            >
              <AddIcon /> Add crew member
            </div>
          )}
        </div>
        {!hideJoined && (
          <div className={styles['crew']}>
            {event.byoc.joinedCrew?.members.map( ( member, index ) => (
              <EventMemberByoc
                user={member}
                key={index}
                action={
                  member._id === event.byoc?.joinedCrew?.author._id
                    ? joinedCrewAuthorAction
                    : undefined
                }
              />
            ) )}
          </div>
        )}
      </div>
    </div>
  );
};
