import MUITextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon as Adapter } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import styles from './BirthDateField.module.scss';

export const BirthDateField = () => {
  const name = 'birthDate';

  const { values, setFieldValue, setFieldError, errors } = useFormikContext();

  const [ isClicked, setClicked ] = useState( false );

  const fieldValue = ( values as Record<string, Date> )[name];

  const onChange = ( date: DateTime | null ) => {
    if ( date )
      setFieldValue(
        name,
        DateTime.fromObject( {
          year: date.year,
          month: date.month
        } ).toJSDate()
      );
  };

  const onOpen = () => {
    setClicked( true );
  };

  useEffect( () => {
    if ( !isClicked && !( errors as Record<string, string> )[name] )
      setFieldError( name, 'required' );
  }, [ isClicked, setFieldError, errors ] );

  return (
    <LocalizationProvider dateAdapter={Adapter}>
      <DatePicker
        onChange={onChange}
        value={fieldValue || null}
        renderInput={props => (
          <MUITextField
            {...props}
            className={[ 'datetime-input', styles['birth-date'] ].join( ' ' )}
            inputProps={{
              ...props.inputProps,
              placeholder: 'date of birth'
            }}
          />
        )}
        minDate={DateTime.now().minus( { years: 120 } )}
        maxDate={DateTime.now().minus( { years: 18 } )}
        onOpen={onOpen}
        views={[ 'year', 'month' ]}
      />
    </LocalizationProvider>
  );
};
