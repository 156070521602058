import { useImageView } from '../../../../hooks/imageView';
import { useGetInstagramMedia } from '../../../../hooks/instagram';
import styles from './InstagramBlock.module.sass';

export interface InstagramImageProps {
  id: string
}

export const InstagramImage = ( { id } : InstagramImageProps ) => {
  
  const media = useGetInstagramMedia( id );
  const { openMedia } = useImageView();

  if ( !media ) return null;

  const onClick = () => {
    openMedia( media );
  };

  return (
    <div className={styles['instagram-image-wrapper']} onClick={onClick}>
      <img src={media.media_url} className={styles['instagram-image']} />
    </div>
  );
};