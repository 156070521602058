import { useMemo } from 'react';
import { useGetInstagramImages } from '../../../../hooks/instagram';
import { InstagramImage } from './InstagramImage';
import styles from './InstagramBlock.module.sass';
import { useParams } from 'react-router-dom';

export interface InstagramBlockSlideProps {
  slideNumber: number,
  imagesPerSlide: number
}

export const InstagramBlockSlide = ( { slideNumber, imagesPerSlide }: InstagramBlockSlideProps ) => {

  
  const params = useParams() as { userId?: string };
  
  const { images } = useGetInstagramImages( params.userId );

  const slideMedias = useMemo( () => (
    images.slice( imagesPerSlide * slideNumber, imagesPerSlide * ( slideNumber + 1 ) )
  ), [ images ] );
  
  return (
    <div className={styles['instagram-slide']}>
      {slideMedias.map( media => (
        <InstagramImage key={media.id} id={media.id} />
      ) )}
    </div>
  );
};