import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiSliceState } from '.';

const initialState : ApiSliceState = {
  isLoading: false
};

export const registerSlice = createSlice( {
  name: 'register',
  initialState: initialState,
  reducers: {
    setLoading: ( state ) => {
      state.isLoading = true;
    },
    setError: ( state, action: PayloadAction<string | object> ) => {
      state.error = action.payload;
      state.isLoading = false;
      state.success = false;
    },
    setSuccess: ( state, action: PayloadAction<boolean> ) => {
      state.success = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
  }
} );

export const registerActions = registerSlice.actions;