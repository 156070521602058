import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import { useGetInvitesQuery } from '../../../services/invitesApi';
import { useTypedSelector } from '../../../state';
import { InvitePreviewCard } from '../../ui';
import styles from './EventsListScreen.module.sass';

export const UnreadInvites = () => {
  const { isAuthorized } = useTypedSelector( state => state.auth );

  const { data: unreadInvites } = useGetInvitesQuery(
    isAuthorized ? { status: 'sent' } : skipToken
  );

  const plainInvites = useMemo(
    () => unreadInvites?.filter( invite => invite.type !== 'crew' ),
    [ unreadInvites ]
  );

  const crewInvites = useMemo(
    () => unreadInvites?.filter( invite => invite.type === 'crew' ),
    [ unreadInvites ]
  );

  return (
    <>
      {!!plainInvites?.length && (
        <>
          <InvitePreviewCard invites={plainInvites} />
          <h3 className={styles['section-label']}>All Events</h3>
        </>
      )}
      {!!crewInvites?.length && (
        <>
          <InvitePreviewCard
            invites={crewInvites}
            type='crew'
          />
          <h3 className={styles['section-label']}>All Events</h3>
        </>
      )}
    </>
  );
};
