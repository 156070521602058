import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocationSliceState } from '.';

const initialState: LocationSliceState = {
  lat: undefined,
  lng: undefined,
  locationReceived: false,
  locationDenied: undefined,
};

export const locationSlice = createSlice( {
  name: 'location',
  initialState,
  reducers: {
    setLocation: ( state, action: PayloadAction<{ lat: number; lng: number }> ) => {
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
      state.locationReceived = true;
    },
    setDenied: ( state, action: PayloadAction<LocationSliceState['locationDenied']> ) => {
      state.locationDenied = action.payload;
    },
  },
} );

export const locationActions = locationSlice.actions;
