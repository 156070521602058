import { useState } from 'react';
import '../../assets/css/components/controls/TextInput.css';
import '../../assets/css/components/controls/PasswordInput.css';
import { TextInput, TextInputProps } from './TextInput';
import { VisibilityToggle } from './VisibilityToggle';

export type PasswordInputProps = Omit<TextInputProps, 'type' | 'defaultValue' | 'children'>

export const PasswordInput = (
  { name, onChange, value, className, title, placeholder, error, onBlur } : PasswordInputProps
) => {
  const [ type, setType ] = useState<'password' | 'text'>( 'password' );

  const toggleVisibility = ( visible: boolean ) => setType( visible ? 'text' : 'password' );
  
  return (
    <TextInput
      name={name}
      onChange={onChange}
      value={value}
      className={[ 'password', className, type === 'text' ? 'visible' : undefined ].join( ' ' )}
      title={title}
      placeholder={placeholder}
      type={type}
      defaultValue=''
      error={error}
      onBlur={onBlur}
    >
      <VisibilityToggle onToggle={toggleVisibility} />
    </TextInput>
  );
};