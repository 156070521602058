import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import '../../../assets/css/components/ui/views/EventItem.css';
import { ReactComponent as WarningIcon } from '../../../assets/img/icons/warning-white.svg';
import { Event } from '../../../services';
import { getCssGradientObject } from '../../../utils';
import { EventCardInfo } from './EventCardInfo';
export interface EventItemProps {
  event: Event;
  onLinkClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const EventItem = ( { event, onLinkClick }: EventItemProps ) => {
  const isPendingByoc = useMemo( () => {
    if ( event.crew ) {
      return event.crew.members.length < event.maxMembers;
    }
    return false;
  }, [ event ] );

  const isByoc = !!event.byoc;

  return (
    <Link
      to={`/event/${event.title.replace( /[^a-z\d]+/gi, '' )}_${event._id}`}
      onClick={onLinkClick}
      className='event-item-link'
    >
      <article
        className='event-item'
        style={getCssGradientObject( event.byoc ? '#8667F8' : event.bannerColor )}
      >
        {isByoc && (
          <div className={[ 'byoc', isPendingByoc ? 'pending' : undefined ].join( ' ' )}>
            {isPendingByoc ? (
              <>
                <WarningIcon /> Pending
              </>
            ) : (
              'BYOC'
            )}
          </div>
        )}
        <div className='emoji'>{event.emoji}</div>
        <div className='event-item-body'>
          <h1 className='title'>{event.title}</h1>
          <EventCardInfo event={event} />
          <div className='description'>{event.description.slice( 0, 122 )}</div>
        </div>
      </article>
    </Link>
  );
};
