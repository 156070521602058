import { Link, To } from 'react-router-dom';
import '../../../assets/css/components/ui/views/NavList.css';

export interface NavListProps {
  links: {
    label: string;
    to: To;
    state?: object;
    custom?: boolean;
  }[];
}

export const NavList = ( { links }: NavListProps ) => {
  return (
    <nav>
      <ul className='nav-list'>
        {links.map( link => (
          <li key={link.label + link.to}>
            {link.custom ? (
              <a
                href={link.to.toString()}
                target='_blank'
                rel='noreferrer'
              >
                {link.label}
              </a>
            ) : (
              <Link
                to={link.to}
                state={link.state}
              >
                {link.label}
              </Link>
            )}
          </li>
        ) )}
      </ul>
    </nav>
  );
};
