import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { ReactComponent as Clock } from '../../../../assets/img/icons/clock.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/img/icons/close-light.svg';
import { ReactComponent as Location } from '../../../../assets/img/icons/location.svg';
import { ReactComponent as User } from '../../../../assets/img/icons/userStroke.svg';
import { EventCreationFormFields } from '../../../../services';
import { useGetBannerColorsQuery } from '../../../../services/eventsService';
import {
  byocGradient,
  getCssGradientObject,
  getEventStartString
} from '../../../../utils';
import {
  ColorPickerField,
  DateTimeField,
  DropDownField,
  EmojiField,
  TextAreaField,
  TextField
} from '../../../forms';
import { EventInfo } from '../../../ui';
import styles from '../EventCreationScreen.module.scss';

const maxMembersOptions = ( byoc = false ) =>
  Array.from( { length: byoc ? 3 : 7 } ).map( ( _, index ) => ( {
    value: ( index + 2 ).toString(),
    label: ( index + 2 ).toString()
  } ) );

export interface DefaultFieldsProps {
  showLocationModal: boolean;
  setShowLocationModal: ( value: boolean ) => void;
  onMaxMembersUpdate?: ( max: number ) => void;
  edit?: boolean;
  disabled?: { maxMembers?: boolean; start?: boolean };
  byoc?: boolean;
}

export const DefaultFields = ( {
  setShowLocationModal,
  showLocationModal,
  onMaxMembersUpdate,
  edit,
  disabled,
  byoc
}: DefaultFieldsProps ) => {
  const { values } = useFormikContext<EventCreationFormFields>();
  const bannerColors = useGetBannerColorsQuery( byoc ? skipToken : undefined );

  const [ isByocNoteClosed, setByocNoteClosed ] = useState( false );

  const toggleByocNoteClosed = ( value: boolean ) => () => setByocNoteClosed( value );

  const maxMembers = values.maxMembers;

  useEffect( () => {
    if ( onMaxMembersUpdate ) onMaxMembersUpdate( maxMembers );
  }, [ maxMembers, onMaxMembersUpdate ] );

  return (
    <>
      <div
        className={[ 'event-banner creation', styles['banner'] ].join( ' ' )}
        style={
          byoc ? byocGradient : getCssGradientObject( values.bannerColor || '#C3BFBE' )
        }
      >
        <EmojiField
          name='emoji'
          placeholder={String.fromCodePoint( 0x1f366, 0x1f389 )}
        />
        <p>Use emojis to describe your event!</p>
        <TextField
          name='title'
          className='title'
          placeholder='Add an event title'
        />
        {!byoc && bannerColors.data && (
          <ColorPickerField
            colors={bannerColors.data.map( bannerColor => bannerColor.color )}
            label='Select a banner Gradient'
            name='bannerColor'
          />
        )}
      </div>
      {byoc && !isByocNoteClosed && (
        <div className={styles['byoc-note']}>
          <p>
            What is BYOC? Bring.Your.Own.Crew is for your friend crew to meet a new friend
            crew of the same size and have a social mixer!
          </p>
          <div
            className={styles['close']}
            onClick={toggleByocNoteClosed( true )}
          >
            <CloseIcon />
          </div>
        </div>
      )}
      <EventInfo
        className={showLocationModal ? 'hide' : undefined}
        items={[
          {
            icon: Location,
            element: <p>{values.location.title || 'Where is it?'}</p>,
            onClick: () => setShowLocationModal( true ),
            className: 'location'
          },
          {
            icon: User,
            element: disabled?.maxMembers ? (
              <>{values.maxMembers}</>
            ) : (
              <DropDownField
                name='maxMembers'
                options={maxMembersOptions( byoc )}
                placeholder={values.byoc ? 'How many people?' : 'Set group size 2-8'}
              />
            )
          },
          {
            icon: Clock,
            element: disabled?.start ? (
              <>{getEventStartString( values )}</>
            ) : (
              <DateTimeField
                name='start'
                minDateTime={DateTime.now()}
                maxDateTime={DateTime.now().plus( { hours: 72 } )}
                required={!edit}
              />
            ),
            className: 'date'
          }
        ]}
      />
      <TextAreaField
        name='description'
        maxCharacters={450}
        placeholder={
          values.byoc
            ? "Hey peeps! my friends in this crew and I are putting together a fun evening and would love for your friend group to join us. We're thinking of heading to the new escape room place in town followed by some drinks and snacks at a nearby lounge. ping us if you’re free and we'll make the booking!"
            : 'e.g I really wanted to check out the seasonal flavors of icecream at Birds of Paradise in katong. Anyone interested in joining? we can walk around the area after. Feel free to join the group and send me a message, i’ll make a whatsapp group for those who join!'
        }
        title='Event Description'
      />
    </>
  );
};
