import { Link } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { useDocTitle } from '../../hooks';
import { routesPaths } from '../../navigation';
import { useGetCurrentUserInfoQuery } from '../../services/currentUserApi';
import { Avatar, LoadingIndicator, ScreenTitle } from '../ui';
import { NavList } from '../ui/views/NavList';
import { MyEventsScreenState } from './MyEventsScreen';

export const SettingsScreen = () => {
  useDocTitle( 'Crewww - Settings' );

  const currentUser = useGetCurrentUserInfoQuery();

  if ( currentUser.isLoading ) return <LoadingIndicator />;
  if ( !currentUser.data ) return <>error</>;

  return (
    <>
      <ScreenTitle title='Your Settings' />
      <h3>{currentUser.data.name}</h3>
      <Link to={routesPaths.profile.index}>
        <Avatar
          user={currentUser.data}
          style={{
            margin: '0 auto',
            width: 120,
            height: 120
          }}
        />
      </Link>
      <Link
        to={[ routesPaths.profile.index, routesPaths.profile.edit ].join( '/' )}
        className='nav-link'
        style={{ width: '100%', padding: 14 }}
      >
        <EditIcon />
        Edit profile
      </Link>
      <NavList
        links={[
          {
            label: 'Events created',
            to: [ routesPaths.events.index, routesPaths.events.my ].join( '/' ),
            state: { show: 'created' } as MyEventsScreenState
          },
          {
            label: 'Events joined',
            to: [ routesPaths.events.index, routesPaths.events.my ].join( '/' ),
            state: { show: 'joined' } as MyEventsScreenState
          },
          {
            label: 'Account settings',
            to: routesPaths.settings.account
          },
          {
            label: 'Notification preferences ',
            to: routesPaths.settings.notifications
          },
          {
            label: 'Help center',
            to: 'https://wa.me/+6580448430',
            custom: true
          },
          {
            label: 'Logout',
            to: routesPaths.logout
          }
        ]}
      />
      <div style={{ marginBottom: 100 }} />
    </>
  );
};
