import { PropsWithChildren } from 'react';
import styles from './LabeledField.module.sass';

export type LabeledTextProps = PropsWithChildren<{
  label: string | JSX.Element;
  className?: string;
}>;

export const LabeledField = ( { label, children = '', className }: LabeledTextProps ) => (
  <div className={[ styles['labeled-field'], className ].join( ' ' )}>
    <h4 className={styles['label']}>{label}</h4>
    <div>{children}</div>
  </div>
);
