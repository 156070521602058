import {
  TouchEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Tag } from '../../../../services';
import { useGetTagsQuery } from '../../../../services/tagsService';
import { useTypedSelector } from '../../../../state';
import { splitTags } from '../../../../utils';
import { TagList } from '../../../controls';
import styles from '../InviteScreen.module.sass';

export interface TagsSelectProps {
  selectedFeatures: string[];
  selectedInterests: string[];
  onFeaturesChange: ( tags: string[] ) => void;
  onInterestsChange: ( tags: string[] ) => void;
}

export const TagsSelect = ( {
  selectedFeatures,
  selectedInterests,
  onFeaturesChange,
  onInterestsChange,
}: TagsSelectProps ) => {
  const { containerWidth, containerPadding } = useTypedSelector( state => state.app );

  const { data: features } = useGetTagsQuery( { type: 'userFeature', all: true } );
  const { data: interests } = useGetTagsQuery( { type: 'userInterest', all: true } );

  const [ touchStart, setTouchStart ] = useState( 0 );
  const [ translateBefore, setTranslateBefore ] = useState( 0 );
  const [ touchX, setTouchX ] = useState( 0 );
  const [ maxListWidth, setMaxListWidth ] = useState( 0 );

  const isTouch = useMemo( () => {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ( navigator as any ).msMaxTouchPoints > 0
    );
  }, [] );

  const edge = useMemo(
    () => containerWidth - maxListWidth - containerPadding * 2,
    [ maxListWidth ]
  );
  const translate = ( () => {
    const tmp = translateBefore + touchX - touchStart;
    if ( tmp >= 0 ) return 0;
    if ( tmp < edge ) return edge;
    return tmp;
  } )();

  const tagListRef = useCallback(
    ( element: HTMLDivElement | null ) => {
      if ( element ) {
        if ( element.offsetWidth > maxListWidth ) setMaxListWidth( element.offsetWidth );
      }
    },
    [ maxListWidth ]
  );
  const containerRef = useRef<HTMLDivElement>( null );

  const onTagSelected = ( tag: Tag ) => {
    const setter = tag.type === 'userFeature' ? onFeaturesChange : onInterestsChange;
    const setterToClear =
      tag.type === 'userFeature' ? onInterestsChange : onFeaturesChange;
    setter( [ tag._id ] );
    setterToClear( [] );
  };

  const tagsSplitted = useMemo( () => {
    if ( features && interests ) {
      return splitTags( [ ...features, ...interests ] );
    }
    return [ [], [], [], [] ];
  }, [ features, interests ] );

  const onTouchStart: TouchEventHandler<HTMLDivElement> = e => {
    setTouchStart( e.touches[0].screenX );
    setTouchX( e.touches[0].screenX );
  };
  const onTouchEnd: TouchEventHandler<HTMLDivElement> = e => {
    const x = e.changedTouches[0].screenX;
    if ( touchStart ) {
      setTranslateBefore( translateBefore + x - touchStart );
    }
    setTouchX( 0 );
    setTouchStart( 0 );
  };
  const onTouchMove: TouchEventHandler<HTMLDivElement> = e => {
    setTouchX( e.touches[0].screenX );
  };

  const onWheel = useCallback(
    ( e: WheelEvent ) => {
      const tmp = translateBefore - e.deltaY * 2;
      if ( tmp <= 0 ) setTranslateBefore( tmp );
      e.preventDefault();
    },
    [ translateBefore ]
  );

  useEffect( () => {
    if ( containerRef.current ) {
      containerRef.current.addEventListener( 'wheel', onWheel, { passive: false } );
      return () => {
        if ( containerRef.current ) {
          containerRef.current.removeEventListener( 'wheel', onWheel );
        }
      };
    }
  }, [ onWheel ] );

  return (
    <div
      className={styles['tags']}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      style={{
        transform: `translateX(${translate}px)`,
        transition: isTouch ? undefined : '.2s',
      }}
      ref={containerRef}
    >
      <TagList
        items={tagsSplitted[0]}
        editable
        selected={[ ...selectedFeatures, ...selectedInterests ]}
        onSelect={onTagSelected}
        className={styles['list']}
        containerRef={tagListRef}
      />
      {!!tagsSplitted[1].length && (
        <TagList
          items={tagsSplitted[1]}
          editable
          selected={[ ...selectedFeatures, ...selectedInterests ]}
          onSelect={onTagSelected}
          className={styles['list']}
          containerRef={tagListRef}
        />
      )}

      <TagList
        items={tagsSplitted[2]}
        editable
        selected={[ ...selectedFeatures, ...selectedInterests ]}
        onSelect={onTagSelected}
        className={styles['list']}
        containerRef={tagListRef}
      />
      {!!tagsSplitted[3].length && (
        <TagList
          items={tagsSplitted[3]}
          editable
          selected={[ ...selectedFeatures, ...selectedInterests ]}
          onSelect={onTagSelected}
          className={styles['list']}
          containerRef={tagListRef}
        />
      )}
    </div>
  );
};
