import { useFormikContext } from 'formik';
import { EventCreationFormFields } from '../../../../../services';
import styles from './BYOCGender.module.scss';
import { ReactComponent as FemaleIcon } from './icons/female.svg';
import { ReactComponent as MaleIcon } from './icons/male.svg';

export const BYOCGender = () => {
  const { values, setFieldValue } = useFormikContext<EventCreationFormFields>();

  const toggleGender = ( value: string ) => () => {
    setFieldValue( 'byoc', {
      ...values.byoc,
      targetCrewGender: value
    } as EventCreationFormFields['byoc'] );
  };

  return (
    <div className={styles['byoc-gender']}>
      <p>What group are you looking for?</p>
      <div className={styles['options']}>
        <div
          className={styles['option']}
          onClick={toggleGender( 'female' )}
          data-checked={values.byoc?.targetCrewGender === 'female'}
          data-gender='female'
        >
          <div className={styles['check']}></div>
          <span>All</span>
          <FemaleIcon />
        </div>
        <div
          className={styles['option']}
          onClick={toggleGender( 'male' )}
          data-checked={values.byoc?.targetCrewGender === 'male'}
          data-gender='male'
        >
          <div className={styles['check']}></div>
          <span>All</span>
          <MaleIcon />
        </div>
      </div>
    </div>
  );
};
