import { useFormikContext } from 'formik';
import { useState } from 'react';
import { EventCreationFormFields, GetUsersResponse } from '../../../../../services';
import { Submit } from '../../../../forms';
import styles from './BYOCInvites.module.scss';
import { Crew } from './Crew';
import { CrewSearch } from './CrewSearch';

export const BYOCInvites = () => {
  const { values, setFieldValue } = useFormikContext<EventCreationFormFields>();

  const [ addedUsers, setAddedUsers ] = useState<GetUsersResponse>( [] );

  const byoc = values.byoc;

  const onUserAdd = ( user: GetUsersResponse[0] ) => {
    setAddedUsers( [ ...addedUsers, user ] );
  };

  const onUserRemove = ( id: string ) => {
    if ( byoc ) {
      setFieldValue( 'byoc', {
        ...byoc,
        invites: {
          ...byoc.invites,
          registered: byoc.invites.registered.filter( inviteeId => inviteeId !== id )
        }
      } as EventCreationFormFields['byoc'] );
    }
  };

  const onPhoneRemove = ( phone: string ) => {
    if ( byoc ) {
      setFieldValue( 'byoc', {
        ...byoc,
        invites: {
          ...byoc.invites,
          notRegistered: byoc.invites.notRegistered.filter(
            inviteePhone => inviteePhone !== phone
          )
        }
      } as EventCreationFormFields['byoc'] );
    }
  };

  return (
    <div className={styles['byoc-invites']}>
      <Crew
        crewSize={values.maxMembers}
        users={
          values.byoc?.invites.registered.map(
            id => addedUsers.find( user => user._id === id ) as GetUsersResponse[0]
          ) || []
        }
        phones={values.byoc?.invites.notRegistered}
        onUserRemove={onUserRemove}
        onPhoneRemove={onPhoneRemove}
      />
      <CrewSearch onUserAdd={onUserAdd} />
      <div className={styles['publish']}>
        <p>Users who join will contact you via whatsapp</p>
        <Submit text='Publish' />
      </div>
    </div>
  );
};
