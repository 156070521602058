import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { instagramApi } from '../services/instagram';
import { landingService } from '../services/landingService';
import { mainApi } from '../services/mainApi';
import { appSlice } from './slices/appSlice';
import { authSlice } from './slices/authSlice';
import { eventCreationPrefillSlice } from './slices/eventCreationPrefillSlice';
import { imageViewSlice } from './slices/imageViewSlice';
import { invtieScreenSlice } from './slices/inviteScreenSlice';
import { locationSlice } from './slices/locationSlice';
import { otpSlice } from './slices/otpSlice';
import { registerSlice } from './slices/registerSlice';
import { facebookSlice, googleSlice } from './slices/thirdParty';

export const store = configureStore( {
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [registerSlice.name]: registerSlice.reducer,
    [otpSlice.name]: otpSlice.reducer,
    [locationSlice.name]: locationSlice.reducer,
    [facebookSlice.name]: facebookSlice.reducer,
    [googleSlice.name]: googleSlice.reducer,
    [imageViewSlice.name]: imageViewSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    [invtieScreenSlice.name]: invtieScreenSlice.reducer,
    [eventCreationPrefillSlice.name]: eventCreationPrefillSlice.reducer,
    [landingService.reducerPath]: landingService.reducer,
    [instagramApi.reducerPath]: instagramApi.reducer,
    [mainApi.reducerPath]: mainApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware( {
      serializableCheck: false
    } )
      .concat( instagramApi.middleware )
      .concat( landingService.middleware )
      .concat( mainApi.middleware )
} );

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type Store = typeof store;
