import { CSSProperties, MouseEventHandler } from 'react';
import '../../assets/css/components/controls/Button.css';

export interface ButtonProps {
  text: string | JSX.Element;
  onClick?: MouseEventHandler | ( () => void );
  type?: 'submit' | 'reset' | 'button';
  style?: 'secondary' | 'transparentNoBorder';
  disabled?: boolean;
  styles?: CSSProperties;
  className?: string;
}

export const Button = ( {
  text,
  onClick,
  type = 'button',
  style,
  disabled,
  styles,
  className,
}: ButtonProps ) => (
  <button
    onClick={onClick}
    type={type}
    className={[ 'button', style, className ].join( ' ' )}
    disabled={disabled}
    style={styles}
  >
    {text}
  </button>
);
