import { useFormikContext } from 'formik';
import { TextInput, TextInputProps } from '../controls';

export type TextFieldProps = Omit<TextInputProps, 'onChange' | 'value' | 'defaultValue'> 

export const TextField = (
  props : TextFieldProps
) => {

  const { values, initialValues, handleChange, errors, touched, handleBlur } = useFormikContext();

  const error = ( errors as Record<string, string | undefined> )[props.name];
  const isTouched = ( touched as Record<string, boolean | undefined> )[props.name];

  return (
    <TextInput 
      {...props}
      value={( values as Record<string, string> )[props.name]}
      onChange={handleChange( props.name )}
      defaultValue={( initialValues as Record<string, string> )[props.name]}
      error={isTouched ? error : undefined}
      onBlur={handleBlur( props.name )}
    />
  );
};