import { Link } from 'react-router-dom';
import { routesPaths } from '../../../../navigation';
import { Event, Invite, UserMediaExpanded } from '../../../../services';
import { useReadInviteMutation } from '../../../../services/invitesApi';
import { getEventUrl, getInviteCardGradient } from '../../../../utils';
import { EventCardInfo } from '../EventCardInfo';
import styles from './InviteCard.module.sass';
export interface InviteCardProps {
  user: UserMediaExpanded;
  event: Event;
  isUnread?: boolean;
  background?: string;
  invite: Invite;
}

export const InviteCard = ( {
  user,
  event,
  isUnread,
  background,
  invite
}: InviteCardProps ) => {
  const [ readInvite ] = useReadInviteMutation();

  const onContentClick = isUnread
    ? () => {
        if ( invite._id ) readInvite( invite._id );
      }
    : undefined;

  return (
    <article
      className={styles['invite-card']}
      style={{ background: getInviteCardGradient( background || '#BAFFF3' ) }}
    >
      {isUnread && <div className={styles['unread']}></div>}
      <Link to={[ routesPaths.profile.index, user._id ].join( '/' )}>
        <div className={styles['header']}>
          <div className={styles['avatar']}>
            {user.media.length ? (
              <img
                src={user.media[0].url}
                alt={user.name}
              />
            ) : (
              <div className={styles['no-image']}>{user.name[0]}</div>
            )}
          </div>
          <span className={styles['name']}>{user.name}</span>
          <span>
            {invite.type === 'plain' ? 'wants you to join' : 'added you to their crew!'}
          </span>
        </div>
      </Link>
      <Link
        to={getEventUrl( event )}
        onClick={onContentClick}
      >
        <div className={styles['content']}>
          <p>{event.emoji}</p>
          <h1>{event.title}</h1>
          <EventCardInfo
            event={event}
            className={styles['card-info']}
          />
        </div>
      </Link>
    </article>
  );
};
