import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventCreationFormFields } from '../../services';

const initialState = {
  prefill: undefined as EventCreationFormFields | undefined
};

export const eventCreationPrefillSlice = createSlice( {
  initialState,
  name: 'eventCreationPrefill',
  reducers: {
    setPrefill: ( state, action: PayloadAction<EventCreationFormFields | undefined> ) => {
      state.prefill = action.payload;
    }
  }
} );

export const eventCreationPrefillActions = eventCreationPrefillSlice.actions;
