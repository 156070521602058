import { useDocTitle } from '../../../hooks';
import { useGetInvitesQuery } from '../../../services/invitesApi';
import { InviteCard, LabeledField, ScreenTitle } from '../../ui';
import styles from './InvitesScreen.module.sass';

export const InvitesScreen = () => {
  useDocTitle( 'Crewww - Invites' );

  const { data: readInvites } = useGetInvitesQuery( { status: 'read' } );
  const { data: unreadInvites } = useGetInvitesQuery( { status: 'sent' } );

  const colors = [ '#BAFFF3', '#FFBAD2', '#FFBFA6' ];

  return (
    <>
      <ScreenTitle title='Invitations' />
      {unreadInvites?.map( ( invite, index ) => (
        <InviteCard
          event={invite.event}
          user={invite.author}
          key={index}
          background={colors[index % colors.length]}
          isUnread
          invite={invite}
        />
      ) )}
      {!!readInvites?.length && (
        <LabeledField
          label={'Past invites'}
          className={styles['past-invites']}
        >
          {readInvites.map( ( invite, index ) => (
            <InviteCard
              event={invite.event}
              user={invite.author}
              key={index}
              background={colors[index % colors.length]}
              invite={invite}
            />
          ) )}
        </LabeledField>
      )}
      <div style={{ marginBottom: 100 }}></div>
    </>
  );
};
