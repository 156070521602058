import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IconType } from 'react-icons';
import '../../../assets/css/components/ui/views/BasicInfo.css';

export interface BasicInfoItem {
  text?: string | number;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | IconType;
}

export interface BasicInfoProps {
  items: BasicInfoItem[];
  label?: string;
}

export const BasicInfo = ( { items, label }: BasicInfoProps ) => {
  const itemsRefs = useRef<HTMLDivElement[]>( [] );
  const containerRef = useRef<HTMLDivElement | null>( null );
  const listRef = useRef<HTMLDivElement | null>( null );

  const [ touchPosition, setTouchPosition ] = useState<number | null>( null );
  const [ translateX, setTranslateX ] = useState( 0 );
  const [ transformStyle, setTransformStyle ] = useState( 'translateX(0)' );

  const totalItemsWidth = itemsRefs.current.reduce(
    ( width, item ) => ( item ? width + item.clientWidth : width ),
    0
  );
  const containerWidth = useMemo(
    () => ( containerRef.current?.clientWidth || 0 ) - 20,
    [ containerRef.current ]
  );
  const isMobile = window.innerWidth < 576;

  useEffect( () => {
    if ( listRef.current && totalItemsWidth )
      listRef.current.style.width = totalItemsWidth + 10 + 'px';
  }, [ totalItemsWidth, listRef.current ] );

  useEffect( () => {
    if ( touchPosition !== null ) {
      document.addEventListener( 'mousemove', onDrag );
      document.addEventListener( 'mouseup', onDragEnd );
    }
    return () => {
      document.removeEventListener( 'mousemove', onDrag );
      document.removeEventListener( 'mouseup', onDragEnd );
    };
  }, [ touchPosition ] );

  const onDragStart = ( event: React.MouseEvent ) => {
    setTouchPosition( event.clientX );
  };
  const onDragEnd = () => {
    setTouchPosition( null );
  };
  const onDrag = ( event: MouseEvent ) => {
    const translate = translateX + event.clientX - ( touchPosition as number ) + 10;
    if ( translate < 0 && translate > containerWidth - totalItemsWidth )
      setTranslateX( translate );
  };

  useEffect( () => {
    setTransformStyle( `translateX(${translateX}px)` );
  }, [ translateX ] );

  return (
    <div
      className='basic-info'
      onMouseDown={isMobile ? undefined : onDragStart}
    >
      {label && <h4 className='label'>{label}</h4>}
      <div
        className='list-container'
        ref={containerRef}
      >
        <div
          className='list'
          ref={listRef}
          style={{ transform: transformStyle }}
        >
          {items.map( ( item, index ) =>
            item.text ? (
              <div
                className='list-item'
                ref={el => ( itemsRefs.current[index] = el as HTMLDivElement )}
                key={index}
              >
                <item.icon className='icon' />
                {item.text}
              </div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};
