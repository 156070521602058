import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { baseUrl, Event } from '.';



export const landingService = createApi( {
  reducerPath: 'landing',
  baseQuery: fetchBaseQuery( { baseUrl } ),
  endpoints: build => ( {
    getLandingEvents: build.query<Event[], void>( {
      query: () => ( { url: '/landing' } )
    } )
  } )
} );

export const {
  useGetLandingEventsQuery
} = landingService;