import { Navigate, Route, Routes } from 'react-router-dom';
import { routesPaths } from '.';
import { ProfileEditScreen } from '../components/screens';



export const FirstLoginRouter = () => (
  <Routes>
    <Route path={[ routesPaths.profile.index, routesPaths.profile.edit ].join( '/' )}  element={<ProfileEditScreen />}/>
    <Route 
      path='/*' 
      element={<Navigate to={[ routesPaths.profile.index, routesPaths.profile.edit ].join( '/' )} />} 
    />
  </Routes>
);