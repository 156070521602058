import { useEffect } from 'react';
import { authService } from '../../services';
import { facebookService } from '../../services/facebookService';
import { useTypedSelector } from '../../state';


export const LogoutRoute = () => {

  const facebookLoggedIn = useTypedSelector( state => state.facebookState.loggedIn );

  useEffect( () => {
    if ( facebookLoggedIn ) facebookService.logout();
    authService.logout();
  }, [] );
  
  return null;
};