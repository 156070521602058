import { deleteObject, getDownloadURL, ref, StorageReference, uploadBytes } from 'firebase/storage';
import { ServerResponse, ServerStatus } from '..';
import { Media } from '../models';

import { firebaseStorage } from './configuration';

async function uploadUserImage ( image: File, uid: string ) {
  try {
    const userFolderRef = ref( firebaseStorage, uid );
    const imageRef = await getImageRef( userFolderRef, image.name );
    const result = await uploadBytes( imageRef, image );  
    return result.ref;
  } catch ( err ) {
    console.error( err );
    return <ServerResponse>{ success: false, status: ServerStatus.UNKNOWN_ERROR };
  }
}

async function deleteUserImage ( ref: StorageReference ) {
  try {
    await deleteObject( ref )
      .catch( reason => {
        throw new Error( reason );
    } );
    return <ServerResponse>{ success: true, status: ServerStatus.SUCCESS };
  } catch ( err ) {
    console.error( err );
    return <ServerResponse>{ success: false, status: ServerStatus.UNKNOWN_ERROR };
  }
}

async function getImageRef ( 
  userFolderRef: StorageReference, imageName: string 
): Promise<StorageReference> {
  return await getDownloadURL( ref( userFolderRef, imageName ) )
    .then( () => getImageRef( userFolderRef, `d${imageName}` ) )
    .catch( () => ref( userFolderRef, imageName ) );
}

async function getMediaURL ( media: Media ) {
  return await getDownloadURL( ref( firebaseStorage, media.firebasePath ) );
}

export const firebaseService = {
  uploadUserImage,
  deleteUserImage,
  getMediaURL
};
