import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { ReactComponent as SearchIcon } from '../../../../../assets/img/icons/search.svg';
import { ReactComponent as WarningIcon } from '../../../../../assets/img/icons/warning.svg';
import { GetUsersResponse } from '../../../../../services';
import { useGetCurrentUserInfoQuery } from '../../../../../services/currentUserApi';
import { useLazyGetUsersQuery } from '../../../../../services/usersApi';
import styles from './CrewSearch.module.scss';
import { SearchUser } from './components';

export interface CrewSearchProps {
  onUserAdd: ( user: GetUsersResponse[0] ) => void;
  addedUsers: string[];
  targetGender: 'male' | 'female';
  max: number;
  members: string[];
  exclude?: string[];
}

export const CrewSearch = ( {
  addedUsers,
  onUserAdd,
  targetGender,
  max,
  members,
  exclude = []
}: CrewSearchProps ) => {
  const [ getUsers ] = useLazyGetUsersQuery();
  const { data: currentUser } = useGetCurrentUserInfoQuery();

  const [ search, setSearch ] = useState( '' );
  const [ foundUsers, setFoundUsers ] = useState<GetUsersResponse>();

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setSearch( e.target.value );
  };

  const searchUsers = useCallback(
    debounce( 300, async ( searchTerm: string ) => {
      if ( searchTerm.length < 3 || !targetGender ) return;
      const isPhone = /^\+?\d+$/.test( searchTerm );
      if ( isPhone && searchTerm.length < 9 ) return;
      const { data: users } = await getUsers(
        isPhone
          ? { phone: searchTerm.replace( '+', '' ), gender: targetGender }
          : { name: searchTerm, gender: targetGender }
      );
      console.log( { users, addedUsers, members, exclude } );
      if ( users )
        setFoundUsers(
          users.filter(
            user =>
              user._id !== currentUser?._id &&
              ![ ...addedUsers, ...members, ...exclude ].includes( user._id )
          )
        );
    } ),
    [ setFoundUsers, currentUser, targetGender, addedUsers ]
  );

  useEffect( () => {
    searchUsers( search );
  }, [ search ] );

  const onAdd = ( user: GetUsersResponse[0] ) => () => onUserAdd( user );

  return (
    <div className={styles['crew-search']}>
      <h4>Search and add your crew</h4>
      <div className={styles['search']}>
        <SearchIcon />
        <input
          placeholder='Search for user name or add by number'
          value={search}
          onChange={onInputChange}
        />
      </div>
      {!!foundUsers?.length && (
        <ul className={styles['found-users']}>
          {foundUsers.map( ( user, index ) => (
            <li key={index}>
              <SearchUser
                user={user}
                onAdd={onAdd( user )}
                isAdded={addedUsers.includes( user._id )}
                disabled={max ? addedUsers.length >= max : false}
              />
            </li>
          ) )}
        </ul>
      )}
      {foundUsers?.length === 0 && (
        <div className={styles['invite-phone']}>
          <p>Seems like they are not on Crewww yet ....</p>

          <div className={styles['invited-note']}>
            <WarningIcon />
            <div>
              <h4>Make sure your crew signs up!</h4>
              <p>
                Before the invited crew signs up, your event will be pending and other
                users won’t be able to see your event.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
