import { IconType } from 'react-icons';
import '../../assets/css/components/controls/IconButton.css';

export interface IconButtonProps {
  icon: IconType,
  onClick: () => void
}

export const IconButton = ( { icon : Icon, onClick } : IconButtonProps ) => (
  <Icon className='icon-button' onClick={onClick} />
);