import styles from './Modal.module.sass';

export interface ModalTitleProps {
  title: string;
}

export const ModalTitle = ( { title }: ModalTitleProps ) => (
  <div className={styles['modal-title']}>
    <p>{title}</p>
  </div>
);
