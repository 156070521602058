import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useRecaptchaScript } from '../../../hooks';
import { routesPaths } from '../../../navigation';
import {
  FacebookRegisterData,
  authService,
  registrationService
} from '../../../services';
import { useTypedSelector } from '../../../state';
import { authActions } from '../../../state/slices/authSlice';
import { otpActions } from '../../../state/slices/otpSlice';
import { facebookActions } from '../../../state/slices/thirdParty';
import { facebookRegistrationValidationSchema } from '../../../utils';
import {
  CheckBoxField,
  DropDownField,
  OTPField,
  PhoneField,
  Submit,
  TextField
} from '../../forms';
import { ScreenTitle } from '../../ui';

export const FacebookRegisterScreen = () => {
  useRecaptchaScript();

  const facebookState = useTypedSelector( state => state.facebookState );
  const otpState = useTypedSelector( state => state.otp );

  const [ otpSent, setOTPSent ] = useState( false );

  const dispatch = useDispatch();

  useEffect( () => {
    if ( otpState.error && typeof otpState.error === 'string' ) {
      toast.error( otpState.error );
      dispatch( otpActions.setError( undefined ) );
    }
    if ( otpSent && otpState.success ) {
      toast.success( 'One time password has been sent to your phone number' );
      dispatch( otpActions.setSuccess( undefined ) );
    }
  }, [ otpState.error, otpSent, otpState.success ] );

  useEffect( () => {
    if ( facebookState.registrationSuccess ) {
      toast.success( 'Registered successfully' );
      dispatch( authActions.setFirstLogin( true ) );
    } else if (
      facebookState.registrationError &&
      typeof facebookState.registrationError === 'string'
    ) {
      toast.error( facebookState.registrationError );
    }
    dispatch( facebookActions.setRegistrationSuccess( undefined ) );
    dispatch( facebookActions.setRegistrationError( undefined ) );
  }, [ facebookState.registrationSuccess ] );

  if ( !facebookState || !facebookState.userData ) return null;

  const initialValues = {
    name: facebookState.userData.name,
    gender: facebookState.userData.gender,
    age: facebookState.userData?.birthday
      ? Math.floor(
          -DateTime.fromFormat( facebookState.userData.birthday, 'MM/dd/yyyy' ).diffNow(
            'years'
          ).years
        )
      : 18,
    phone: '',
    otp: '',
    termsAgreed: false,
    emailMarketing: false
  } as Omit<FacebookRegisterData, 'facebookUserId' | 'facebookAccessToken'>;

  const sendOTP = async ( phone: string ) => {
    const sent = await registrationService.sendOTP( phone, 'register' );
    setOTPSent( !!sent );
  };

  return (
    <>
      <ScreenTitle title='Sign Up' />
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          if ( facebookState.authData ) {
            registrationService
              .registerWithFacebook( {
                ...values,
                ...facebookState.authData
              } )
              .then( response => {
                if ( response ) authService.processTokensPair( response );
              } );
          }
        }}
        validationSchema={facebookRegistrationValidationSchema}
        validateOnMount
      >
        {( { values, errors, handleSubmit } ) => (
          <Form onSubmit={handleSubmit}>
            <TextField
              name='name'
              title='Name'
              placeholder='Name'
            />
            {facebookState.userData && !facebookState.userData.birthday && (
              <TextField
                name='age'
                title='Age'
                placeholder='Age'
                type='number'
                min={18}
                max={120}
              />
            )}
            {facebookState.userData && !facebookState.userData.gender && (
              <DropDownField
                name='gender'
                title='Gender'
                placeholder='Gender'
                options={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                  { label: 'Other', value: 'other' }
                ]}
              />
            )}
            <PhoneField
              name='phone'
              title='Phone Number'
              action={
                !errors.phone && !otpSent
                  ? {
                      label: 'Send OTP',
                      onClick: sendOTP
                    }
                  : undefined
              }
            />
            <OTPField
              name='otp'
              title='Fill your OTP code below'
              action={
                !errors.phone && otpSent
                  ? {
                      label: 'Did not get a code?',
                      onClick: sendOTP.bind( null, values.phone ),
                      buttonText: 'Resend OTP',
                      lastSend: DateTime.now().plus( { seconds: 60 } )
                    }
                  : undefined
              }
              disabled={!otpSent}
            />
            <CheckBoxField
              name='termsAgreed'
              label={
                <p>
                  I agree to Crewww’s{' '}
                  <a
                    href={routesPaths.terms}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Terms and Conditions
                  </a>
                  , and agree to being over 18 years of age
                </p>
              }
            />
            <CheckBoxField
              name='emailMarketing'
              label='I want to receive email marketing communications from Crewww'
            />
            <Submit text='Submit' />
          </Form>
        )}
      </Formik>
    </>
  );
};
