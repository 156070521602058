import { RiFacebookFill, RiGoogleFill, RiPhoneLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { routesPaths } from '../../../navigation';
import { facebookService } from '../../../services/facebookService';
import { googleService } from '../../../services/google';
import { IconButton } from '../../controls/IconButton';
import styles from './OnboardScreen.module.sass';

export const OnboardSocials = () => {
  const navigate = useNavigate();
  const goRegister = () => navigate( routesPaths.register );

  const goFacebookRegister = () =>
    navigate(
      [
        routesPaths.socials.index,
        routesPaths.socials.facebook.index,
        routesPaths.socials.facebook.register,
      ].join( '/' )
    );

  const goGoogleRegister = () =>
    navigate(
      [
        routesPaths.socials.index,
        routesPaths.socials.google.index,
        routesPaths.socials.google.register,
      ].join( '/' )
    );

  const onFacebookClick = () => {
    facebookService.login().then( loginSuccess => {
      if ( loginSuccess ) {
        facebookService.getUserData().then( () => goFacebookRegister() );
      }
    } );
  };

  const onGoogleClick = () => {
    googleService.login().then( () => {
      goGoogleRegister();
    } );
  };

  return (
    <div
      className={styles['onboard-socials']}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <p>Sign up with</p>
      <div>
        <IconButton
          icon={RiFacebookFill}
          onClick={onFacebookClick}
        />
        <IconButton
          icon={RiGoogleFill}
          onClick={onGoogleClick}
        />
        <IconButton
          icon={RiPhoneLine}
          onClick={goRegister}
        />
      </div>
    </div>
  );
};
