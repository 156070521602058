import { CSSProperties, MouseEventHandler, useMemo, useRef } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import ReactModal from 'react-modal';
import { useImageView } from '../../../../hooks/imageView';
import styles from './ImageFullScreenView.module.sass';


export const ImageFullScreenView = () => {

  const backgroundRef = useRef<HTMLDivElement>( null );

  const { currentImage, closeImageView } = useImageView();

  const imageUrl = useMemo( () => {
    if ( currentImage ) {
      if ( currentImage.type === 'app' ) return currentImage.media.url;
      if ( currentImage.type === 'instagram' ) return currentImage.media.media_url;
    }
  }, [ currentImage ] );

  const onBackgroundClick : MouseEventHandler<HTMLDivElement> = event => {
    if ( event.target === backgroundRef.current ) {
      closeImageView();
    }
  };
  
  return (
    <ReactModal
      isOpen={!!imageUrl}
      overlayClassName={styles['overlay']}
      style={{ content: contentStyle }}
      
    >
      <div className={styles['image-view']} onClick={onBackgroundClick} ref={backgroundRef}>
        <div className={styles['close']} onClick={closeImageView}><RiCloseLine /></div>
        <img src={imageUrl} className={styles['image']} />
      </div>
    </ReactModal>
  );
};

const contentStyle = {
  position: 'initial',
  top: 'initial',
  left: 'initial',
  right: 'initial',
  bottom: 'initial',
  border: 'initial',
  background: 'initial',
  overflow: 'initial',
  borderRadius: 'initial',
  outline: 'initial',
  padding: 'initial',
  height: '100%'
} as CSSProperties;