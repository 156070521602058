import Color from 'color';
import React from 'react';

export const getCssGradientObject = ( hexColor: string ) => {
  const color = Color( hexColor );
  const lighterColor = color.lighten( 0.15 );
  const darkerColor = color.darken( 0.25 );
  return {
    '--gradient': `linear-gradient(136.02deg, ${lighterColor} -8.03%, ${darkerColor} 104.69%)`,
    '--gradientSemiTransparent': `linear-gradient(136.02deg, 
          ${lighterColor.opaquer( -0.4 )} -8.03%, 
          ${darkerColor.opaquer( -0.4 )} 104.69%)`,
    '--gradientTransparent': `linear-gradient(136.02deg, 
        ${lighterColor.alpha( 0.2 )} -8.03%, 
        ${darkerColor.alpha( 0.2 )} 104.69%)`
  } as React.CSSProperties;
};

export const getInviteCardGradient = ( hexColor: string ) => {
  const color = Color( hexColor );
  const lighterColor = color.lighten( 0.1 );
  const darkerColor = color.lighten( 0.09 );
  return `linear-gradient(133.56deg, ${darkerColor} 1.88%, ${lighterColor} 103.65%)`;
};

export const byocGradient = {
  background: 'linear-gradient(134.72deg, #C3EBF3 0%, #7A4DFA 101.5%)'
};
