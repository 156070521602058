import { useState } from 'react';
import { Button, ButtonProps } from '../../controls';
import { Modal } from '../../modals';

export type ButtonWithConfirmationProps = {
  buttonText: string | JSX.Element;
  modalText: string | JSX.Element;
  modalTitle: string;
  cancel?: {
    label?: string;
    onClick?: () => void;
  };
  confirm?: {
    label?: string;
    onClick?: () => void;
  };
  buttonProps?: Pick<ButtonProps, 'style' | 'disabled' | 'styles'>;
};

export const ButtonWithConfirmation = ( {
  buttonText,
  modalText,
  cancel,
  confirm,
  modalTitle,
  buttonProps
}: ButtonWithConfirmationProps ) => {
  const [ isOpen, setOpen ] = useState( false );

  const open = () => setOpen( true );
  const close = () => setOpen( false );

  return (
    <>
      <Button
        text={buttonText}
        onClick={open}
        {...buttonProps}
      />
      <Modal
        isOpen={isOpen}
        cancel={{ label: cancel?.label || 'Cancel', onClick: cancel?.onClick || close }}
        confirm={{
          label: confirm?.label || 'Cancel',
          onClick: confirm?.onClick || close
        }}
        onClose={close}
        title={modalTitle}
      >
        {modalText}
      </Modal>
    </>
  );
};
