import axios from 'axios';
import { Event, EventCreationFormFields, ServerResponse } from '.';
import { baseUrl } from './baseInstances';
import { mainApi } from './mainApi';
import { EventPreview, EventSingle } from './models';
import { EventPreviewTokenResponse, UpdateByocFields } from './types';

export const eventsService = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getEvents: build.query<Event[], { sort?: string; lat?: number; lng?: number }>( {
      query: params => ( { url: '/events', method: 'GET', params } ),
      providesTags: [ 'eventsAll' ]
    } ),
    createEvent: build.mutation<
      ServerResponse & { _id: string },
      EventCreationFormFields
    >( {
      query: event => ( { url: '/events', method: 'POST', data: event } ),
      invalidatesTags: result =>
        result && result.success ? [ 'eventsAll', 'created' ] : []
    } ),
    updateEvent: build.mutation<
      ServerResponse,
      { id: string; eventData: EventCreationFormFields }
    >( {
      query: arg => ( {
        url: `/events/${arg.id}`,
        method: 'PUT',
        data: arg.eventData
      } ),
      invalidatesTags: ( result, err, arg ) =>
        result && result.success
          ? [ 'eventsAll', 'created', { type: 'event', id: arg.id } ]
          : []
    } ),
    deleteEvent: build.mutation<ServerResponse, string>( {
      query: id => ( { url: `/events/${id}`, method: 'DELETE' } ),
      invalidatesTags: result => ( result ? [ 'eventsAll', 'created', 'joined' ] : [] )
    } ),
    getBannerColors: build.query<{ color: string }[], void>( {
      query: () => ( { url: '/bannerColors', method: 'GET' } )
    } ),
    getEvent: build.query<EventSingle, string>( {
      query: id => ( { url: `/events/${id}`, method: 'GET' } ),
      providesTags: ( result, error, arg ) => [ { type: 'event', id: arg } ]
    } ),
    joinEvent: build.mutation<ServerResponse, string>( {
      query: id => ( { url: `/events/${id}/join`, method: 'PUT' } ),
      invalidatesTags: ( result, error, arg ) =>
        result && result.success
          ? [ { type: 'event', id: arg }, 'eventsAll', 'joined' ]
          : []
    } ),
    getEventChatLink: build.query<{ regular: string; prefilled: string }, string>( {
      query: id => ( { url: `/events/${id}/chatLink`, method: 'GET' } ),
      providesTags: ( result, error, arg ) => [ { type: 'event', id: arg } ]
    } ),
    getUserCreatedEvents: build.query<Event[], string>( {
      query: id => ( { url: `/events?author=${id}`, method: 'GET' } ),
      providesTags: [ 'created' ]
    } ),
    getCurrentUserJoinedEvents: build.query<Event[], void>( {
      query: () => ( { url: '/events/joined', method: 'GET' } ),
      providesTags: [ 'joined' ]
    } ),
    getEventPreviewToken: build.query<EventPreviewTokenResponse, string>( {
      query: id => ( {
        url: `/events/${id}/previewToken`,
        method: 'GET'
      } ),
      providesTags: ( res, err, arg ) =>
        err
          ? []
          : [
              {
                type: 'previewToken',
                id: arg
              }
            ]
    } ),
    getPastEvents: build.query<{ events: Event[]; total: number }, { limit: number }>( {
      query: ( { limit } ) => ( {
        url: '/events/past',
        params: { limit }
      } ),
      providesTags: [ 'pastEvents' ]
    } ),
    writeReview: build.mutation<ServerResponse, { id: string; text: string }>( {
      query: ( { id, text } ) => ( {
        url: `/events/${id}/review`,
        method: 'post',
        data: { text }
      } ),
      invalidatesTags: ( res, err, { id } ) => ( err ? [] : [ { type: 'event', id } ] )
    } ),
    updateReview: build.mutation<ServerResponse, { id: string; text: string }>( {
      query: ( { id, text } ) => ( {
        url: `/events/${id}/review`,
        method: 'put',
        data: { text }
      } ),
      invalidatesTags: ( res, err, { id } ) => ( err ? [] : [ { type: 'event', id } ] )
    } ),
    leaveEvent: build.mutation<ServerResponse, string>( {
      query: id => ( {
        url: `/events/${id}/leave`,
        method: 'post'
      } ),
      invalidatesTags: ( res, err, id ) => ( err ? [] : [ { type: 'event', id }, 'eventsAll' ] )
    } ),
    removeEventMember: build.mutation<
      ServerResponse,
      { eventId: string; memberId: string; reason: string }
    >( {
      query: ( { eventId, memberId, reason } ) => ( {
        url: `/events/${eventId}/members/${memberId}`,
        method: 'delete',
        data: { reason }
      } ),
      invalidatesTags: ( res, err, { eventId } ) =>
        err ? [] : [ { type: 'event', id: eventId }, 'eventsAll' ]
    } ),
    joinByoc: build.mutation<void, { members: string[]; id: string }>( {
      query: ( { id, ...data } ) => ( {
        url: `/events/${id}/byoc/join`,
        data,
        method: 'post'
      } ),
      invalidatesTags: ( res, err, { id } ) =>
        err ? [] : [ { type: 'event', id }, 'eventsAll' ]
    } ),
    updateByoc: build.mutation<void, UpdateByocFields & { id: string }>( {
      query: ( { id, ...data } ) => ( {
        url: `/events/${id}/byoc`,
        data,
        method: 'put'
      } ),
      invalidatesTags: ( res, err, { id } ) =>
        err ? [] : [ { type: 'event', id }, 'eventsAll' ]
    } )
  } )
} );

export const getEventPreview = async ( token: string ) => {
  try {
    const { status, data } = await axios.post( `${baseUrl}/events/preview`, { token } );
    return status === 200
      ? {
          ...( data as EventPreview ),
          start: new Date( ( data as EventPreview ).start )
        }
      : ( data as ServerResponse );
  } catch ( err ) {
    console.log( 'ERR', err );
  }
};

export const {
  useGetEventsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useGetBannerColorsQuery,
  useGetEventQuery,
  useGetEventChatLinkQuery,
  useJoinEventMutation,
  useGetCurrentUserJoinedEventsQuery,
  useGetUserCreatedEventsQuery,
  useGetEventPreviewTokenQuery,
  useGetPastEventsQuery,
  useWriteReviewMutation,
  useUpdateReviewMutation,
  useLeaveEventMutation,
  useRemoveEventMemberMutation,
  useLazyGetEventQuery,
  useJoinByocMutation,
  useUpdateByocMutation
} = eventsService;
