import { skipToken } from '@reduxjs/toolkit/dist/query';
import '../../../assets/css/components/ui/views/ChatLink.css';
import { ReactComponent as Visibility } from '../../../assets/img/icons/password-visible.svg';
import { isServerResponse } from '../../../services';
import { useGetCurrentUserInfoQuery } from '../../../services/currentUserApi';
import {
  useGetEventChatLinkQuery,
  useGetEventQuery
} from '../../../services/eventsService';

export type ChatLinkProps = {
  eventId?: string;
  creation?: boolean;
  creatorPhone?: string;
};

export const ChatLink = ( { creation, creatorPhone, eventId }: ChatLinkProps ) => {
  const chatLink = useGetEventChatLinkQuery( eventId || skipToken );
  const currentUser = useGetCurrentUserInfoQuery();
  const event = useGetEventQuery( eventId || skipToken );

  return (
    <div className='chat-link'>
      <div className='title'>Chat</div>
      {creation ? (
        <span>(Users who join will contact you via whatsapp)</span>
      ) : chatLink.data ? (
        event.data &&
        currentUser.data &&
        event.data.author._id === currentUser.data._id ? (
          <span>(Members who join will contact you via whatsapp to co-ordinate)</span>
        ) : (
          <span>
            Please contact the event creator to co-ordinate using the whatsapp link below,
            or use the phone number within the link
          </span>
        )
      ) : (
        <span className='dark'>Join to receive link to chat</span>
      )}
      {creation ? (
        <a
          target='_blank'
          href={`https://wa.me/${creatorPhone}`}
          rel='noreferrer'
        >
          <Visibility />
          https://wa.me/{creatorPhone}
        </a>
      ) : (
        chatLink.data &&
        !isServerResponse( chatLink.data ) && (
          <>
            <a
              target='_blank'
              href={chatLink.data.regular}
              rel='noreferrer'
            >
              <Visibility />
              {chatLink.data.regular}
            </a>
            <a
              href={chatLink.data.regular}
              target={'_blank'}
              className='button'
              rel='noreferrer'
              style={{ marginBottom: 0 }}
            >
              {chatLink.data.regular}
            </a>
          </>
        )
      )}
    </div>
  );
};
