import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import axios from 'axios';
import { InstagramProfileResponse, InstagramRequestParamsInternal, InstagramProfileRequestFields, InstagramMedia, InstagramMediaRequestFields } from './types';

export const instagramBaseUrl = 'https://graph.instagram.com';

export const instagramApi = createApi( {
  baseQuery: fetchBaseQuery( { baseUrl: instagramBaseUrl } ),
  reducerPath: 'instagramApi',
  tagTypes: [ 'profile', 'media' ],
  endpoints: build => ( {
    getInstagramProfile: build.query<InstagramProfileResponse, InstagramRequestParamsInternal<InstagramProfileRequestFields>>( {
      query: ( { accessToken, fields, id='me' } ) => ( {
        url: `/${id}`,
        params: {
          access_token: accessToken,
          fields: fields.join( ',' )
        }
      } ),
      providesTags: ( res, err ) => err ? [] : [ 'profile' ]
    } ),
    getInstagramMedia: build.query<InstagramMedia, InstagramRequestParamsInternal<InstagramMediaRequestFields> & {id: string}>( {
      query: ( { accessToken, fields, id } ) => ( {
        url: `/${id}`,
        params: {
          access_token: accessToken,
          fields: fields.join( ',' )
        }
      } ),
      providesTags: ( res, err, { id } ) => err ? [] : [ { type: 'media', id } ]
    } ),
    getInstagramMediasByNextUrl: build.query<InstagramProfileResponse['media'], string>( {
      queryFn: async ( url ) => {
        const response = await axios.get( url );
        if ( response.status === 200 ) return { data: response.data as Required<InstagramProfileResponse>['media'] };
        else return { error: {
          error: response.statusText,
          originalStatus: response.status,
          status: response.status,
          data: response.data
        } as FetchBaseQueryError };
      },
      providesTags: ( res, err, url ) => err ? [] : [ { type: 'profile', id: url } ]
    } )
  } )
} );

export const {
  useGetInstagramMediaQuery,
  useGetInstagramProfileQuery,
  useLazyGetInstagramMediaQuery,
  useLazyGetInstagramMediasByNextUrlQuery
} = instagramApi;