import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { ReactComponent as InvitedIcon } from '../../../../../../assets/img/icons/invited.svg';
import { ReactComponent as SearchIcon } from '../../../../../../assets/img/icons/search.svg';
import { ReactComponent as WarningIcon } from '../../../../../../assets/img/icons/warning.svg';
import { EventCreationFormFields, GetUsersResponse } from '../../../../../../services';
import { useGetCurrentUserInfoQuery } from '../../../../../../services/currentUserApi';
import { useLazyGetUsersQuery } from '../../../../../../services/usersApi';
import { Button } from '../../../../../controls';
import { SearchUser } from '../SearchUser';
import styles from './CrewSearch.module.scss';

export interface CrewSearchProps {
  onUserAdd?: ( user: GetUsersResponse[0] ) => void;
}

export const CrewSearch = ( { onUserAdd }: CrewSearchProps ) => {
  const [ getUsers, { isSuccess: isUsersFetched } ] = useLazyGetUsersQuery();
  const { data: currentUser } = useGetCurrentUserInfoQuery();

  const { values, setFieldValue } = useFormikContext<EventCreationFormFields>();

  const byoc = values.byoc;
  const targetGender = byoc?.authorCrewGender;

  const [ search, setSearch ] = useState( '' );
  const [ foundUsers, setFoundUsers ] = useState<GetUsersResponse>();

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setSearch( e.target.value );
  };

  const searchUsers = useCallback(
    debounce( 300, async ( searchTerm: string ) => {
      if ( searchTerm.length < 3 || !targetGender ) return;
      const isPhone = /^\+?\d+$/.test( searchTerm );
      if ( isPhone && searchTerm.length < 9 ) return;
      const { data: users } = await getUsers(
        isPhone
          ? { phone: searchTerm.replace( '+', '' ), gender: targetGender }
          : { name: searchTerm, gender: targetGender }
      );
      if ( users ) setFoundUsers( users.filter( user => user._id !== currentUser?._id ) );
    } ),
    [ setFoundUsers, currentUser, targetGender ]
  );

  useEffect( () => {
    searchUsers( search );
  }, [ search ] );

  const onAdd = ( user: GetUsersResponse[0] ) => () => {
    if ( byoc ) {
      setFieldValue( 'byoc', {
        ...byoc,
        invites: {
          ...byoc.invites,
          registered: byoc.invites.registered.concat( user._id )
        }
      } as EventCreationFormFields['byoc'] );
      if ( onUserAdd ) onUserAdd( user );
    }
  };

  const isPhoneInvited = useMemo( () => {
    return byoc?.invites.notRegistered.includes( search.replace( '+', '' ) );
  }, [ search, byoc ] );

  const invitePhone = ( phone: string ) => () => {
    if ( byoc ) {
      setFieldValue( 'byoc', {
        ...byoc,
        invites: {
          ...byoc.invites,
          notRegistered: byoc.invites.notRegistered.concat( phone.replace( '+', '' ) )
        }
      } as EventCreationFormFields['byoc'] );
    }
  };

  const isFetchedByPhone = useMemo( () => {
    if ( !isUsersFetched ) return false;
    const isPhone = /^\+?\d+$/.test( search );
    return isPhone;
  }, [ isUsersFetched, search ] );

  return (
    <div className={styles['crew-search']}>
      <h4>Search and add your crew</h4>
      <div className={styles['search']}>
        <SearchIcon />
        <input
          placeholder='Search for user name or add by number'
          value={search}
          onChange={onInputChange}
        />
      </div>
      {!!foundUsers?.length && (
        <ul className={styles['found-users']}>
          {foundUsers.map( ( user, index ) => (
            <li key={index}>
              <SearchUser
                user={user}
                onAdd={onAdd( user )}
                isAdded={values.byoc?.invites.registered.includes( user._id )}
              />
            </li>
          ) )}
        </ul>
      )}
      {foundUsers?.length === 0 && isFetchedByPhone && (
        <div className={styles['invite-phone']}>
          <p>Seems like they are not on Crewww yet ....</p>
          <Button
            text={
              isPhoneInvited ? (
                <>
                  <InvitedIcon />
                  Invited
                </>
              ) : (
                'Invite'
              )
            }
            onClick={invitePhone( search )}
            style={isPhoneInvited ? 'secondary' : undefined}
            className={[
              styles['invite-button'],
              isPhoneInvited && styles['invited']
            ].join( ' ' )}
          />
          {isPhoneInvited && (
            <div className={styles['invited-note']}>
              <WarningIcon />
              <div>
                <h4>Make sure your crew signs up!</h4>
                <p>
                  Before the invited crew signs up, your event will be pending and other
                  users won’t be able to see your event.
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
