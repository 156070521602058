import { CSSProperties, useState } from 'react';
import { UserMediaExpanded, UserPreview } from '../../../../services';
import styles from './Avatar.module.sass';

export interface AvatarProps {
  user?: Pick<UserMediaExpanded, 'name' | 'media'> | UserPreview;
  style?: CSSProperties;
  imgStyle?: CSSProperties;
  className?: string;
}

export const Avatar = ( { user, style, imgStyle, className }: AvatarProps ) => {
  const [ isImgError, setImgError ] = useState( false );
  const toggleImgError = ( value: boolean ) => () => setImgError( value );

  if ( !user ) return null;

  return (
    <div
      className={[ styles['avatar'], className ].join( ' ' )}
      style={style}
    >
      {user.media?.length && !isImgError ? (
        <img
          src={user.media[0]?.url}
          alt={user.name}
          onError={toggleImgError( true )}
          style={imgStyle}
        />
      ) : (
        <div className={styles['no-image']}>{user.name[0]}</div>
      )}
    </div>
  );
};
