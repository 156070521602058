import { NavLink } from 'react-router-dom';
import '../../../assets/css/components/ui/global/BottomNavigation.css';
import { ReactComponent as Home } from '../../../assets/img/icons/home.svg';
import { ReactComponent as User } from '../../../assets/img/icons/user.svg';
import { routesPaths } from '../../../navigation';

export const BottomNavigation = () => {
  return (
    <div className='bottom-navigation'>
      {/* <div className="main-button"></div> */}
      <NavLink
        to={[ routesPaths.event.index, routesPaths.event.creation ].join( '/' )}
        className={'main-button'}
      >
        {null}
      </NavLink>
      <NavLink
        end
        to={routesPaths.events.index}
        className={[ 'nav-button', 'home' ].join( ' ' )}
      >
        <Home />
      </NavLink>
      <NavLink
        end
        to={routesPaths.settings.index}
        className={[ 'nav-button', 'profile' ].join( ' ' )}
      >
        <User />
      </NavLink>
    </div>
  );
};
