import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GoogleSliceState } from './types';



const initialState : GoogleSliceState = {
  code: undefined,
  registrationSuccess: undefined
};

export const googleSlice = createSlice( {
  name: 'googleSlice',
  initialState,
  reducers: {
    setCode: ( state, action: PayloadAction<string | undefined> ) => {
      state.code = action.payload;
    },
    setRegistrationSuccess: ( state, action: PayloadAction<boolean | undefined> ) => {
      state.registrationSuccess = action.payload;
    }
  }
} );

export const googleActions = googleSlice.actions;