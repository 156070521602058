import { useState } from 'react';
import { EventSingle, GetUsersResponse } from '../../../../../services';
import { useJoinByocMutation } from '../../../../../services/eventsService';
import { Button } from '../../../../controls';
import { ScreenTitle } from '../../../../ui';
import { Crew } from '../Crew';
import { CrewSearch } from '../CrewSearch';
import styles from './JoinByocModal.module.scss';

export interface JoinByocModal {
  event: EventSingle;
  close: () => void;
  editing?: {
    confirm: {
      label: string;
      onClick: ( users: GetUsersResponse[0][] ) => void;
    };
    isAdding?: boolean;
    members: GetUsersResponse[0][];
    exclude?: string[];
  };
}

export const JoinByocModal = ( { event, close, editing }: JoinByocModal ) => {
  const [ joinEvent ] = useJoinByocMutation();

  const [ addedUsers, setAddedUsers ] = useState<GetUsersResponse[0][]>(
    editing ? editing.members.filter( user => user._id !== event.author._id ) : []
  );

  const onAddUser = ( user: GetUsersResponse[0] ) => {
    if ( !addedUsers.find( addedUser => addedUser._id === user._id ) )
      setAddedUsers( [ ...addedUsers, user ] );
  };

  const onRemoveUser = ( id: string ) => {
    setAddedUsers( addedUsers.filter( user => user._id !== id ) );
  };

  const onJoin = () => {
    joinEvent( {
      id: event._id,
      members: addedUsers.map( user => user._id )
    } ).then( () => {
      close();
    } );
  };

  if ( !event.byoc ) return null;

  return (
    <div className={styles['join-byoc']}>
      <ScreenTitle
        title='Join Event'
        onBack={close}
      />
      <Crew
        crewSize={
          ( editing?.members.length || event.maxMembers ) + ( editing?.isAdding ? 1 : 0 )
        }
        users={addedUsers}
        onUserRemove={onRemoveUser}
      />
      <CrewSearch
        addedUsers={addedUsers.map( user => user._id )}
        onUserAdd={onAddUser}
        targetGender={event.byoc.targetCrewGender}
        max={event.maxMembers - 1 + ( editing?.isAdding ? 1 : 0 )}
        members={( editing?.members || event.byoc.authorCrew.members ).map(
          user => user._id
        )}
        exclude={editing?.exclude}
      />
      <Button
        text={editing?.confirm.label || 'Join'}
        disabled={
          addedUsers.length !== event.maxMembers - 1 + ( editing?.isAdding ? 1 : 0 )
        }
        className={styles['join-button']}
        onClick={editing?.confirm.onClick?.bind( null, addedUsers ) || onJoin}
      />
    </div>
  );
};
