import '../../assets/css/components/controls/TextArea.css';

export interface TextAreaProps {
  name: string;
  value: string;
  onChange: ( value: string ) => void;
  maxCharacters?: number;
  placeholder?: string;
  title?: string;
  rows?: number;
  className?: string;
}

export const TextArea = ( {
  name,
  value,
  onChange,
  maxCharacters,
  placeholder,
  title,
  rows = 6,
  className
}: TextAreaProps ) => {
  const inputOnChange = ( e: React.ChangeEvent<HTMLTextAreaElement> ) => {
    onChange( e.target.value );
  };

  return (
    <div className={[ 'textarea', className ].join( ' ' )}>
      {title && <label>{title}</label>}
      <textarea
        name={name}
        id={name}
        rows={rows}
        placeholder={placeholder}
        maxLength={maxCharacters}
        spellCheck={false}
        onChange={inputOnChange}
        value={value}
      />
      {maxCharacters && (
        <span className='max-length'>{maxCharacters - value.length}</span>
      )}
    </div>
  );
};
