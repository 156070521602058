import {
  ChangePasswordFormFields,
  CurrentUser,
  EventCreationFormFields,
  EventSingle,
  Media,
  ProfileEditFormFields,
  RegisterFormFields,
  RestorePasswordFormFields
} from '../services';

export const getProfileEditValues = (
  user: CurrentUser | undefined,
  userMedia: Media[] | undefined
) =>
  ( {
    name: user?.name || '',
    about: user?.about || '',
    age: user?.age || 18,
    alcohol: user?.alcohol || { enabled: false, visible: true },
    smoking: user?.smoking || { enabled: false, visible: true },
    education: user?.education || '',
    features: {
      upload: [],
      deleted: [],
      selected: user?.features.map( tag => tag._id ) || []
    },
    interests: {
      upload: [],
      deleted: [],
      selected: user?.interests.map( tag => tag._id ) || []
    },
    job: user?.job || '',
    location: user?.location || '',
    images: { added: [], deleted: [], default: userMedia || [] },
    birthDate: user?.birthDate?.date
  } as ProfileEditFormFields );

export const getEventCreationInitialValues = ( event?: EventSingle, ignoreDate = false ) =>
  ( {
    emoji: event?.emoji || '',
    title: event?.title || '',
    bannerColor: event?.bannerColor || '',
    description: event?.description || '',
    maxMembers: event?.maxMembers || 0,
    location: event?.location || {
      lat: NaN,
      lng: NaN,
      title: ''
    },
    start: ignoreDate ? undefined : event?.start || new Date(),
    byoc: event?.byoc
      ? {
          targetCrewGender: event.byoc.targetCrewGender
        }
      : undefined
  } as EventCreationFormFields );

export const getRegistrationInitialValues = () =>
  ( {
    name: '',
    gender: '',
    otp: '',
    phone: '',
    emailMarketing: false,
    termsAgreed: false,
    password: '',
    confirmPassword: ''
  } as RegisterFormFields );

export const getLoginInitialValues = () => ( { phone: '', password: '' } );

export const getRestorePasswordInitialValues = () =>
  ( {
    phone: '',
    otp: '',
    newPassword: '',
    confirmPassword: ''
  } as RestorePasswordFormFields );

export const getChangePasswordInitialValues = () =>
  ( {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  } as ChangePasswordFormFields );
