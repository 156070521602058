import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from './baseInstances';

export const mainApi = createApi( {
  baseQuery: axiosBaseQuery,
  reducerPath: 'mainApi',
  tagTypes: [
    'info',
    'features',
    'interests',
    'event',
    'eventsAll',
    'joined',
    'created',
    'previewToken',
    'pastEvents',
    'invites',
    'Media'
  ],
  endpoints: () => ( {} )
} );
