import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Media } from '../../services';
import { InstagramMedia, isInstagramMedia } from '../../services/instagram';
import { ImageViewSliceState } from './types';


export const imageViewSlice = createSlice( {
  initialState: {} as ImageViewSliceState,
  name: 'imageView',
  reducers: {
    setImage: ( state, action: PayloadAction<Media | InstagramMedia | undefined> ) => {
      if ( action.payload === undefined ) {
        state.image = undefined;
      }
      else {
        if ( isInstagramMedia( action.payload ) ) {
          state.image = {
            media: action.payload,
            type: 'instagram'
          };
        } else {
          state.image = {
            media: action.payload,
            type: 'app'
          };
        }
      }
    }
  }
} );

export const imageViewActions = imageViewSlice.actions;