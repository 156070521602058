import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as FemaleIcon } from '../../../../assets/img/icons/female.svg';
import { ReactComponent as MaleIcon } from '../../../../assets/img/icons/male.svg';
import { Event, User } from '../../../../services';
import { Toggle } from '../../../controls';
import styles from '../EventCreationScreen.module.scss';

export interface GenderFilterFieldProps {
  gender: User['gender'];
}

export const GenderFilterField = ( { gender }: GenderFilterFieldProps ) => {
  const { values, setFieldValue } = useFormikContext();
  const filters = ( values as Record<string, Event['filters']> )['filters'];

  const [ isEnabled, setEnabled ] = useState( !!filters?.gender );

  useEffect( () => {
    setFieldValue( 'filters', {
      ...filters,
      gender: isEnabled
    } as Event['filters'] );
  }, [ isEnabled ] );

  const icon = useMemo( () => {
    if ( gender === 'other' ) return null;
    return gender === 'female' ? <FemaleIcon /> : <MaleIcon />;
  }, [ gender ] );

  return (
    <div className={styles['filter']}>
      <div className={styles['toggle-container']}>
        <Toggle
          onToggle={setEnabled}
          defaultValue={isEnabled}
          className={styles['toggle']}
          labels={null}
        />
        <span>{isEnabled ? <>Same gender only {icon}</> : 'Set gender filter'}</span>
      </div>
    </div>
  );
};
