import { useFormikContext } from 'formik';
import '../../assets/css/components/controls/ColorPicker.css';
import { getCssGradientObject } from '../../utils';


export interface ColorPickerFieldProps {
  colors: string[],
  label: string,
  name: string
}


export const ColorPickerField = ( { colors, label, name } : ColorPickerFieldProps ) => {

  const { setFieldValue } = useFormikContext();
  
  const onColorClick = ( color: string ) => () => {
    setFieldValue( name, color );
  };
  
  return (
    <div className="color-picker">
      <p>{label}</p>
      <div className="colors">
        {colors.map( color => (
          <div
            key={color}
            className="color" 
            style={getCssGradientObject( color )}
            onClick={onColorClick( color )}
          />
        ) )}
      </div>
    </div>
  );
};