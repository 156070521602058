import SwiperCore, { Navigation } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../assets/css/components/ui/views/ProfileGallery.css';
import { useImageView } from '../../../hooks/imageView';
import { Media } from '../../../services';

export interface ProfileGalleryProps {
  medias: Media[]
}

SwiperCore.use( [ Navigation ] );

export const ProfileGallery = ( { medias } : ProfileGalleryProps ) => {
  
  const { openMedia } = useImageView();

  const onImageClick = ( media: Media ) => () => openMedia( media );

  return medias.length ? (
    <Swiper
      slidesPerView={1}
      className='profile-slider'
      spaceBetween={50}
      navigation={true}
    >
      {medias.map( media => (
        <SwiperSlide key={media._id}>
          <img src={media.url} onClick={onImageClick( media )} />
        </SwiperSlide>
      ) )}
    </Swiper>
  ) : null;
};