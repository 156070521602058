import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../../assets/img/icons/back-arrow.svg';
import { EventCreationFormFields } from '../../../../services';
import { useGetCurrentUserInfoQuery } from '../../../../services/currentUserApi';
import styles from '../EventCreationScreen.module.scss';

export interface EventTypeHeaderProps {
  onBack?: () => void;
}

export const EventTypeHeader = ( { onBack }: EventTypeHeaderProps ) => {
  const navigate = useNavigate();

  const { data: currentUser } = useGetCurrentUserInfoQuery();

  const { setFieldValue, values } = useFormikContext<EventCreationFormFields>();

  const setTypePlain = () => {
    setFieldValue( 'byoc', undefined );
  };

  const setTypeBYOC = () => {
    setFieldValue( 'byoc', {
      invites: {
        notRegistered: [],
        registered: []
      },
      targetCrewGender: currentUser?.gender,
      authorCrewGender: currentUser?.gender
    } as EventCreationFormFields['byoc'] );
  };

  const goBack = () => navigate( -1 );

  if ( !currentUser ) return null;

  return (
    <div className={styles['event-type']}>
      <div
        className={styles['back']}
        onClick={onBack || goBack}
      >
        <BackIcon />
      </div>
      <span
        onClick={setTypePlain}
        className={values.byoc ? undefined : styles['current']}
      >
        Event
      </span>
      <span
        onClick={setTypeBYOC}
        className={values.byoc ? styles['current'] : undefined}
      >
        BYOC
      </span>
    </div>
  );
};
