import { ReactNode } from 'react';
import { CheckBox, CheckBoxProps } from './CheckBox';
import '../../assets/css/components/controls/CheckBoxLabeled.css';

export type CheckBoxLabeledProps = CheckBoxProps & { label: string | ReactNode };

export const CheckBoxLabeled = ( 
  { checked, name, onChange, label } : CheckBoxLabeledProps 
) => (
  <div className="checkbox-labeled">
    <CheckBox name={name} checked={checked} onChange={onChange} />
    <label htmlFor={name}>{label}</label>
  </div>
);