import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  EventCreationFormFields,
  EventSingle,
  GetUsersResponse
} from '../../../../services';
import {
  useGetEventQuery,
  useUpdateByocMutation
} from '../../../../services/eventsService';
import { getEventIdFromSignature } from '../../../../utils';
import { Button } from '../../../controls';
import { Submit } from '../../../forms';
import { EventMembersByoc, JoinByocModal } from '../../EventScreen/components';
import { BYOCGender } from './BYOCGender';
import { BYOCInvites } from './BYOCInvites';
import { DefaultFields, DefaultFieldsProps } from './DefaultFields';
import { EventTypeHeader } from './EventTypeHeader';

export interface BYOCEventProps extends DefaultFieldsProps {
  edit?: boolean;
  setNoMargin: ( value: boolean ) => void;
}

export const BYOCEvent = ( {
  edit,
  setNoMargin,
  ...defaultFieldsProps
}: BYOCEventProps ) => {
  const { eventSignature } = useParams();

  const eventId = useMemo( () => {
    if ( eventSignature ) return getEventIdFromSignature( eventSignature );
  }, [ eventSignature ] );

  const { data: event } = useGetEventQuery( eventId || skipToken );
  const [ updateByoc ] = useUpdateByocMutation();

  const [ editingCrew, setEditingCrew ] = useState( false );
  const [ editedMembers, setEditedMembers ] = useState<GetUsersResponse[0][]>(
    event?.byoc?.authorCrew?.members || []
  );
  const [ isAddingUser, setAddingUser ] = useState( false );
  const [ removeJoinedCrew, setRemoveJoinedCrew ] = useState( false );

  const toggleEditingCrew = ( value: boolean ) => () => setEditingCrew( value );
  const toggleRemoveJoinedCrew = ( value: boolean ) => () => setRemoveJoinedCrew( value );

  const openSearch =
    ( add = false ) =>
    () => {
      setEditingCrew( true );
      setAddingUser( add );
    };

  const { errors, setFieldValue } = useFormikContext<EventCreationFormFields>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { byoc, ...defaultErrors } = errors;

  useEffect( () => {
    setNoMargin( editingCrew );
  }, [ editingCrew ] );

  useEffect( () => {
    return () => setNoMargin( false );
  }, [] );

  const canEditGender = useMemo( () => {
    if ( !event ) return false;
    return !event.byoc?.joinedCrew;
  }, [ event ] );

  const updateEditedMembers: typeof setEditedMembers = users => {
    setEditedMembers( users );
    setFieldValue( 'maxMembers', users.length );
  };

  const onSubmit = () => {
    if ( eventId ) {
      updateByoc( {
        id: eventId,
        authorCrewMembers: editedMembers.map( user => user._id ),
        removeJoinedCrew
      } );
    }
  };

  const resetEditedMembers = () => {
    if ( event?.byoc?.authorCrew ) updateEditedMembers( event.byoc.authorCrew.members );
  };

  const closeByocModal = () => {
    resetEditedMembers();
    setEditingCrew( false );
  };

  if ( edit && editingCrew && !!event?.byoc ) {
    return (
      <JoinByocModal
        event={event}
        close={closeByocModal}
        editing={{
          confirm: {
            label: 'Back',
            onClick: users => {
              const members = [
                ( event.byoc as Required<EventSingle>['byoc'] ).authorCrew.author,
                ...users
              ];
              updateEditedMembers( members );
              setEditingCrew( false );
              setAddingUser( false );
            }
          },
          members: editedMembers,
          isAdding: isAddingUser,
          exclude: ( event.byoc as Required<EventSingle>['byoc'] ).joinedCrew?.members.map(
            user => user._id
          )
        }}
      />
    );
  }

  return (
    <>
      {!edit && (
        <EventTypeHeader onBack={editingCrew ? toggleEditingCrew( false ) : undefined} />
      )}
      {editingCrew ? (
        <BYOCInvites />
      ) : (
        <>
          <DefaultFields
            {...defaultFieldsProps}
            edit={edit}
            disabled={{ maxMembers: edit }}
            byoc
          />
          {( !edit || canEditGender ) && <BYOCGender />}
          {edit && event && (
            <EventMembersByoc
              event={event}
              style={{ marginTop: 24 }}
              setUsers={updateEditedMembers}
              editedUsers={editedMembers}
              openCrewSearch={openSearch}
              onRemoveJoinedCrew={toggleRemoveJoinedCrew( true )}
              hideJoined={removeJoinedCrew}
            />
          )}
          {edit ? (
            <Submit
              text='Save'
              styles={{ marginTop: 16, marginBottom: 4 }}
              onClick={onSubmit}
            />
          ) : (
            <Button
              text='Next'
              styles={{ marginTop: 16, marginBottom: 4 }}
              onClick={toggleEditingCrew( true )}
              disabled={!!Object.keys( defaultErrors ).length}
            />
          )}
        </>
      )}
    </>
  );
};
