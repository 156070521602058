import { useEffect } from 'react';
import { useGetCurrentUserInfoQuery, useInstagramAuthMutation } from '../../services/currentUserApi';

export const useInstagramRedirectHandler = () => {

  const currentUser = useGetCurrentUserInfoQuery();
  const [ instagramAuth ] = useInstagramAuthMutation();

  useEffect( () => {
    if ( !currentUser.data ) return;
    const params = new URLSearchParams( window.location.search );
    const code = params.get( 'code' );
    if ( code && !currentUser.data.instagram?.accessToken ) {
      instagramAuth( { code } );
    }
  }, [ window.location.search, currentUser ] );

  return null;
};