import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { Event } from '../../../../services';
import { Toggle } from '../../../controls';
import styles from '../EventCreationScreen.module.scss';
import { AgeScroller } from './AgeScroller';

export const AgeFilterField = () => {
  const { values, setFieldValue, errors } = useFormikContext();
  console.log( { values } );
  const filters = ( values as Record<string, Event['filters']> )['filters'];

  const age = filters?.age;

  const [ min, setMin ] = useState( age?.min || NaN );
  const [ max, setMax ] = useState( age?.max || NaN );

  const [ isEnabled, setEnabled ] = useState( false );

  useEffect( () => {
    if ( isEnabled ) {
      setMin( 18 );
      setMax( 35 );
      setFieldValue( 'filters', {
        ...filters,
        age: {
          min: 18,
          max: 35
        }
      } as Event['filters'] );
    } else {
      setFieldValue(
        'filters',
        filters?.gender ? ( { gender: filters.gender } as Event['filters'] ) : undefined
      );
      setMin( NaN );
      setMax( NaN );
    }
  }, [ isEnabled ] );

  const updateFormMin = debounce( 500, ( value: number ) => {
    setFieldValue( 'filters', {
      ...filters,
      age: {
        ...age,
        min: value
      }
    } as Event['filters'] );
  } );
  const updateFormMax = debounce( 500, ( value: number ) => {
    setFieldValue( 'filters', {
      ...filters,
      age: {
        ...age,
        max: value
      }
    } as Event['filters'] );
  } );

  const onMinChange = ( value: number ) => {
    setMin( value );
    updateFormMin( value );
  };
  const onMaxChange = ( value: number ) => {
    setMax( value );
    updateFormMax( value );
  };

  return (
    <div className={styles['filter']}>
      <div className={styles['toggle-container']}>
        <Toggle
          onToggle={setEnabled}
          defaultValue={isEnabled}
          className={styles['toggle']}
          labels={null}
        />
        <span>{isEnabled ? 'Age filter' : 'Set age filter'}</span>
      </div>
      {isEnabled && (
        <div className={styles['age-controls']}>
          <AgeScroller
            value={min || 0}
            onChange={onMinChange}
            max={max || undefined}
            placeholder='18'
            isError={!!( errors as Record<string, Event['filters']> ).filters?.age?.min}
          />
          <span>–</span>
          <AgeScroller
            value={max || 0}
            onChange={onMaxChange}
            min={min || undefined}
            placeholder='35'
            isError={!!( errors as Record<string, Event['filters']> ).filters?.age?.max}
          />
        </div>
      )}
    </div>
  );
};
