import { EventSingle } from '../../../../services';
import { Review } from '../../../ui';
import '../EventScreen.sass';

export interface EventReviewsProps {
  event: EventSingle;
}

export const EventReviews = ( { event }: EventReviewsProps ) => {
  if ( !event.reviews.length ) return null;

  return (
    <section className='event-reviews'>
      <h1>The crewww said...</h1>
      {event.reviews.map( ( review, index ) => (
        <Review
          review={review}
          key={index}
        />
      ) )}
    </section>
  );
};
