import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/img/logo-text.svg';
import { routes } from '../../../navigation';
import { Button } from '../../controls';
import { WebViewWarningModal } from '../../modals';
import { AboutButton } from './components';
import styles from './OnboardScreen.module.sass';
import { OnboardSocials } from './OnboardSocials';

export const OnboardScreen = () => {
  const navigate = useNavigate();

  const goLogin = () => navigate( routes.auth.login.path );

  return (
    <div className={styles['onboarding']}>
      <div className={styles['onboarding-container']}>
        <Logo className={styles['logo']} />
        <Button
          text='Log In'
          onClick={goLogin}
        />
        <OnboardSocials />
        <AboutButton />
        <WebViewWarningModal />
      </div>
    </div>
  );
};
