import { useState } from 'react';
import { ReactComponent as Visible } from '../../assets/img/icons/password-visible.svg';
import '../../assets/css/components/controls/VisibilityToggle.css';

export interface VisibilityToggleProps {
  defaultValue?: boolean,
  onToggle: ( visible: boolean ) => void
}

export const VisibilityToggle = ( { onToggle, defaultValue=false } : VisibilityToggleProps ) => {
  
  const [ visible, setVisible ] = useState( defaultValue );
  
  const toggleVisibility = () => {
    onToggle( !visible );
    setVisible( !visible );
  };
  
  return (
    <div className={[ 'visibility', visible ? 'visible' : '' ].join( ' ' )} onClick={toggleVisibility}>
        <Visible/>
    </div>
  );
};