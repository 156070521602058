import { useMemo } from 'react';
import { GetUsersResponse } from '../../../../../../services';
import { useGetCurrentUserInfoQuery } from '../../../../../../services/currentUserApi';
import { InvitedUser } from '../InvitedUser';
import styles from './Crew.module.scss';

export interface CrewParams {
  users: GetUsersResponse;
  phones?: string[];
  crewSize: number;
  onUserRemove?: ( id: string ) => void;
  onPhoneRemove?: ( phone: string ) => void;
}

export const Crew = ( {
  users,
  crewSize,
  phones,
  onUserRemove,
  onPhoneRemove
}: CrewParams ) => {
  const { data: currentUser } = useGetCurrentUserInfoQuery();

  const placeholdersCount = useMemo( () => {
    return crewSize - 1 - ( users.length + ( phones?.length || 0 ) );
  }, [ users, phones, crewSize ] );

  const onMemberRemove = ( user: GetUsersResponse[0] ) => () => {
    if ( onUserRemove ) onUserRemove( user._id );
  };

  const onNonRegisteredMemberRemove = ( phone: string ) => () => {
    if ( onPhoneRemove ) onPhoneRemove( phone );
  };

  return (
    <div className={styles['crew']}>
      <h4>
        My Crew ({users.length + 1}/{crewSize})
      </h4>
      <div className={styles['users']}>
        {currentUser && (
          <InvitedUser
            user={{
              id: currentUser._id,
              name: currentUser.name,
              avatar: currentUser.media[0]?.url
            }}
          />
        )}
        {users.map( ( user, index ) => (
          <InvitedUser
            key={index}
            user={{ id: user._id, name: user.name, avatar: user.media[0]?.url }}
            onRemove={onMemberRemove( user )}
          />
        ) )}
        {phones?.map( ( phone, index ) => (
          <InvitedUser
            key={index}
            phone={phone}
            onRemove={onNonRegisteredMemberRemove( phone )}
          />
        ) )}
        {[ ...Array( placeholdersCount ).keys() ].map( index => (
          <div
            className={styles['placeholder']}
            key={index}
          ></div>
        ) )}
      </div>
    </div>
  );
};
