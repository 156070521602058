import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import { useBottomReachHandler, useDocTitle } from '../../../hooks';
import { routesPaths } from '../../../navigation';
import { UserMediaExpanded } from '../../../services';
import { useGetCurrentUserInfoQuery } from '../../../services/currentUserApi';
import { useGetEventQuery } from '../../../services/eventsService';
import { useGetEventInvitesQuery } from '../../../services/invitesApi';
import { useLazyGetUsersByTagsQuery } from '../../../services/usersApi';
import { useTypedSelector } from '../../../state';
import { inviteScreenActions } from '../../../state/slices/inviteScreenSlice';
import { getEventIdFromSignature } from '../../../utils';
import { InviteeCard, LoadingIndicator, ScreenTitle } from '../../ui';
import { TagsSelect } from './components';

export const InviteScreen = () => {
  const { eventSignature } = useParams();

  const eventId = useMemo( () => {
    if ( !eventSignature ) throw new Error( 'no event signature' );
    return getEventIdFromSignature( eventSignature );
  }, [ eventSignature ] );

  const storedState = useTypedSelector( state => state.inviteScreen );
  const dispatch = useDispatch();

  const { data: currentUser } = useGetCurrentUserInfoQuery();
  const [ getUsers, { isFetching: isUsersLoading } ] = useLazyGetUsersByTagsQuery();
  const { data: sentInvites } = useGetEventInvitesQuery( eventId || skipToken );
  const { data: event } = useGetEventQuery( eventId || skipToken );

  useDocTitle( event ? `Crewww - Invite to ${event.title}` : 'Crewww - Invite' );

  const [ selectedFeatures, setSelectedFeatures ] = useState<string[]>(
    ( storedState.eventId === eventId ? storedState.selectedFeatures : [] ) || []
  );
  const [ selectedInterests, setSelectedInterests ] = useState<string[]>(
    ( storedState.eventId === eventId ? storedState.selectedInterests : [] ) || []
  );

  const updateTags = ( setter: ( tags: string[] ) => void ) => ( tags: string[] ) => {
    dispatch( inviteScreenActions.clearInviteScreenState() );
    setter( tags );
  };

  const [ suitableUsers, setSuitableUsers ] = useState<UserMediaExpanded[]>(
    storedState.suitableUsers || []
  );
  const [ justInvitedUsers, setJustInvitedUsers ] = useState<string[]>( [] );
  const [ currentPage, setCurrentPage ] = useState( storedState.currentPage || 1 );
  const [ hasNext, setHasNext ] = useState<boolean | undefined>( storedState.hasNext );

  const invitedUsers = useMemo( () => {
    return sentInvites?.map( invite => invite.invitee ) || [];
  }, [ sentInvites ] );

  console.log( { event } );

  const updateUsers = useCallback(
    debounce( 400, ( features: string[], interests: string[] ) => {
      if ( invitedUsers.length >= 20 ) return;
      console.log( { gender: event?.byoc?.targetCrewGender } );
      getUsers( { features, interests, gender: event?.byoc?.targetCrewGender } )
        .unwrap()
        .then( response => {
          setSuitableUsers( response.users );
          setHasNext( response.users.length < response.total );
        } );
    } ),
    [ getUsers, setSuitableUsers, invitedUsers, storedState, event ]
  );

  const updateUsersNextPage = useCallback( () => {
    if ( invitedUsers.length >= 20 ) return;
    getUsers( {
      features: selectedFeatures,
      interests: selectedInterests,
      page: currentPage + 1,
      gender: event?.byoc?.targetCrewGender
    } )
      .unwrap()
      .then( response => {
        const tmp = [ ...suitableUsers, ...response.users ];
        setSuitableUsers( tmp );
        setHasNext( response.users.length > 0 && tmp.length < response.total );
        setCurrentPage( currentPage + 1 );
      } );
  }, [ getUsers, suitableUsers, currentPage, event ] );

  useEffect( () => {
    if (
      ( selectedFeatures.length || selectedInterests.length ) &&
      !storedState.cancelInitRequest
    ) {
      updateUsers( selectedFeatures, selectedInterests );
    }
  }, [ selectedFeatures, selectedInterests ] );

  useEffect( () => {
    dispatch(
      inviteScreenActions.setInviteScreenState( {
        eventId,
        selectedFeatures,
        selectedInterests,
        suitableUsers,
        hasNext,
        currentPage
      } )
    );
  }, [ selectedFeatures, selectedInterests, eventId, suitableUsers, currentPage ] );

  useBottomReachHandler( () => {
    if ( hasNext && !isUsersLoading ) updateUsersNextPage();
  } );

  const uninvitedUsers = useMemo( () => {
    if ( !sentInvites || !sentInvites.length ) return suitableUsers;
    const invitedUsers = sentInvites
      .map( invite => invite.invitee._id )
      .concat( currentUser?._id || '' );
    return suitableUsers.filter(
      user => !invitedUsers.includes( user._id ) || justInvitedUsers.includes( user._id )
    );
  }, [ suitableUsers, sentInvites, currentUser ] );

  const onInvite = ( user: UserMediaExpanded ) => {
    setJustInvitedUsers( [ ...justInvitedUsers, user._id ] );
  };

  return (
    <>
      <ScreenTitle
        title='Invite to Event'
        backLink={routesPaths.events.index}
      />
      <p>
        Filter crewww members by tags below and invite them to your event! Invite up to 20
        members
      </p>
      <TagsSelect
        selectedFeatures={selectedFeatures}
        selectedInterests={selectedInterests}
        onFeaturesChange={updateTags( setSelectedFeatures )}
        onInterestsChange={updateTags( setSelectedInterests )}
      />
      {uninvitedUsers.map( ( user, index ) => (
        <InviteeCard
          user={user}
          key={index}
          eventId={eventId}
          invited={justInvitedUsers.includes( user._id )}
          onInvite={onInvite}
        />
      ) )}
      {isUsersLoading && (
        <div style={{ position: 'relative', height: 120 }}>
          <LoadingIndicator />
        </div>
      )}
    </>
  );
};
