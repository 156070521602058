import { useState } from 'react';
import { Toggle, ToggleProps } from '../Toggle';
import { VisibilityToggle } from '../VisibilityToggle';
import styles from './ToggleLabeled.module.sass';

export type ToggleLabeledVisibilityChangeProps = {
  visibilityDefault: boolean;
  onVisibilityChange: ( visible: boolean ) => void;
};

export type ToggleLabeledNoVisibilityChangeProps = {
  visibilityDefault?: undefined;
  onVisibilityChange?: undefined;
};

export type ToggleLabeledProps = ToggleProps & { title: string } & (
    | ToggleLabeledVisibilityChangeProps
    | ToggleLabeledNoVisibilityChangeProps
  );

export const ToggleLabeled = ( {
  onToggle,
  defaultValue,
  title,
  labels,
  visibilityDefault,
  onVisibilityChange
}: ToggleLabeledProps ) => {
  const [ visible, setVisible ] = useState(
    visibilityDefault !== undefined ? visibilityDefault : true
  );

  const onVisibleMiddleware = ( visible: boolean ) => {
    setVisible( visible );
    if ( onVisibilityChange ) onVisibilityChange( visible );
  };

  return (
    <div
      className={[
        styles['toggle-labeled'],
        visible ? styles['visible'] : styles['not-visible'],
        onVisibilityChange ? undefined : styles['no-visibility']
      ].join( ' ' )}
    >
      <span>{title}</span>
      <div className={styles['controls']}>
        {onVisibilityChange ? (
          <VisibilityToggle
            defaultValue={visibilityDefault}
            onToggle={onVisibleMiddleware}
          />
        ) : null}
        <Toggle
          defaultValue={defaultValue}
          onToggle={onToggle}
          labels={labels}
          disabled={!visible}
        />
      </div>
    </div>
  );
};
