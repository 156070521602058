import { useEffect, useState } from 'react';
import '../../assets/css/components/controls/Toggle.scss';

export interface ToggleProps {
  defaultValue?: boolean;
  onToggle: ( on: boolean ) => void;
  labels?: {
    enabled: string;
    disabled: string;
  } | null;
  disabled?: boolean;
  className?: string;
}

export const Toggle = ( {
  onToggle,
  defaultValue = false,
  labels = { enabled: 'Yes', disabled: 'No' },
  disabled,
  className
}: ToggleProps ) => {
  const [ enabled, setEnabled ] = useState( defaultValue );

  const toggle = () => setEnabled( !enabled );

  useEffect( () => onToggle( enabled ), [ enabled, onToggle ] );

  return (
    <div
      className={[ 'toggle', className ].join( ' ' )}
      onClick={disabled ? undefined : toggle}
      data-enabled={enabled}
    >
      {labels && <span>{enabled ? labels.enabled : labels.disabled}</span>}
    </div>
  );
};
