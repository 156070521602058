import MUITextField from '@mui/material/TextField';
import { AdapterLuxon as Adapter } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import '../../assets/css/components/controls/DateTimeField.css';

export interface DateTimeFieldProps
  extends Pick<DateTimePickerProps<DateTime>, 'minDateTime' | 'maxDateTime'> {
  name: string;
  required?: boolean;
}

export const DateTimeField = ( {
  name,
  minDateTime,
  maxDateTime,
  required
}: DateTimeFieldProps ) => {
  const { values, setFieldValue, setFieldError, errors, touched, handleBlur } =
    useFormikContext<Record<string, Date>>();

  const isClicked = !!touched[name];

  const fieldValue = ( values as Record<string, Date> )[name];

  const onChange = ( date: DateTime | null ) => {
    if ( date ) setFieldValue( name, date.set( { second: 0 } ).toJSDate() );
  };

  useEffect( () => {
    if ( !isClicked && required && !( errors as Record<string, string> )[name] )
      setFieldError( name, 'required' );
  }, [ isClicked, setFieldError, errors ] );

  return (
    <LocalizationProvider dateAdapter={Adapter}>
      <MobileDateTimePicker
        onChange={onChange}
        value={fieldValue || DateTime.now()}
        renderInput={props => {
          const value = fieldValue ? props.inputProps?.value : '';
          return (
            <MUITextField
              {...props}
              className={'datetime-input'}
              inputProps={{
                ...props.inputProps,
                value,
                placeholder: 'At What Time?'
              }}
              onBlur={handleBlur( name )}
            />
          );
        }}
        minDateTime={minDateTime}
        maxDateTime={maxDateTime}
      />
    </LocalizationProvider>
  );
};
