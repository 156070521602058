import { MouseEventHandler, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../../../../../assets/img/icons/close-alt.svg';
import styles from './InvitedUser.module.scss';

export interface InvitedUserProps {
  user?: {
    avatar?: string;
    name: string;
    id: string;
  };
  phone?: string;
  onRemove?: () => void;
}

export const InvitedUser = ( { phone, user, onRemove }: InvitedUserProps ) => {
  const [ isImgError, setImgError ] = useState( false );
  const toggleImgError = ( value: boolean ) => () => setImgError( value );

  const text = useMemo( () => {
    if ( phone ) return phone.replace( '+', '' ).slice( 0, 4 );
    if ( user && ( !user.avatar || isImgError ) ) return user.name[0];
  }, [ phone, user, isImgError ] );

  const onRemoveClick: MouseEventHandler = e => {
    e.preventDefault();
    if ( onRemove ) onRemove();
  };

  const render = (
    <div className={styles['invited-user']}>
      {user?.avatar && !isImgError && (
        <img
          src={user.avatar}
          onError={toggleImgError( true )}
          alt={user.name}
        />
      )}
      {text}
      {onRemove && (
        <CloseIcon
          className={styles['close']}
          onClick={onRemoveClick}
        />
      )}
    </div>
  );

  return user ? (
    <Link
      to={`/profile/${user.id}`}
      target='_blank'
    >
      {render}
    </Link>
  ) : (
    render
  );
};
