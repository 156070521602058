import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { routesPaths } from '../../../../navigation';
import { EventReview } from '../../../../services';
import { Avatar } from '../Avatar';
import styles from './Review.module.sass';

export interface ReviewProps {
  review: EventReview;
}

export const Review = ( { review }: ReviewProps ) => {
  const profileUrl = useMemo(
    () =>
      [ routesPaths.profile.index, routesPaths.profile.user ]
        .join( '/' )
        .replace( ':userId', review.author._id ),
    [ review ]
  );

  return (
    <article className={styles['review']}>
      <Link to={profileUrl}>
        <Avatar
          user={review.author}
          className={styles['avatar']}
        />
      </Link>
      <div className={styles['content']}>
        <Link to={profileUrl}>
          <h1>{review.author.name}</h1>
        </Link>
        <p>{review.text}</p>
      </div>
    </article>
  );
};
