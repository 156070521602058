import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../assets/css/components/screens/EventScreen.css';
import { useDocTitle } from '../../../hooks';
import { routesPaths } from '../../../navigation';
import {
  useCreateEventMutation,
  useDeleteEventMutation,
  useGetBannerColorsQuery,
  useGetEventQuery,
  useLazyGetEventQuery,
  useUpdateEventMutation
} from '../../../services/eventsService';
import { useTypedSelector } from '../../../state';
import { eventCreationPrefillActions } from '../../../state/slices/eventCreationPrefillSlice';
import {
  eventCreationValidationSchema,
  getEventCreationInitialValues,
  getEventIdFromSignature,
  getEventUrl,
  getRandomEmoji,
  getRandomIntFromInterval
} from '../../../utils';
import { EventLocationModal } from '../../modals';
import { ButtonWithConfirmation } from '../../ui';
import '../EventScreen/EventScreen.sass';
import styles from './EventCreationScreen.module.scss';
import { BYOCEvent, PlainEvent } from './components';

const initialValues = getEventCreationInitialValues( undefined, true );

export interface EventCreationScreenProps {
  edit?: boolean;
}

export interface EventCreationNavigationState {
  event: string | undefined;
}

export const EventCreationScreen = ( { edit }: EventCreationScreenProps ) => {
  const dispatch = useDispatch();
  const { eventSignature } = useParams();

  const eventId = useMemo( () => {
    if ( eventSignature ) return getEventIdFromSignature( eventSignature );
  }, [ eventSignature ] );
  const prefilledValues = useTypedSelector( state => state.eventCreationPrefill.prefill );

  const bannerColors = useGetBannerColorsQuery();
  const [
    createEvent,
    { isSuccess: creationSuccess, isError: creationError, data: creationResult }
  ] = useCreateEventMutation();
  const [ updateEvent, { isSuccess: updateSuccess, isError: updateError } ] =
    useUpdateEventMutation();
  const [ deleteEvent, { isSuccess: deleteSuccess, isError: deleteError } ] =
    useDeleteEventMutation();
  const { data: eventToEdit } = useGetEventQuery( eventId || skipToken );
  const [ getEvent ] = useLazyGetEventQuery();

  useDocTitle( eventToEdit ? `Crewww - Edit ${eventToEdit.title}` : 'Create event' );

  const [ showLocationModal, setShowLocationModal ] = useState( false );
  const [ noMargin, setNoMargin ] = useState( false );
  const [ maxMembers, setMaxMembers ] = useState<number>();

  const navigate = useNavigate();

  useEffect( () => {
    if ( creationSuccess && creationResult ) {
      getEvent( creationResult._id )
        .unwrap()
        .then( event => {
          toast.success( 'Event created successfully' );
          navigate(
            event.byoc
              ? getEventUrl( event )
              : [ getEventUrl( event ), routesPaths.event.single.invite ].join( '/' )
          );
        } );
    } else if ( creationError ) {
      toast.error( 'Failed to create event' );
    }
  }, [ creationSuccess, creationError ] );

  useEffect( () => {
    if ( updateSuccess ) {
      toast.success( 'Event updated successfully' );
      if ( eventToEdit ) navigate( getEventUrl( eventToEdit ) );
    } else if ( updateError ) {
      toast.error( 'Failed to update event' );
    }
  }, [ updateSuccess, updateError ] );

  useEffect( () => {
    if ( deleteSuccess ) {
      toast.success( 'Event deleted' );
      navigate( routesPaths.events.index );
    } else if ( deleteError ) {
      toast.error( 'Failed to delete event' );
    }
  }, [ deleteSuccess, deleteError ] );

  useEffect( () => {
    return () => {
      dispatch( eventCreationPrefillActions.setPrefill( undefined ) );
    };
  }, [] );

  const onSubmit = ( values: typeof initialValues ) => {
    values.maxMembers = +values.maxMembers;
    if ( !values.emoji ) values.emoji = getRandomEmoji().repeat( 3 );
    if ( !values.bannerColor && bannerColors.data )
      values.bannerColor =
        bannerColors.data[
          getRandomIntFromInterval( 0, bannerColors.data.length - 1 )
        ].color;
    if ( edit ) {
      if ( !eventToEdit ) return;
      updateEvent( { id: eventToEdit._id, eventData: values } );
    } else {
      createEvent( values )
        .unwrap()
        .then( ( { _id } ) => {
          gtag( 'event', 'createEvent', {
            createdEvent: _id,
            event_label: 'User created Crewww event',
            event_category: 'events'
          } );
          heap.track( 'createEvent', {
            eventId: _id,
            title: values.title
          } );
        } );
    }
  };

  const onMaxMembersUpdate = ( max: number ) => {
    setMaxMembers( max );
  };

  return (
    <>
      <Formik
        initialValues={
          edit && eventToEdit
            ? getEventCreationInitialValues( eventToEdit )
            : prefilledValues || initialValues
        }
        onSubmit={onSubmit}
        validationSchema={eventCreationValidationSchema( maxMembers || 0, edit )}
        validateOnMount={true}
        enableReinitialize
      >
        {( { values, handleSubmit } ) => (
          <form
            onSubmit={handleSubmit}
            className={[
              'event-body',
              noMargin ? 'no-margin' : undefined,
              edit ? styles['edit'] : undefined
            ].join( ' ' )}
          >
            {values.byoc || ( edit && eventToEdit?.byoc ) ? (
              <BYOCEvent
                edit={edit}
                showLocationModal={showLocationModal}
                setShowLocationModal={setShowLocationModal}
                setNoMargin={setNoMargin}
                onMaxMembersUpdate={onMaxMembersUpdate}
              />
            ) : (
              <PlainEvent
                edit={edit}
                showLocationModal={showLocationModal}
                setShowLocationModal={setShowLocationModal}
                onMaxMembersUpdate={onMaxMembersUpdate}
              />
            )}
            {edit && (
              <ButtonWithConfirmation
                buttonText='Delete event'
                modalText='You can’t undo this action.'
                modalTitle='Delete event?'
                confirm={{
                  label: 'Delete',
                  onClick: () => eventId && deleteEvent( eventId )
                }}
                buttonProps={{
                  style: 'transparentNoBorder',
                  styles: { marginBottom: 0 }
                }}
              />
            )}
            {showLocationModal && (
              <EventLocationModal
                close={() => setShowLocationModal( false )}
                name='location'
              />
            )}
          </form>
        )}
      </Formik>
    </>
  );
};
