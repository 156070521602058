import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useBottomReachHandler } from '../../../hooks';
import { routesPaths } from '../../../navigation';
import {
  useGetEventsQuery,
  useGetPastEventsQuery
} from '../../../services/eventsService';
import { useGetInvitesQuery } from '../../../services/invitesApi';
import { useGetLandingEventsQuery } from '../../../services/landingService';
import { useTypedSelector } from '../../../state';
import { locationActions } from '../../../state/slices/locationSlice';
import { ListControls } from '../../controls';
import { Modal } from '../../modals';
import { EventItem, LoadingIndicator, ScreenTitle, TopLoadingIndicator } from '../../ui';
import { InviteBoxIcon } from '../../ui/icons';
import styles from './EventsListScreen.module.sass';
import { UnreadInvites } from './UnreadInvites';

export const EventsListScreen = () => {
  const navigate = useNavigate();

  const { lat, lng, locationDenied, locationReceived } = useTypedSelector(
    state => state.location
  );
  const { isAuthorized } = useTypedSelector( state => state.auth );
  const dispatch = useDispatch();

  const [ sort, setSort ] = useState<'start' | 'distance' | undefined>( 'start' );
  const [ isLoginModalOpen, setLoginModalOpen ] = useState( false );
  const [ pastEventsLimit, setPastEventsLimit ] = useState( 3 );

  useEffect( () => {
    if ( sort === 'distance' ) {
      dispatch( locationActions.setDenied( undefined ) );
      navigator.geolocation.getCurrentPosition(
        result => {
          dispatch(
            locationActions.setLocation( {
              lat: result.coords.latitude,
              lng: result.coords.longitude
            } )
          );
        },
        err => {
          if ( err.PERMISSION_DENIED ) {
            dispatch( locationActions.setDenied( true ) );
          }
        }
      );
    }
  }, [ sort ] );

  const { data: unreadInvites } = useGetInvitesQuery(
    isAuthorized ? { status: 'sent' } : skipToken
  );

  const events = isAuthorized
    ? useGetEventsQuery(
        { sort: locationReceived ? sort : 'start', lat, lng },
        { pollingInterval: 5000 }
      )
    : useGetLandingEventsQuery();

  const pastEvents = useGetPastEventsQuery(
    isAuthorized ? { limit: pastEventsLimit } : skipToken
  );

  const openLoginModal = () => setLoginModalOpen( true );
  const goToOnboarding = () => navigate( routesPaths.onboarding );
  const goToLogin = () => navigate( routesPaths.login );
  const goToInvites = () => navigate( routesPaths.invites );

  const onEventUnauthorizedClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    openLoginModal();
  };

  useEffect( () => {
    if ( !isAuthorized ) {
      const timeout = setTimeout( () => openLoginModal(), 3000 );
      return () => clearTimeout( timeout );
    }
  }, [ isAuthorized ] );

  useBottomReachHandler( () => {
    if ( pastEvents.data && pastEvents.data.total > pastEventsLimit ) {
      let limitNew = pastEventsLimit + 3;
      if ( limitNew > 60 ) limitNew = 60;
      setPastEventsLimit( limitNew );
    }
  } );

  if ( events.isLoading || pastEvents.isLoading ) return <LoadingIndicator />;
  if ( events.isError ) return <>error</>;

  return (
    <>
      <ScreenTitle
        title='Events'
        back={false}
        action={
          isAuthorized
            ? {
                label: <InviteBoxIcon unread={!!unreadInvites?.length} />,
                onClick: goToInvites
              }
            : { label: 'Login', onClick: goToLogin }
        }
      />

      {isAuthorized &&
        ( unreadInvites?.length ? (
          <UnreadInvites />
        ) : (
          <ListControls
            controls={[
              {
                options: [
                  { value: 'start', label: 'Start date' },
                  { value: 'distance', label: 'Distance' }
                ],
                onChange: setSort as ( value: string | undefined ) => void,
                placeholder: 'Sort by',
                value: sort
              }
            ]}
          />
        ) )}
      {sort === 'distance' && locationDenied ? (
        <div className={styles['location-denied']}>
          <h3>Location is turned off</h3>
          <p>
            Please enable your location via browser settings to sort events by distance
            from you
          </p>
        </div>
      ) : (
        events.data?.map( event => (
          <EventItem
            event={event}
            key={event._id}
          />
        ) )
      )}
      {!!pastEvents.data?.events.length && (
        <>
          <h3 className={styles['section-label']}>Past Events</h3>
          {pastEvents.data.events.map( event => (
            <EventItem
              event={event}
              key={event._id}
              onLinkClick={isAuthorized ? undefined : onEventUnauthorizedClick}
            />
          ) )}
        </>
      )}
      {pastEvents.isFetching && <TopLoadingIndicator />}

      <div style={{ marginBottom: 80 }} />
      {isLoginModalOpen && (
        <Modal
          isOpen={isLoginModalOpen}
          confirm={{
            label: 'Login / Sign Up',
            onClick: goToOnboarding
          }}
          onClose={() => null}
          title='Login or Sign Up to view events!'
          hideCloseButton
        />
      )}
    </>
  );
};
