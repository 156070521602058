export const testPhone = ( phone: string | undefined ) => {
  return phone ? /^\+\d{10,15}$/.test( phone ) : false;
};

export const testPhoneStart = ( phone: string | undefined ) => {
  return phone ? /^\+\d*/.test( phone ) : false;
};

export const testPhoneOptionalPlus = ( phone: string | undefined ) => {
  return phone ? /^\+?\d{10,15}$/.test( phone ) : false;
};

export const onlyEmojiRegex =
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
