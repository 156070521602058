import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InviteScreenSliceState } from './types';

const initialState: InviteScreenSliceState = {};

export const invtieScreenSlice = createSlice( {
  initialState,
  name: 'inviteScreen',
  reducers: {
    setInviteScreenState: ( state, action: PayloadAction<InviteScreenSliceState> ) => {
      const {
        eventId,
        selectedFeatures,
        selectedInterests,
        suitableUsers,
        hasNext,
        currentPage,
      } = action.payload;
      state.eventId = eventId;
      state.selectedFeatures = selectedFeatures;
      state.selectedInterests = selectedInterests;
      state.suitableUsers = suitableUsers;
      state.hasNext = hasNext;
      state.currentPage = currentPage;
      if ( suitableUsers?.length ) state.cancelInitRequest = true;
    },
    clearInviteScreenState: state => {
      state.eventId = undefined;
      state.selectedFeatures = undefined;
      state.selectedInterests = undefined;
      state.suitableUsers = undefined;
      state.cancelInitRequest = false;
      state.currentPage = undefined;
    },
    setCancelInitRequest: (
      state,
      action: PayloadAction<InviteScreenSliceState['cancelInitRequest']>
    ) => {
      state.cancelInitRequest = action.payload;
    },
  },
} );

export const inviteScreenActions = invtieScreenSlice.actions;
