import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDg8TBxhrkjZ0FN_gcy1bl3TftRqg7dXwo',
  authDomain: 'cre-d1bf7.firebaseapp.com',
  projectId: 'cre-d1bf7',
  storageBucket: 'cre-d1bf7.appspot.com',
  messagingSenderId: '1010664448464',
  appId: '1:1010664448464:web:e43b82e41a7dfd72ec19d5',
  measurementId: 'G-F1Y5BYJVE8'
};

export const firebaseApp = initializeApp( firebaseConfig );
export const firebaseStorage = getStorage( firebaseApp );