import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Clock } from '../../assets/img/icons/clock.svg';
import { ReactComponent as Location } from '../../assets/img/icons/location.svg';
import { ReactComponent as SandGlass } from '../../assets/img/icons/sandglass.svg';
import { ReactComponent as User } from '../../assets/img/icons/userStroke.svg';
import { routesPaths } from '../../navigation';
import { EventPreview, isServerResponse } from '../../services';
import { getEventPreview } from '../../services/eventsService';
import {
  generateMapsLocationURL,
  getCssGradientObject,
  getEventStartString
} from '../../utils';
import { Button } from '../controls';
import { Modal } from '../modals';
import { CountDown, EventInfo, EventMemberPreview } from '../ui';

export const EventPreviewScreen = () => {
  const [ token, setToken ] = useState<string>();
  const [ eventPreview, setEventPreview ] = useState<EventPreview>();
  const [ isLoginModalOpen, setLoginModalOpen ] = useState( false );

  const navigate = useNavigate();
  const goToOnboarding = () => navigate( routesPaths.onboarding );
  const openLoginModal = () => setLoginModalOpen( true );

  useEffect( () => {
    const regexMatch = window.location.href.match( /preview\/(.*)$/ );
    if ( regexMatch ) {
      setToken( regexMatch[1] );
    }
  }, [] );

  useEffect( () => {
    if ( token )
      getEventPreview( token ).then( result => {
        if ( isServerResponse( result ) || !result ) {
          toast.error( 'Some error occurred' );
        } else {
          setEventPreview( result );
        }
      } );
  }, [ token ] );

  const mapsUrl = useMemo( () => generateMapsLocationURL( eventPreview ), [ eventPreview ] );

  if ( !eventPreview ) return null;

  return (
    <>
      <div
        className='event-banner single'
        style={getCssGradientObject( eventPreview.bannerColor )}
      >
        <div className='emoji'>{eventPreview.emoji}</div>
        <div className='title'>{eventPreview.title}</div>
      </div>
      <div className='event-body'>
        <EventInfo
          items={[
            {
              icon: Location,
              element: (
                <a
                  href={mapsUrl}
                  target='_blank'
                  rel='noreferrer'
                >
                  <u>{eventPreview.location.title}</u>
                </a>
              )
            },
            {
              icon: User,
              element: (
                <p>
                  {eventPreview.members.length}/{eventPreview.maxMembers}
                </p>
              )
            },
            { icon: Clock, element: <p>{getEventStartString( eventPreview )}</p> },
            {
              icon: SandGlass,
              element: <CountDown target={DateTime.fromJSDate( eventPreview.start )} />,
              className: 'countdown'
            }
          ]}
        />
        <p>{eventPreview.description}</p>
        {eventPreview.members.map( ( member, index ) => (
          <EventMemberPreview
            key={index}
            user={member}
            creator={eventPreview.author.name === member.name}
            onClick={openLoginModal}
          />
        ) )}
        <Button
          text={'Join'}
          disabled={false}
          onClick={openLoginModal}
          styles={{ marginTop: 20 }}
        />
      </div>
      <Modal
        isOpen={isLoginModalOpen}
        confirm={{
          label: 'Login / Sign Up',
          onClick: goToOnboarding
        }}
        onClose={() => null}
        title='Login or Sign Up to view events!'
        hideCloseButton
      />
    </>
  );
};
