import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../assets/css/components/ui/global/ScreenTitle.css';
import { ReactComponent as Back } from '../../../assets/img/icons/back-arrow.svg';
import { ReactComponent as Close } from '../../../assets/img/icons/close.svg';
import { Modal } from '../../modals/modalBase';

export interface ScreenTitleProps {
  title: string;
  action?: { label: string | JSX.Element; onClick: () => void };
  back?: boolean;
  close?: boolean;
  backLink?: string;
  confirmationProps?: {
    title: string;
    text: string;
    confirmText?: string;
    cancelText?: string;
  };
  onBack?: () => void;
}

export const ScreenTitle = ( {
  title,
  action,
  back = true,
  close = false,
  backLink,
  confirmationProps,
  onBack
}: ScreenTitleProps ) => {
  const navigate = useNavigate();
  const goBack = () => {
    if ( onBack ) {
      onBack();
    } else if ( close ) {
      navigate( backLink || '/' );
    } else {
      if ( backLink ) navigate( backLink );
      else navigate( -1 );
    }
  };

  const [ isConfirmationOpen, setConfirmationOpen ] = useState( false );
  const openConfirmation = () => setConfirmationOpen( true );
  const closeConfirmation = () => setConfirmationOpen( false );

  return (
    <div className='screen-title'>
      {back && (
        <div
          className='back'
          onClick={confirmationProps ? openConfirmation : goBack}
        >
          {close ? <Close /> : <Back className='triangle' />}
        </div>
      )}
      <h1>{title}</h1>
      {action && (
        <div
          className='action'
          onClick={action.onClick}
        >
          {action.label}
        </div>
      )}
      {confirmationProps && (
        <Modal
          isOpen={isConfirmationOpen}
          cancel={{
            label: confirmationProps.cancelText || 'Cancel',
            onClick: closeConfirmation
          }}
          confirm={{ label: confirmationProps.confirmText || 'Confirm', onClick: goBack }}
          onClose={closeConfirmation}
          title={confirmationProps.title}
        >
          {confirmationProps.text}
        </Modal>
      )}
    </div>
  );
};
