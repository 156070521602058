import { useMemo } from 'react';
import { GetUsersResponse } from '../../../../../services';
import { useGetCurrentUserInfoQuery } from '../../../../../services/currentUserApi';
import { InvitedUser } from '../InvitedUser';
import styles from './Crew.module.scss';

export interface CrewParams {
  users: GetUsersResponse;
  crewSize: number;
  onUserRemove?: ( id: string ) => void;
}

export const Crew = ( { users, crewSize, onUserRemove }: CrewParams ) => {
  const { data: currentUser } = useGetCurrentUserInfoQuery();

  const placeholdersCount = useMemo( () => {
    return crewSize - 1 - users.length;
  }, [ users, crewSize ] );

  const onMemberRemove = ( user: GetUsersResponse[0] ) => () => {
    if ( onUserRemove ) onUserRemove( user._id );
  };

  return (
    <div className={styles['crew']}>
      <h4>
        My Crew ({users.length + 1}/{crewSize})
      </h4>
      <div className={styles['users']}>
        {currentUser && (
          <InvitedUser
            user={{
              id: currentUser._id,
              name: currentUser.name,
              avatar: currentUser.media[0]?.url
            }}
          />
        )}
        {users.map( ( user, index ) => (
          <InvitedUser
            key={index}
            user={{ id: user._id, name: user.name, avatar: user.media[0]?.url }}
            onRemove={onMemberRemove( user )}
          />
        ) )}
        {[ ...Array( placeholdersCount ).keys() ].map( index => (
          <div
            className={styles['placeholder']}
            key={index}
          ></div>
        ) )}
      </div>
    </div>
  );
};
