import React, { Ref, useState } from 'react';
import '../../assets/css/components/controls/TagList.css';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/close.svg';
import { Tag } from '../../services';

export type TagWithoutType = Omit<Tag, 'type'>;

export type TagListProps = {
  items: Tag[];
  title?: string;
  editable?: true;
  selected?: string[];
  onSelect?: ( tag: Tag ) => void;
  canAdd?: true;
  onAdd?: ( _id: string ) => void;
  onDelete?: ( tag: Tag ) => void;
  className?: string;
  containerRef?: Ref<HTMLDivElement>;
};

const tagItemBaseClass = 'tag-item';

export const TagList = ( {
  items,
  title,
  editable,
  selected,
  onSelect,
  canAdd,
  onAdd,
  onDelete,
  className,
  containerRef,
}: TagListProps ) => {
  const [ adding, setAdding ] = useState( false );
  const [ newTagLength, setNewTagLength ] = useState( 1 );

  const canDelete = editable && onDelete;

  const getTagItemClassName = editable
    ? ( tag: TagWithoutType ) =>
        [
          tagItemBaseClass,
          selected?.includes( tag._id ) ? 'active' : undefined,
          !tag.default ? 'custom' : undefined,
          canDelete && 'deletable',
        ].join( ' ' )
    : () => tagItemBaseClass;

  const getItemClickHandler = ( tag: Tag ) => ( onSelect ? () => onSelect( tag ) : undefined );

  const openAddInput = () => {
    setAdding( true );
  };

  const addTag = ( value: string ) => {
    if ( value.length && onAdd ) onAdd( value );
    setAdding( false );
    setNewTagLength( 1 );
  };

  const onBlur = ( e: React.FocusEvent<HTMLInputElement, Element> ) => {
    addTag( e.target.value );
  };

  const onEnterPress = ( e: React.KeyboardEvent<HTMLInputElement> ) => {
    if ( e.key === 'Enter' ) addTag( e.currentTarget.value );
  };

  const onNewItemChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setNewTagLength( e.target.value.length || 1 );
  };

  const onItemDeleteClick = ( tag: Tag ) => ( e: React.MouseEvent<HTMLSpanElement> ) => {
    e.stopPropagation();
    onDelete && onDelete( tag );
  };

  return (
    <div
      className={[ 'tag-list', className, editable ? 'editable' : '' ].join( ' ' )}
      ref={containerRef}
    >
      {title && <label>{title}</label>}
      <div className='tag-items'>
        {items.map( tag => {
          if ( !tag ) return null;
          return (
            <span
              key={tag._id}
              className={getTagItemClassName( tag )}
              onClick={getItemClickHandler( tag )}
            >
              {tag.name}
              {!tag.default && canDelete && (
                <span
                  className='delete'
                  onClick={onItemDeleteClick( tag )}
                >
                  <DeleteIcon />
                </span>
              )}
            </span>
          );
        } )}
        {adding && (
          <span className={[ tagItemBaseClass, 'active', 'new-tag' ].join( ' ' )}>
            <input
              type='text'
              onBlur={onBlur}
              onKeyPress={onEnterPress}
              onChange={onNewItemChange}
              size={newTagLength}
              autoFocus
            />
          </span>
        )}
        {canAdd && (
          <span
            className='add'
            onClick={openAddInput}
          >
            +Add
          </span>
        )}
      </div>
    </div>
  );
};
