import { store } from '../state';
import { facebookActions } from '../state/slices/thirdParty';
import { FacebookAuthData, FacebookUserData } from './types';

const login = () => new Promise<FacebookAuthData | null>( ( resolve, reject ) => {
  try {
    FB.getLoginStatus( loginStatusResponse => {
      if ( loginStatusResponse.status !== 'connected' ) {
        FB.login( loginResponse => {
          if ( loginResponse.status === 'connected' ) {
            const authData = {
              facebookAccessToken: loginResponse.authResponse.accessToken,
              facebookUserId: loginResponse.authResponse.userID,
            };
            store.dispatch( facebookActions.setAuthData( authData ) );
            resolve( authData );
          } else resolve( null );
        } );
      } else {
        const authData = {
          facebookAccessToken: loginStatusResponse.authResponse.accessToken,
          facebookUserId: loginStatusResponse.authResponse.userID,
        };
        store.dispatch( facebookActions.setAuthData( authData ) );
        resolve( authData );
      }
    } );
  } catch {
    reject();
  }
} );

const logout = () => new Promise<boolean>( ( resolve, reject ) => {
  try {
    FB.logout( logoutResponse => {
      resolve( logoutResponse.status !== 'connected' );
    } );
  } catch {
    reject();
  }
} );

const getUserData = () => new Promise<void>( ( resolve, reject ) => {
  FB.api(
    '/me',
    'get',
    { 'fields': 'id,name,birthday,gender' },
    function ( response ) {
      if ( response ) {
        store.dispatch( facebookActions.setUserData( {
          id: ( response as FacebookUserData ).id,
          name: ( response as FacebookUserData ).name,
          gender: ( response as FacebookUserData ).gender,
          birthday: ( response as FacebookUserData ).birthday,
        } ) );
        resolve();
      } else {
        reject();
      }
    }
  );
} );

export const facebookService = {
  login,
  logout,
  getUserData,
};
