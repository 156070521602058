import { useFormikContext } from 'formik';
import { OTPInput, OTPInputProps } from '../controls';


export type OTPFieldProps = Omit<OTPInputProps, 'value' | 'onChange'>;

export const OTPField = ( props : OTPFieldProps ) => {

  const { values, handleChange } = useFormikContext();


  return (
    <OTPInput 
      {...props}
      value={( values as Record<string, string> )[props.name]}
      onChange={handleChange( props.name )}
    />
  );
};