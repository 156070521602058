import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo, useState } from 'react';
import { useGetInstagramProfile } from '.';
import { useGetCurrentUserInfoQuery } from '../../services/currentUserApi';
import {
  InstagramMedia,
  useLazyGetInstagramMediaQuery,
  useLazyGetInstagramMediasByNextUrlQuery
} from '../../services/instagram';
import { useGetUserQuery } from '../../services/usersApi';

export const useGetInstagramImages = ( appUserId?: string ) => {
  const user = useGetUserQuery( appUserId || skipToken );
  const currentUser = useGetCurrentUserInfoQuery( appUserId ? skipToken : undefined );
  const [ getMedia ] = useLazyGetInstagramMediaQuery();
  const [ getMediasByNextUrl ] = useLazyGetInstagramMediasByNextUrlQuery();

  const [ images, setImages ] = useState<InstagramMedia[]>( [] );
  const [ isLoading, setLoading ] = useState( false );

  const isCurrentUser = !appUserId || currentUser.data?._id === appUserId;

  const accessToken = useMemo( () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ( !isCurrentUser && user.data && ( user.data as any ).instagram?.accessToken )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return ( user.data as any ).instagram.accessToken;
    if ( !currentUser.data ) return;
    return currentUser.data.instagram?.accessToken as string;
  }, [ currentUser.data, user.data ] );

  const instagramProfile = useGetInstagramProfile(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    appUserId ? ( user.data as any )?.instagram?.userId || skipToken : undefined,
    accessToken
  );

  async function fetchImages( data: { id: string }[] ) {
    if ( !accessToken ) return;
    const tmpImages: InstagramMedia[] = [];
    setLoading( true );
    await Promise.all(
      data.map( ( { id } ) =>
        getMedia(
          {
            accessToken,
            id,
            fields: [ 'media_type', 'media_url' ],
          },
          true
        )
          .unwrap()
          .then( media => {
            if ( media.media_type === 'IMAGE' ) {
              tmpImages.push( media );
            }
          } )
      )
    );
    setImages( [ ...images, ...tmpImages ] );
    setLoading( false );
  }

  useEffect( () => {
    if ( !instagramProfile || !instagramProfile.media || !accessToken ) return;
    fetchImages( instagramProfile.media.data );
  }, [ instagramProfile, accessToken ] );

  useEffect( () => {
    if ( !instagramProfile || !instagramProfile.media?.paging.next ) return;
    if ( images.length > 0 && images.length < 27 ) {
      fetchNext();
    }
    async function fetchNext() {
      if ( !instagramProfile || !instagramProfile.media?.paging.next ) return;
      getMediasByNextUrl( instagramProfile.media.paging.next, true )
        .unwrap()
        .then( medias => {
          if ( medias ) {
            fetchImages( medias.data );
          }
        } );
    }
  }, [ instagramProfile, images ] );

  return { images, isLoading };
};
