import { ReactComponent as ArrowIcon } from '../../../../assets/img/icons/arrowTriangle.svg';
import styles from './AboutButton.module.sass';

export const AboutButton = () => {
  return (
    <a
      href='https://crewww.notion.site/crewww/About-Crewww-a31892ac40484aedbee3e50640695a80'
      target='_blank'
      className={styles['button']}
      rel='noreferrer'
    >
      About Crewww
      <ArrowIcon />
    </a>
  );
};
