import { useDispatch } from 'react-redux';
import { Media } from '../../services';
import { InstagramMedia } from '../../services/instagram';
import { useTypedSelector } from '../../state';
import { imageViewActions } from '../../state/slices/imageViewSlice';


export const useImageView = () => {

  const currentImage = useTypedSelector( state => state.imageView.image );
  const dispatch = useDispatch();

  const openMedia = ( media: Media | InstagramMedia ) => {
    dispatch( imageViewActions.setImage( media ) );
  };

  const closeImageView = () => {
    dispatch( imageViewActions.setImage( undefined ) );
  };

  return { currentImage, openMedia, closeImageView };
};