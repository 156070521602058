import { EventSingle } from '../../../../services';
import { ChatLink } from '../../../ui';

import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { EventActions, EventMainInfo, EventMembers } from '.';
import { ReactComponent as WarningWhiteIcon } from '../../../../assets/img/icons/warning-white.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/img/icons/warning.svg';
import styles from './EventBody.module.scss';
import { EventReviews } from './EventReviews';
export interface EventBodyProps {
  event: EventSingle;
  bannerHeight: number;
  isAuthor: boolean;
  isMember: boolean;
  openByocJoin: () => void;
}

export const EventBody = ( {
  event,
  bannerHeight,
  isAuthor,
  isMember,
  openByocJoin
}: EventBodyProps ) => {
  const eventPassed =
    DateTime.fromJSDate( event.start ).plus( { hours: 1 } ) < DateTime.now();

  const isPendingBYOC = useMemo( () => {
    if ( event.byoc?.authorCrew ) {
      const { authorCrew } = event.byoc;
      return authorCrew.members.length !== event.maxMembers;
    }
    return false;
  }, [ event ] );

  return (
    <div
      className='event-body'
      style={{ marginTop: bannerHeight }}
    >
      {isPendingBYOC && (
        <>
          <div className={styles['pending-byoc']}>
            <WarningWhiteIcon /> Pending
          </div>
          <div className={styles['invited-note']}>
            <WarningIcon />
            <div>
              <h4>Make sure your crew signs up!</h4>
              <p>
                Before the invited crew signs up, your event will be pending and other
                users won’t be able to see your event.
              </p>
            </div>
          </div>
        </>
      )}
      <EventMainInfo event={event} />
      <p style={{ whiteSpace: 'pre-wrap' }}>{event.description}</p>
      <EventReviews event={event} />
      <EventMembers event={event} />
      {!isAuthor && isMember && !eventPassed && !event.byoc && (
        <ChatLink eventId={event._id} />
      )}
      <EventActions
        event={event}
        eventPassed={eventPassed}
        isAuthor={isAuthor}
        isMember={isMember}
        openByocJoin={openByocJoin}
      />
    </div>
  );
};
