import {
  ChangePasswordParams,
  CurrentUser,
  ServerResponse,
  UpdateCurrentUserFields,
  UpdateCurrentUserTagsParams
} from '.';
import { instagramRedirectUri } from './instagram';
import { mainApi } from './mainApi';

export const currentUserApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getCurrentUserInfo: build.query<CurrentUser, void>( {
      query: () => ( { url: '/me', method: 'GET' } ),
      providesTags: [ 'info' ]
    } ),
    updateCurrentUserInfo: build.mutation<ServerResponse, UpdateCurrentUserFields>( {
      query: user => ( { url: '/me', method: 'PUT', data: user } ),
      invalidatesTags: [ 'info' ]
    } ),
    updateCurrentUserTags: build.mutation<ServerResponse, UpdateCurrentUserTagsParams>( {
      query: ( { tagType, tags } ) => ( {
        url: `/me/${tagType}`,
        method: 'PUT',
        data: tags
      } ),
      invalidatesTags: ( res, err, arg ) => ( err ? [] : [ 'info', arg.tagType ] )
    } ),
    changePassword: build.mutation<ServerResponse, ChangePasswordParams>( {
      query: params => ( { url: '/me/changePassword', method: 'POST', data: params } )
    } ),
    instagramAuth: build.mutation<void, { code: string }>( {
      query: ( { code } ) => ( {
        url: '/socials/instagram/auth',
        data: { code, redirectUri: instagramRedirectUri },
        method: 'post'
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'info' ] )
    } ),
    instagramRefresh: build.mutation<void, void>( {
      query: () => ( {
        url: '/socials/instagram/refresh',
        method: 'post'
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'info' ] )
    } ),
    updateNotificationsSettings: build.mutation<
      void,
      Required<CurrentUser>['notificationsPreferences']
    >( {
      query: fields => ( {
        url: '/me/notifications',
        method: 'PUT',
        data: fields
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'info' ] )
    } )
  } )
} );

export const {
  useGetCurrentUserInfoQuery,
  useUpdateCurrentUserInfoMutation,
  useUpdateCurrentUserTagsMutation,
  useChangePasswordMutation,
  useInstagramAuthMutation,
  useInstagramRefreshMutation,
  useUpdateNotificationsSettingsMutation
} = currentUserApi;
