import { useEffect, useState } from 'react';
import { Media } from '../../services';
import { ImageSelect } from './ImageSelect';
import '../../assets/css/components/controls/ImageSelectBlock.css';
import { ImageSelectPreview } from '.';
import Resizer from 'react-image-file-resizer';

export interface ImageSelectBlockProps {
  defaultValues: Media[],
  onChange: ( values: ImageSelectBlockChangeValues ) => void,
  title?: string
}

export interface ImageSelectBlockChangeValues {
  deleted: Media[],
  added: File[],
  default: Media[]
}

const imagesIndexes = [ 0, 1, 2, 3, 4, 5 ];

export const ImageSelectBlock = (
  { defaultValues, onChange, title }: ImageSelectBlockProps
) => {

  const [ deletedMedia, setDeletedMedia ] = useState<Media[]>( [] );
  const [ addedImages, setAddedImages ] = useState<File[]>( [] );

  const [ mediaImages, setMediaImages ] = useState<Media[]>( defaultValues );

  const onMediaDelete = ( mediaToDelete: Media ) => {
    setDeletedMedia( deletedMedia.concat( mediaToDelete ) );
    setMediaImages( mediaImages.filter( media => media._id !== mediaToDelete._id ) );
  };

  const onSelectImage = ( imageToAdd: File ) => {
    Resizer.imageFileResizer( imageToAdd, 1000, 1000, 'JPEG', 70, 0, ( file ) => {
      const fileWithCorrectName = new File( 
        [ ( file as File ) ], 
        imageToAdd.name.replace( '.png', '.jpg' ),
        { type: ( file as File ).type } 
      );
      setAddedImages( addedImages.concat( fileWithCorrectName ) );
    }, 'file' );
  };

  const onSelectedImageDelete = ( imageToDelete: File ) => {
    setAddedImages( addedImages.filter( image => image !== imageToDelete ) );
  };

  useEffect( () => {
    onChange( {
      added: addedImages,
      deleted: deletedMedia,
      default: defaultValues
    } );
  }, [ addedImages, deletedMedia ] );

  return (
    <div className="image-select-block">
      {title && <label>{title}</label>}
      {mediaImages.map( media => (
        <ImageSelectPreview
          media={media}
          onDelete={onMediaDelete}
          key={media._id}
        />
      ) )}
      {imagesIndexes.slice( 0, 6 - mediaImages.length ).map( index => (
        <ImageSelect
          name={`imageBlock${index}`}
          onChange={onSelectImage}
          onDelete={onSelectedImageDelete}
          key={`imageBlock${index}`}
        />
      ) )}
    </div>
  );
};
