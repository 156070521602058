import { store } from '../../state';
import { googleActions } from '../../state/slices/thirdParty';


export const googleService = {
  login,
};

async function login () : Promise<string> {
  return new Promise( ( resolve, reject ) => {
    google.accounts.oauth2.initCodeClient( {
      client_id: '343838663621-7k122c4duk1qsvt3dluemu25fk4tdisb.apps.googleusercontent.com',
      redirect_uri: window.location.origin,
      scope: 'https://www.googleapis.com/auth/userinfo.profile',
      callback: ( response ) => {
        const code = ( response as {code?: string} )?.code;
        if ( code ) {
          store.dispatch( googleActions.setCode( code ) );
          resolve( code );
        } else {
          reject();
        }
      },
    } ).requestCode();
  } );
}

