import { Modal } from '../../../modals';
import '../EventScreen.sass';

export interface EventJoinModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EventJoinModal = ( { isOpen, onClose }: EventJoinModalProps ) => {
  return (
    <Modal
      isOpen={isOpen}
      confirm={{
        label: 'Message',
        onClick: onClose
      }}
      onClose={onClose}
      title='Message the event creator!'
    >
      Use the link below to confirm your interest👇
    </Modal>
  );
};
