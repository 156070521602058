import { useEffect } from 'react';

export const useScript = ( url: string, onLoad: ( () => void ) | null = null ) => {
  useEffect( () => {
    const script = document.createElement( 'script' );

    script.src = url;
    script.async = true;

    document.body.appendChild( script );

    script.onload = onLoad;

    return () => {
      document.body.removeChild( script );
    };
  }, [ url ] );
};
