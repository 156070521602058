import { Link } from 'react-router-dom';
import { routesPaths } from '../../../../navigation';
import { UserMediaExpanded } from '../../../../services';
import { Avatar } from '../Avatar';
import styles from './EventMember.module.scss';

export interface EventMemberByocProps {
  user: Pick<UserMediaExpanded, 'media' | 'name' | '_id'>;
  creator?: boolean;
  action?: {
    icon: JSX.Element;
    onClick?: () => void;
    href?: string;
  };
}

export const EventMemberByoc = ( { user, creator, action }: EventMemberByocProps ) => {
  const render = () => (
    <>
      <div className={styles['user']}>
        <Avatar user={user} />
        <div className={styles['info']}>
          {user && <div className={styles['name']}>{user.name}</div>}
          {creator && <span>creator</span>}
        </div>
      </div>
      {action &&
        ( action.href ? (
          <a
            href={action.href}
            target='_blank'
            rel='noreferrer'
          >
            {action.icon}
          </a>
        ) : (
          <div
            className={styles['action']}
            onClick={action.onClick}
          >
            {action.icon}
          </div>
        ) )}
    </>
  );

  return action ? (
    <div className={styles['event-member-byoc']}>{render()}</div>
  ) : (
    <Link
      className={styles['event-member-byoc']}
      to={`${routesPaths.profile.index}/${user._id}`}
    >
      {render()}
    </Link>
  );
};
