import React from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as CloseIcon } from '../../../assets/img/icons/close.svg';
import { Button } from '../../controls';
import styles from './Modal.module.sass';
import { ModalBody } from './ModalBody';
import { ModalTitle } from './ModalTitle';

export type ModalCloseFunction = ( e: React.MouseEvent | React.KeyboardEvent ) => void;

export interface ModalProps {
  isOpen: boolean;
  children?: React.ReactNode;
  onClose: ModalCloseFunction;
  title?: string;
  className?: string;
  confirm?: {
    label: string;
    onClick: () => void;
  };
  cancel?: {
    label: string;
    onClick: () => void;
  };
  hideCloseButton?: boolean;
}

export const Modal = ( {
  isOpen,
  children,
  onClose,
  title = '',
  className,
  cancel,
  confirm,
  hideCloseButton
}: ModalProps ) => {
  return (
    <ReactModal
      isOpen={isOpen}
      appElement={document.getElementsByTagName( 'body' )}
      className={{
        base: [ styles['modal'], className ].join( ' ' ),
        afterOpen: styles['modal-after-open'] || '',
        beforeClose: styles['modal-before-close'] || ''
      }}
      overlayClassName={{
        base: styles['modal-overlay'],
        afterOpen: styles['modal-overlay-after-open'] || '',
        beforeClose: styles['modal-overlay-before-close'] || ''
      }}
      onRequestClose={onClose}
      closeTimeoutMS={200}
    >
      {!hideCloseButton && (
        <div
          className={styles['close']}
          onClick={onClose}
        >
          <CloseIcon />
        </div>
      )}
      <ModalTitle title={title} />
      <ModalBody>
        {children}
        {( confirm || cancel ) && (
          <div className={styles['buttons']}>
            {confirm && (
              <Button
                text={confirm.label}
                onClick={confirm.onClick}
              />
            )}
            {cancel && (
              <Button
                text={cancel.label}
                onClick={cancel.onClick}
                style='transparentNoBorder'
              />
            )}
          </div>
        )}
      </ModalBody>
    </ReactModal>
  );
};
