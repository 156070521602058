import { useFormikContext } from 'formik';
import { TextArea, TextAreaProps } from '../controls';

export type TextAreaFieldProps = Omit<TextAreaProps, 'onChange' | 'value'>;

export const TextAreaField = ( props: TextAreaFieldProps ) => {
  const { values, handleChange } = useFormikContext();

  return (
    <TextArea
      {...props}
      value={( values as Record<string, string> )[props.name]}
      onChange={handleChange( props.name )}
    />
  );
};
