import { useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';
import { Modal } from './modalBase';



export const WebViewWarningModal = () => {

  const [ isOpen, setOpen ] = useState( false );
  
  useEffect( () => {
    const parser = new UAParser( window.navigator.userAgent );
    const browserName = parser.getBrowser().name;
    const isSupportedBrowser = (
      browserName === 'Chrome' ||
      browserName === 'Edge' ||
      !!browserName?.includes( 'Firefox' ) ||
      !!browserName?.includes( 'Safari' )
    );


    setOpen( !isSupportedBrowser );
  }, [] );


  const toggleOpen = ( open: boolean ) => () => setOpen( open );
  
  return (
    <Modal
      isOpen={isOpen}
      confirm={{
        label: 'Got It!',
        onClick: toggleOpen( false )
      }}
      onClose={toggleOpen( false )}
      title='Using a non-standard browser'
    >
      Non-standard browsers such as chat and social media based browsers, do not support 3rd party services for login / signup. Please use a native browser
    </Modal>
  );
};