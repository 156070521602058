import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { RiMapPinLine, RiMenLine, RiUserLine, RiWomenLine } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as AlcoholIcon } from '../../assets/img/icons/alcohol.svg';
import { ReactComponent as EducationIcon } from '../../assets/img/icons/education.svg';
import { ReactComponent as JobIcon } from '../../assets/img/icons/job.svg';
import { ReactComponent as SmokingIcon } from '../../assets/img/icons/smoking.svg';
import { useDocTitle } from '../../hooks';
import { routesPaths } from '../../navigation';
import { useGetCurrentUserInfoQuery } from '../../services/currentUserApi';
import { useGetUserMediaQuery } from '../../services/mediaService';
import { useGetUserQuery } from '../../services/usersApi';
import { TagList } from '../controls';
import { BasicInfo, InstagramBlock, LabeledField, ScreenTitle } from '../ui';
import { LoadingIndicator } from '../ui/global/LoadingIndicator';
import { ProfileGallery } from '../ui/views/ProfileGallery';

export const ProfileScreen = () => {
  const { userId } = useParams();

  const { data: user, ...userQuery } = userId
    ? useGetUserQuery( userId )
    : useGetCurrentUserInfoQuery();
  const userMedia = useGetUserMediaQuery( user?._id || skipToken );

  useDocTitle( user ? user.name : 'Crewww - Profile' );

  const navigate = useNavigate();

  const goEdit = () => navigate( routesPaths.profile.edit );

  if ( userQuery.isLoading || userMedia.isLoading ) return <LoadingIndicator />;
  if ( userQuery.isError ) return <>{userQuery.error}</>;

  return (
    <>
      <ScreenTitle
        title='View profile'
        action={
          userId
            ? undefined
            : {
                label: 'Edit',
                onClick: goEdit
              }
        }
      />
      <ProfileGallery medias={userMedia.data || []} />
      <LabeledField label='About me'>{user?.about}</LabeledField>
      <BasicInfo
        label='My basic info'
        items={[
          {
            icon: RiUserLine,
            text: user?.birthDate
              ? Math.floor(
                  -DateTime.fromJSDate( user.birthDate.date ).diffNow( 'years' ).years
                )
              : user?.age
          },
          {
            icon: user?.gender === 'male' ? RiMenLine : RiWomenLine,
            text:
              user?.gender === 'male'
                ? 'man'
                : user?.gender === 'female'
                ? 'woman'
                : 'other'
          },
          { icon: RiMapPinLine, text: user?.location },
          { icon: EducationIcon, text: user?.education },
          {
            icon: AlcoholIcon,
            text: user?.alcohol?.visible
              ? user.alcohol.enabled
                ? 'Yes'
                : 'No'
              : undefined
          },
          {
            icon: SmokingIcon,
            text: user?.smoking?.visible
              ? user.smoking.enabled
                ? 'Yes'
                : 'No'
              : undefined
          },
          { icon: JobIcon, text: user?.job }
        ]}
      />
      <TagList
        title='I am ...'
        items={user?.features || []}
      />
      <TagList
        title='My interests include ...'
        items={user?.interests || []}
      />
      <InstagramBlock />
      <div
        style={{
          marginBottom: '100px'
        }}
      />
    </>
  );
};
