import { useFormikContext } from 'formik';
import { ToggleLabeled, ToggleLabeledProps } from '../controls';

export type ToggleLabeledFieldValue = { enabled: boolean; visible: boolean };
export type ToggleLabeledFieldValueNoVisibility = { enabled: boolean };

export type ToggleLabeledFieldProps = { name: string } & Omit<
  ToggleLabeledProps,
  'onToggle' | 'defaultValue' | 'visibilityDefault' | 'onVisibilityChange'
>;

export const ToggleLabeledField = ( props: ToggleLabeledFieldProps ) => {
  const { values, initialValues, setFieldValue } = useFormikContext();

  const fieldValue = (
    values as Record<
      string,
      ToggleLabeledFieldValue | ToggleLabeledFieldValueNoVisibility
    >
  )[props.name];
  const initialValue = (
    initialValues as Record<
      string,
      ToggleLabeledFieldValue | ToggleLabeledFieldValueNoVisibility
    >
  )[props.name];

  const onToggle = ( enabled: boolean ) => {
    if ( enabled !== fieldValue.enabled )
      setFieldValue( props.name, {
        ...fieldValue,
        enabled
      } as ToggleLabeledFieldValue );
  };

  const onVisibilityChange = ( visible: boolean ) => {
    if ( visible !== ( fieldValue as ToggleLabeledFieldValue ).visible )
      setFieldValue( props.name, {
        enabled: fieldValue.enabled,
        visible
      } as ToggleLabeledFieldValue );
  };

  return (
    <ToggleLabeled
      {...props}
      defaultValue={initialValue.enabled}
      visibilityDefault={( initialValue as ToggleLabeledFieldValue ).visible as never}
      onToggle={onToggle}
      onVisibilityChange={onVisibilityChange}
    />
  );
};
