import { ServerResponse, ServerStatus } from '../services';


export const reduceResponsePromises = async ( responsesPromise: Promise<ServerResponse>[] ) => {
  return Promise.all( responsesPromise ).then( responses => {
    let resultResponse = <ServerResponse>{ success: true, status: ServerStatus.SUCCESS };
    for ( const response of responses ) {
      if ( !response.success ) {
        resultResponse = response;
        break;
      }
    }
    return resultResponse;
  } );
};