import { UserPreview } from '../../../../services';
import { Avatar } from '../Avatar';
import styles from './EventMember.module.scss';

export interface EventMemberPreviewProps {
  user: UserPreview;
  creator?: boolean;
  onClick?: () => void;
}

export const EventMemberPreview = ( {
  user,
  creator,
  onClick
}: EventMemberPreviewProps ) => {
  return (
    <span
      className={styles['event-member']}
      onClick={onClick}
    >
      <Avatar user={user} />
      <div className={styles['name']}>{user.name}</div>
      {creator && <span>creator</span>}
    </span>
  );
};
