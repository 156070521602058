import { AnySchema } from 'yup';
import { CurrentUser, Media, User } from './models';

export type ServerResponse = {
  success: boolean;
  status: ServerStatus;
  message?: string;
};

export enum ServerStatus {
  SUCCESS = 1000,
  INVALID_CREDENTIALS = 1001,
  SAVING_ERROR = 1003,
  NOT_FOUND = 1004,
  UNKNOWN_ERROR = 1005
}

export const isServerResponse = ( obj: unknown ): obj is ServerResponse =>
  ( obj as ServerResponse )?.success !== undefined;

export type TokensPair = {
  accessToken: string;
  refreshToken: string;
};

export type ValidationSchema<T> = Partial<{ [key in keyof T]: AnySchema }>;

export type UpdateCurrentUserTagsParams = {
  tagType: 'features' | 'interests';
  tags: {
    selected: string[];
    upload: string[];
  };
};

export type ChangePasswordParams = {
  oldPassword: string;
  newPassword: string;
};

export type OTPActions = 'register' | 'restore';

export interface FacebookAuthData {
  facebookUserId: string;
  facebookAccessToken: string;
}

export interface GoogleAuthData {
  code: string;
  googleRedirectUri: string;
}

export interface FacebookUserData {
  id: string;
  name: string;
  gender: string;
  birthday?: string;
}

export type FacebookRegisterData = FacebookAuthData &
  Pick<CurrentUser, 'name' | 'age' | 'gender' | 'emailMarketing'> & {
    phone: string;
    otp: string;
    termsAgreed: boolean;
  };

export type EventPreviewTokenResponse = {
  previewToken: string;
};

export type GoogleRegisterData = {
  code: string;
  googleRedirectUri: string;
  otp: string;
  termsAgreed: boolean;
  overrideName?: string;
} & Pick<CurrentUser, 'age' | 'gender' | 'emailMarketing' | 'phone'>;

export interface GetTagsParams {
  all?: boolean;
  type: 'userFeature' | 'userInterest';
  defaultTags?: boolean;
}

export type UpdateCurrentUserFields = Partial<Omit<User, 'birthDate'>> & {
  birthDate?: Date;
};

export type GetUsersResponse = ( Pick<
  User,
  'name' | '_id' | 'age' | 'birthDate' | 'gender'
> & {
  media: Pick<Media, '_id' | 'url'>[];
} )[];

export type UpdateByocFields = {
  authorCrewMembers?: string[];
  removeJoinedCrew?: boolean;
};
