import { useFormikContext } from 'formik';
import { PasswordInput, PasswordInputProps } from '../controls';

export type PasswordFieldProps = Omit<PasswordInputProps, 'onChange' | 'value'>;

export const PasswordField = ( props: PasswordFieldProps ) => {

  const { values, handleChange, errors, touched, handleBlur } = useFormikContext();

  const error = ( errors as Record<string, string | undefined> )[props.name];
  const isTouched = ( touched as Record<string, boolean | undefined> )[props.name];

  return (
    <PasswordInput 
      {...props}
      value={( values as Record<string, string> )[props.name]}
      onChange={handleChange( props.name )}
      error={isTouched ? error : undefined}
      onBlur={handleBlur( props.name )}
    />
  );
};